import React from 'react';
import style from './topCards.module.css';

import TopCard from './TopCard/TopCard';

const TopCards = () => {
  return (
    <div className={`${style.topCards} container`}>
      <h2 className="sectionTitle">TOP CARDS</h2>
      <p className={style.description}>
        Explore our most popular scratch cards. These top picks offer exciting
        themes and great chances to win. Choose your favorite and start
        scratching now!
      </p>

      <ul className={style.GamesContainer}>
        <li>
          <TopCard
            name={'Pearl Of The Orient'}
            rating={5}
            count={5}
            thumbnail="./gameThumbnails/top-card-1.png"
          />
        </li>
        <li>
          <TopCard
            name={'Ice Cream Island Adventure'}
            rating={5}
            count={5}
            thumbnail="./gameThumbnails/top-card-2.png"
          />
        </li>
        <li>
          <TopCard
            name={'Panda Paradise'}
            rating={5}
            count={5}
            thumbnail="./gameThumbnails/top-card-3.png"
          />
        </li>
        <li>
          <TopCard
            name={'Cocktail Carnival Craze'}
            rating={5}
            count={5}
            thumbnail="./gameThumbnails/top-card-4.png"
          />
        </li>
      </ul>
    </div>
  );
};

export default TopCards;
