import React from 'react';
import styles from './home.module.css';

import HeroSection from '../../components/HeroSection/HeroSection';
import TopupSection from '../../components/TopupSection/TopupSection';
import GamesCollection from '../../components/GamesCollectionSection/GamesCollection';
import HowToPlaySection from '../../components/HowToPlaySection/HowToPlaySection';
import FAQSection from '../../components/FAQSection/FAQSection';

const Home = () => {
  return (
    <>
      <div className={styles.pageWrapper}>
        <HeroSection />
        <TopupSection />
        <GamesCollection />
        <HowToPlaySection />
        <FAQSection />
      </div>
    </>
  );
};

export default Home;
