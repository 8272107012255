import React from 'react';
import VisaActive from './VisaActive';
import VisaInactive from './VisaInactive';
import { IconState } from '../../../types/iconState';

type VisaProps = {
  state?: IconState;
};

const Visa = ({ state = IconState.Inactive }: VisaProps) => {
  const fillColor = state === IconState.Inactive ? '#544E65' : '';

  return (
    <>
      <svg
        width="67"
        height="20"
        viewBox="0 0 67 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.2385 19.4764L27.5893 0.628235H32.8249L29.5788 19.4764H24.2385Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#3C58BF' : fillColor}`}
        />
        <path
          d="M24.2385 19.4764L28.5317 0.628235H32.8249L29.5788 19.4764H24.2385Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#293688' : fillColor}`}
        />
        <path
          d="M48.5317 0.837697C47.4846 0.418849 45.8092 0 43.715 0C38.4794 0 34.7098 2.6178 34.7098 6.38743C34.7098 9.21466 37.3276 10.6806 39.4218 11.623C41.516 12.5654 42.1443 13.1937 42.1443 14.0314C42.1443 15.288 40.4689 15.9162 39.0029 15.9162C36.9087 15.9162 35.7569 15.6021 33.9768 14.8691L33.2438 14.555L32.5108 18.8482C33.7673 19.3717 36.071 19.8953 38.4794 19.8953C44.0291 19.8953 47.694 17.2775 47.694 13.2984C47.694 11.0995 46.3328 9.42408 43.1914 8.06283C41.3066 7.12042 40.1548 6.59686 40.1548 5.65445C40.1548 4.81675 41.0972 3.97906 43.1914 3.97906C44.9715 3.97906 46.2281 4.29319 47.1705 4.71204L47.694 4.92147L48.5317 0.837697Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#3C58BF' : fillColor}`}
        />
        <path
          d="M48.5317 0.837697C47.4846 0.418849 45.8092 0 43.715 0C38.4794 0 35.6522 2.6178 35.6522 6.38743C35.6522 9.21466 37.3276 10.6806 39.4218 11.623C41.516 12.5654 42.1443 13.1937 42.1443 14.0314C42.1443 15.288 40.4689 15.9162 39.0029 15.9162C36.9087 15.9162 35.7569 15.6021 33.9768 14.8691L33.2438 14.555L32.5108 18.8482C33.7673 19.3717 36.071 19.8953 38.4794 19.8953C44.0291 19.8953 47.694 17.2775 47.694 13.2984C47.694 11.0995 46.3328 9.42408 43.1914 8.06283C41.3066 7.12042 40.1548 6.59686 40.1548 5.65445C40.1548 4.81675 41.0972 3.97906 43.1914 3.97906C44.9715 3.97906 46.2281 4.29319 47.1705 4.71204L47.694 4.92147L48.5317 0.837697Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#293688' : fillColor}`}
        />
        <path
          d="M57.5369 0.628235C56.2804 0.628235 55.338 0.732946 54.8144 1.98949L46.961 19.4764H52.6154L53.6626 16.335H60.3641L60.9924 19.4764H66.0186L61.6207 0.628235H57.5369ZM55.1285 13.1937C55.4427 12.2513 57.2228 7.64394 57.2228 7.64394C57.2228 7.64394 57.6416 6.49211 57.9558 5.75912L58.2699 7.53923C58.2699 7.53923 59.317 12.2513 59.5264 13.2984H55.1285V13.1937Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#3C58BF' : fillColor}`}
        />
        <path
          d="M58.7935 0.628235C57.5369 0.628235 56.5945 0.732946 56.0709 1.98949L46.961 19.4764H52.6154L53.6626 16.335H60.3641L60.9924 19.4764H66.0186L61.6207 0.628235H58.7935ZM55.1285 13.1937C55.5474 12.1466 57.2228 7.64394 57.2228 7.64394C57.2228 7.64394 57.6416 6.49211 57.9558 5.75912L58.2699 7.53923C58.2699 7.53923 59.317 12.2513 59.5264 13.2984H55.1285V13.1937Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#293688' : fillColor}`}
        />
        <path
          d="M14.605 13.822L14.0814 11.0995C13.139 7.9581 10.1024 4.5026 6.75159 2.82721L11.4636 19.5811H17.1181L25.5997 0.732971H19.9453L14.605 13.822Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#3C58BF' : fillColor}`}
        />
        <path
          d="M14.605 13.822L14.0814 11.0995C13.139 7.9581 10.1024 4.5026 6.75159 2.82721L11.4636 19.5811H17.1181L25.5997 0.732971H20.9924L14.605 13.822Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#293688' : fillColor}`}
        />
        <path
          d="M0.0500488 0.628235L0.992457 0.83766C7.69403 2.40834 12.3014 6.38739 14.0815 11.0994L12.1966 2.19891C11.8825 0.942371 10.9401 0.628235 9.78827 0.628235H0.0500488Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#FFBC00' : fillColor}`}
        />
        <path
          d="M0.0500488 0.628235C6.75162 2.19891 12.3014 6.28268 14.0815 10.9947L12.3014 3.56017C11.9872 2.30363 10.9401 1.57064 9.78827 1.57064L0.0500488 0.628235Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#F7981D' : fillColor}`}
        />
        <path
          d="M0.0500488 0.628235C6.75162 2.19891 12.3014 6.28268 14.0815 10.9947L12.8249 6.91095C12.5108 5.65441 12.0919 4.39787 10.626 3.87431L0.0500488 0.628235Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#ED7C00' : fillColor}`}
        />
        <path
          d="M19.8406 13.1937L16.2804 9.63353L14.605 13.6126L14.1862 10.9948C13.2438 7.85342 10.2071 4.39793 6.85632 2.72253L11.5684 19.4765H17.2228L19.8406 13.1937Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#051244' : fillColor}`}
        />
        <path
          d="M29.5788 19.4765L25.0762 14.8691L24.2385 19.4765H29.5788Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#051244' : fillColor}`}
        />
        <path
          d="M41.6207 12.9843C42.0396 13.4032 42.249 13.7173 42.1443 14.1361C42.1443 15.3927 40.4689 16.021 39.0029 16.021C36.9087 16.021 35.7569 15.7068 33.9768 14.9738L33.2438 14.6597L32.5108 18.9529C33.7673 19.4765 36.071 20 38.4794 20C41.8302 20 44.5527 19.0576 46.1234 17.3822L41.6207 12.9843Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#051244' : fillColor}`}
        />
        <path
          d="M47.694 19.4764H52.6155L53.6626 16.3351H60.3642L60.9925 19.4764H66.0186L64.2385 11.8325L57.9558 5.75916L58.2699 7.43455C58.2699 7.43455 59.3171 12.1466 59.5265 13.1937H55.1286C55.5474 12.1466 57.2228 7.64397 57.2228 7.64397C57.2228 7.64397 57.6417 6.49214 57.9558 5.75916"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#051244' : fillColor}`}
        />
      </svg>
    </>
  );
};

export default Visa;
