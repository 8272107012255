import React from 'react';
import styles from './gamesCollection.module.css';
import globalStyle from '../../global.module.css';

import GameCard from '../GameCard/GameCard';
import gameCardData from '../../data/gameCard.json';

import ArrowRight from '../Icons/ArrowRight/ArrowRight';
import classNames from 'classnames';
import { sanitizeForUrl } from '../../utils/helperFunctions';
import useRequireLogin from '../../hooks/useRequiredLogin';
import routes from '../../router/routes.json';

const featuredGames = [
  'Pearl of the Orient',
  'Ice Cream Island Adventure',
  'Panda Paradise',
  'Cocktail Carnival Craze'
];

const GamesCollection = () => {
  const useRequiredLogin = useRequireLogin();

  return (
    <div className={styles.gamesCollectionContainer}>
      <p className={'sectionTitle'}>COLLECTION</p>
      <h2 className={styles.gamesCollectionHeader}>scratch and win big!</h2>
      <p className={styles.gamesCollectionCopy}>
        Explore our exclusive range of scratch card games, each designed to
        deliver unique and thrilling experiences. From vibrant themes to
        exciting gameplay mechanics, there’s something for everyone.
      </p>

      <ul className={styles.gamesContainer}>
        {gameCardData
          .filter((game) => featuredGames.includes(game.gameTitle))
          .map((game) => {
            const thumbnail = sanitizeForUrl(game.gameTitle);

            return (
              <li key={`featured-games-${game.gameTitle}`}>
                <GameCard
                  name={game.gameTitle}
                  gameType={game.gameType}
                  cost={game.tier}
                  thumbnail={`./thumbnails/cards/${thumbnail}.png`}
                />
              </li>
            );
          })}
      </ul>

      <button
        className={classNames(styles.viewCardsBtn, globalStyle.btnIconReveal)}
        onClick={() => {
          useRequiredLogin(routes.cards.url, { hash: '#cards' });
        }}>
        <span>View All Cards</span>
        <ArrowRight className={globalStyle.icon} />
      </button>
    </div>
  );
};

export default GamesCollection;
