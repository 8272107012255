import React from 'react';

const ArrowDown = () => {
  return (
    <div>
      <svg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.27734 9.17944L0.421875 2.35913C0.105469 2.00757 0.105469 1.48022 0.421875 1.16382L1.23047 0.355225C1.54688 0.0388184 2.07422 0.0388184 2.42578 0.355225L7.875 5.76929L13.2891 0.355225C13.6406 0.0388184 14.168 0.0388184 14.4844 0.355225L15.293 1.16382C15.6094 1.48022 15.6094 2.00757 15.293 2.35913L8.4375 9.17944C8.12109 9.49585 7.59375 9.49585 7.27734 9.17944Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ArrowDown;
