import React from 'react';
import styles from './loggedInHeroBanner.module.css';

const LoggedInHeroBanner = () => {
  return (
    <div className={`${styles.heroBanner} container`}>
      <div className={styles.heroCopy}>
        <h1 className={styles.heroTitle}>Scratch and Win Big!</h1>
        <p>
          Unlock exciting themes, instant cash prizes, and endless fun, all from
          the comfort of your device. Your winning adventure starts now!
        </p>
      </div>
      <div className={styles.heroSvg}>
        <img src="./logged-in-hero.png" alt="" />
      </div>
    </div>
  );
};

export default LoggedInHeroBanner;
