import React from 'react';
import { IconState } from '../../../types/iconState';

type MayaProps = {
  state?: IconState;
};

const Maya = ({ state = IconState.Inactive }: MayaProps) => {
  const fillColor = state === IconState.Inactive ? '#544E65' : '';

  return (
    <>
      <svg
        width="70"
        height="20"
        viewBox="0 0 70 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.6916 2.22828e-07C14.0887 -0.00043147 12.5492 0.626403 11.4025 1.7464L12.0241 2.92723L11.7693 3.13232C11.2306 2.19932 10.4587 1.42233 9.52914 0.877562C8.59958 0.332788 7.54437 0.0389412 6.46699 0.024856C3.08546 0.024856 0.3504 2.98317 0.3504 6.45109V13.909C0.347767 13.9726 0.358351 14.036 0.381473 14.0952C0.404595 14.1545 0.439764 14.2083 0.484747 14.2533C0.529725 14.2982 0.583537 14.3334 0.6428 14.3565C0.702063 14.3796 0.765487 14.3902 0.829049 14.3876H3.27817C3.33799 14.3884 3.39738 14.3772 3.45281 14.3548C3.50824 14.3322 3.55859 14.2988 3.60089 14.2565C3.64319 14.2143 3.67659 14.1639 3.69909 14.1085C3.7216 14.053 3.73277 13.9937 3.73193 13.9339V6.37029C3.73193 4.64878 4.77621 3.26284 6.554 3.26284C8.21369 3.26284 9.40099 4.50582 9.40099 6.33925V12.5542C9.39672 12.6158 9.40516 12.6776 9.42563 12.7359C9.44615 12.7941 9.47834 12.8476 9.52023 12.893C9.56213 12.9384 9.61289 12.9747 9.66934 12.9998C9.72579 13.0249 9.78674 13.0382 9.84854 13.0389H12.1671C12.2289 13.0382 12.2899 13.0249 12.3463 12.9998C12.4028 12.9747 12.4535 12.9384 12.4954 12.893C12.5373 12.8476 12.5695 12.7941 12.59 12.7359C12.6105 12.6776 12.6189 12.6158 12.6147 12.5542V6.33925C12.5871 5.94234 12.6422 5.54401 12.7764 5.16951C12.9106 4.79497 13.1211 4.45231 13.3945 4.16322C13.6679 3.87412 13.9982 3.64485 14.3647 3.48987C14.7312 3.33489 15.1259 3.25759 15.5238 3.26284C17.2954 3.26284 18.2837 4.67361 18.2837 6.37029V13.9215C18.2885 14.0445 18.3413 14.1609 18.4307 14.2456C18.5202 14.3304 18.6391 14.3768 18.7623 14.3751H21.1866C21.2504 14.3787 21.3142 14.3688 21.374 14.346C21.4337 14.3233 21.4879 14.2881 21.5331 14.243C21.5782 14.1978 21.6134 14.1436 21.6362 14.0839C21.6589 14.0242 21.6689 13.9604 21.6653 13.8966V6.43867C21.6653 2.95831 19.0483 2.22828e-07 15.6916 2.22828e-07Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#09B66A' : fillColor}`}
        />
        <path
          d="M37.6525 0.279501H35.4271C35.3653 0.275187 35.3032 0.283774 35.2449 0.304717C35.1865 0.32566 35.1331 0.358504 35.0881 0.401162C35.0431 0.443819 35.0075 0.495357 34.9834 0.552515C34.9594 0.609673 34.9475 0.671195 34.9485 0.733191V1.03772L35.738 2.28071L35.5204 2.49201C34.8687 1.72395 34.0599 1.10458 33.1484 0.67573C32.2369 0.246874 31.244 0.0185038 30.2368 0.00604248C28.359 0.0950759 26.5873 0.902115 25.2879 2.2603C23.9885 3.61849 23.2608 5.42395 23.2553 7.30345C23.2497 9.18294 23.9666 10.9927 25.2579 12.3586C26.5492 13.7245 28.3161 14.542 30.1932 14.6422C31.2053 14.6314 32.2033 14.4039 33.12 13.9751C34.0368 13.5462 34.851 12.9261 35.5079 12.1562L35.6944 12.3054L34.905 13.5981V13.884C34.9041 13.9471 34.916 14.0097 34.9397 14.0681C34.9635 14.1266 34.9987 14.1796 35.0433 14.2242C35.0879 14.2689 35.141 14.3041 35.1995 14.3278C35.2579 14.3516 35.3205 14.3634 35.3836 14.3625H37.6525C37.7163 14.3661 37.7801 14.3562 37.8398 14.3335C37.8996 14.3107 37.9538 14.2755 37.9989 14.2304C38.0441 14.1852 38.0793 14.131 38.102 14.0713C38.1248 14.0116 38.1347 13.9478 38.1311 13.884V0.733191C38.1341 0.670659 38.1236 0.608223 38.1003 0.550136C38.0769 0.492045 38.0413 0.439673 37.9959 0.396598C37.9504 0.353529 37.8962 0.320773 37.837 0.300564C37.7777 0.280354 37.7148 0.273169 37.6525 0.279501ZM30.1932 11.4975C29.087 11.4975 28.0261 11.0581 27.2439 10.276C26.4617 9.49394 26.0223 8.43324 26.0223 7.32723C26.0223 6.22123 26.4617 5.16052 27.2439 4.37845C28.0261 3.59638 29.087 3.15702 30.1932 3.15702C31.2994 3.15702 32.3603 3.59638 33.1426 4.37845C33.9248 5.16052 34.3642 6.22123 34.3642 7.32723C34.3642 8.43324 33.9248 9.49394 33.1426 10.276C32.3603 11.0581 31.2994 11.4975 30.1932 11.4975Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#09B66A' : fillColor}`}
        />
        <path
          d="M69.4543 0.279501H67.2291C67.1675 0.275187 67.1054 0.283774 67.0467 0.304717C66.9884 0.32566 66.9353 0.358504 66.8903 0.401162C66.8452 0.443819 66.8097 0.495357 66.7855 0.552515C66.7614 0.609673 66.7495 0.671195 66.7504 0.733191V1.03772L67.5401 2.28071L67.3225 2.49201C66.6708 1.72395 65.8618 1.10458 64.9504 0.67573C64.039 0.246874 63.046 0.0185038 62.0389 0.00604248C60.0952 0.00604248 58.2312 0.778038 56.8567 2.15219C55.4822 3.52635 54.7102 5.39011 54.7102 7.33344C54.7102 9.27682 55.4822 11.1405 56.8567 12.5147C58.2312 13.8889 60.0952 14.6608 62.0389 14.6608C63.0508 14.65 64.0489 14.4225 64.9655 13.9937C65.8822 13.5649 66.6964 12.9447 67.3533 12.1749L67.5401 12.3241L66.7504 13.6168V13.9026C66.7495 13.9657 66.7613 14.0283 66.785 14.0868C66.8092 14.1452 66.8443 14.1983 66.8888 14.2429C66.9334 14.2875 66.9865 14.3227 67.0452 14.3465C67.1035 14.3702 67.1661 14.382 67.2291 14.3812H69.4543C69.5183 14.3848 69.5823 14.3749 69.642 14.3521C69.7017 14.3293 69.7558 14.2942 69.8008 14.249C69.8463 14.2038 69.8814 14.1496 69.9041 14.0899C69.9269 14.0303 69.9368 13.9664 69.933 13.9026V0.733191C69.9364 0.670659 69.9254 0.608223 69.9022 0.550136C69.879 0.492045 69.8435 0.439673 69.798 0.396598C69.7525 0.353529 69.6984 0.320773 69.6392 0.300564C69.5799 0.280354 69.5169 0.273169 69.4543 0.279501ZM62.0389 11.4975C60.9327 11.4975 59.8715 11.0581 59.0895 10.276C58.3075 9.49394 57.8676 8.43324 57.8676 7.32723C57.8676 6.22123 58.3075 5.16052 59.0895 4.37845C59.8715 3.59638 60.9327 3.15702 62.0389 3.15702C63.1451 3.15702 64.2058 3.59638 64.9883 4.37845C65.7703 5.16052 66.2097 6.22123 66.2097 7.32723C66.2097 8.43324 65.7703 9.49394 64.9883 10.276C64.2058 11.0581 63.1451 11.4975 62.0389 11.4975Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#09B66A' : fillColor}`}
        />
        <path
          d="M52.8812 0.279845H50.6001C50.2332 0.279845 50.0346 0.478726 50.0346 0.67139V7.55133C50.0669 8.0323 49.9967 8.5147 49.8289 8.96667C49.6612 9.41865 49.3995 9.82992 49.0611 10.1734C48.7227 10.517 48.3156 10.7849 47.8663 10.9596C47.417 11.1343 46.9355 11.2119 46.454 11.1871C44.4524 11.1871 43.1595 9.69548 43.1595 7.55133V0.67139C43.1595 0.478726 42.8487 0.279845 42.6498 0.279845H40.1634C40.0624 0.288306 39.9676 0.332276 39.8959 0.403954C39.8242 0.475626 39.7802 0.570376 39.7717 0.67139V7.50783C39.7518 9.29483 40.4402 11.0171 41.6865 12.2981C42.9328 13.5792 44.6357 14.3148 46.4229 14.3443C48.1308 14.3458 49.7801 13.7224 51.0603 12.5916L51.1906 12.7097L50.1773 14.0894V14.6426C50.1773 16.0534 48.0578 16.8426 46.8207 16.8426H46.0313C45.9679 16.8385 45.9043 16.8469 45.8443 16.8675C45.7842 16.8881 45.7288 16.9205 45.6814 16.9627C45.6339 17.0049 45.5954 17.0562 45.5679 17.1134C45.5405 17.1707 45.5247 17.2329 45.5216 17.2964V19.5461C45.5327 19.6725 45.5916 19.7898 45.6863 19.8742C45.7811 19.9585 45.9045 20.0035 46.0313 19.9998H46.8207C50.6001 19.9998 53.3599 17.7997 53.3599 13.6544V0.67139C53.3433 0.558326 53.285 0.455582 53.1964 0.383194C53.1077 0.310807 52.9954 0.273964 52.8812 0.279845Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#09B66A' : fillColor}`}
        />
      </svg>
    </>
  );
};

export default Maya;
