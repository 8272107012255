import React, { useEffect, useMemo } from 'react';
import styles from './router.module.css';
import {
  createBrowserRouter,
  Outlet,
  ScrollRestoration
} from 'react-router-dom';
import {
  ModalProvider,
  ModalType,
  useModal
} from '../context/ModalContextProvider';

// components
import NavBar from '../components/NavBar/NavBar';
import Modal from '../components/Modal/Modal';
import FooterSection from '../components/FooterSection/FooterSection';
import { AuthProvider, useAuth } from '../context/AuthContextProvider';
import Home from '../pages/Home/Home';
import CardsPage from '../pages/CardsPage/CardsPage';
import HelpCenter from '../pages/HelpCenter/HelpCenter';
import GamingGuidelines from '../components/GamingGuidelines/GamingGuidelines';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from '../components/TermsAndConditon/TermsAndCondition';
import ContactUs from '../pages/ContactUs/ContactUs';
import routes from './routes.json';
import MyAccount from '../pages/MyAccount/MyAccount';

const RootContent = () => {
  const { type: modalType, isModalOpen, closeModal } = useModal();
  const { isAuthenticated } = useAuth();

  // close auth modal when user is authenticated
  useEffect(() => {
    if (isAuthenticated && modalType === ModalType.AUTH && isModalOpen)
      closeModal();
  }, [isAuthenticated]);

  return (
    <>
      <ScrollRestoration />
      <div className={styles.appWrapper}>
        <NavBar />
        <Outlet />
        <Modal modalType={modalType} />
      </div>
      <FooterSection />
    </>
  );
};

const Root = () => {
  return (
    <>
      <AuthProvider>
        <ModalProvider>
          <RootContent />
        </ModalProvider>
      </AuthProvider>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: routes.home.url,
        element: <Home />
      },
      {
        path: routes.cards.url,
        element: <CardsPage />
      },
      {
        path: routes.helpCenter.url,
        element: <HelpCenter />
      },
      {
        path: routes.responsibleGaming.url,
        element: <GamingGuidelines />
      },
      {
        path: routes.privacyPolicy.url,
        element: <PrivacyPolicy />
      },
      {
        path: routes.termsAndCondition.url,
        element: <TermsAndCondition />
      },
      {
        path: routes.contactUs.url,
        element: <ContactUs />
      },
      {
        path: routes.myAccount.url,
        element: <MyAccount />
      }
    ]
  }
]);

export default router;
