import React from 'react';
import { IconState } from '../../../types/iconState';

type FaceBookProps = {
  state?: IconState;
};

const FaceBook = ({ state }: FaceBookProps) => {
  const fillColor = state === IconState.Hover ? '#1877F2' : '';

  return (
    <div>
      <svg
        width="11"
        height="19"
        viewBox="0 0 11 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.80859 10.5156H7.17188V18.3906H3.65625V10.5156H0.773438V7.28125H3.65625V4.78516C3.65625 1.97266 5.34375 0.390625 7.91016 0.390625C9.14062 0.390625 10.4414 0.636719 10.4414 0.636719V3.41406H9C7.59375 3.41406 7.17188 4.25781 7.17188 5.17188V7.28125H10.3008L9.80859 10.5156Z"
          fill={`${state === IconState.Hover ? fillColor : 'white'}`}
        />
      </svg>
    </div>
  );
};

export default FaceBook;
