// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guestNavButtons_navButtonWrapper__5TYJ8 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.guestNavButtons_navBarButton__9jFd0 {
  text-transform: capitalize;
  height: 2.4375rem;
  width: 7.5rem;
}

.guestNavButtons_register__KMe0t {
  background-color: var(--secondary-persian-rose);
}

.guestNavButtons_login__bc4eO {
  background-color: var(--secondary-cyan);
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBar/GuestNavButtons/guestNavButtons.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".navButtonWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 1.5rem;\n}\n\n.navBarButton {\n  text-transform: capitalize;\n  height: 2.4375rem;\n  width: 7.5rem;\n}\n\n.register {\n  background-color: var(--secondary-persian-rose);\n}\n\n.login {\n  background-color: var(--secondary-cyan);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navButtonWrapper": `guestNavButtons_navButtonWrapper__5TYJ8`,
	"navBarButton": `guestNavButtons_navBarButton__9jFd0`,
	"register": `guestNavButtons_register__KMe0t`,
	"login": `guestNavButtons_login__bc4eO`
};
export default ___CSS_LOADER_EXPORT___;
