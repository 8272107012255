import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
  useMemo
} from 'react';

interface FaqContextType {
  activeFaqTopic: string | null;
  isFaqTopicSelected: boolean;
  searchQuery: string;
  totalMatches: number;
  handleFaqTopicClick: (topic: string) => void;
  handleSearch: (topic: string) => void;
  highlightTextRecursive: (
    content: React.ReactNode,
    searchQuery: string
  ) => React.ReactNode;
}

const FaqContext = createContext<FaqContextType | undefined>(undefined);

interface FaqProviderProps {
  children: ReactNode;
}

export const FaqProvider: React.FC<FaqProviderProps> = ({ children }) => {
  const [activeFaqTopic, setActiveFaqTopic] = useState<string | null>(null);
  const [isFaqTopicSelected, setIsFaqTopicSelected] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalMatches, setTotalMatches] = useState<number>(0);

  const handleFaqTopicClick = (topic: string) => {
    setActiveFaqTopic(topic);
    setIsFaqTopicSelected(true);
    setSearchQuery('');
    setTotalMatches(0); // Reset matches when topic changes
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setActiveFaqTopic(null); // Clear the active topic when a search is performed
    setTotalMatches(0); // Reset total matches when new search is performed
  };

  // Function to highlight text and count matches
  const highlightText = useCallback((text: string, query: string) => {
    if (!query) return { highlightedText: text, matchCount: 0 };
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    const matchCount = parts.filter(
      (part) => part.toLowerCase() === query.toLowerCase()
    ).length;

    return {
      highlightedText: (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
              <mark
                key={index}
                style={{ backgroundColor: 'yellow', color: 'black' }}>
                {part}
              </mark>
            ) : (
              part
            )
          )}
        </>
      ),
      matchCount
    };
  }, []);

  // Memoized recursiveHighlight function
  const recursiveHighlight = useCallback(
    (
      node: React.ReactNode,
      query: string,
      countRef: { count: number }
    ): React.ReactNode => {
      if (typeof node === 'string') {
        const { highlightedText, matchCount } = highlightText(node, query);
        countRef.count += matchCount; // Accumulate count
        return highlightedText;
      }

      if (React.isValidElement(node)) {
        return React.cloneElement(node, {
          ...node.props,
          children: React.Children.map(node.props.children, (child) =>
            recursiveHighlight(child, query, countRef)
          )
        });
      }

      return node;
    },
    [highlightText]
  );

  const highlightTextRecursive = useCallback(
    (content: React.ReactNode, searchQuery: string): React.ReactNode => {
      const countRef = { count: 0 }; // Initialize a ref to keep track of matches
      const highlightedContent = recursiveHighlight(
        content,
        searchQuery,
        countRef
      );
      setTotalMatches(countRef.count); // Update total matches after processing
      return highlightedContent;
    },
    [recursiveHighlight]
  );

  const contextValue = useMemo(
    () => ({
      searchQuery,
      handleSearch,
      activeFaqTopic,
      isFaqTopicSelected,
      handleFaqTopicClick,
      totalMatches,
      highlightTextRecursive
    }),
    [
      searchQuery,
      activeFaqTopic,
      isFaqTopicSelected,
      totalMatches,
      handleFaqTopicClick
    ]
  );

  return (
    <FaqContext.Provider value={contextValue}>{children}</FaqContext.Provider>
  );
};

export const useFaqTopic = (): FaqContextType => {
  const context = useContext(FaqContext);
  if (context === undefined) {
    throw new Error('useFaqTopic must be used within a FaqProvider');
  }
  return context;
};
