import React, { useEffect, useRef, type ReactNode } from 'react';
import styles from './modal.module.css';

import Overlay from './Overlay';
import { AnimatePresence, motion } from 'framer-motion';
// import { mergeClassNames } from '../../utils/mergeClassNames';
import { useModal } from '../../context/ModalContextProvider';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import CloseBtn from './CloseBtn';

type WrapperProps = {
  children: ReactNode;
  className?: string;
};

const ModalWrapper = ({ children, className }: WrapperProps) => {
  const { isModalOpen, closeModal } = useModal();
  const modalRef = useRef(null);

  // hook
  useOutsideClick(modalRef, () => closeModal());

  return (
    <AnimatePresence>
      {isModalOpen && (
        <motion.div
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            backdropFilter: 'blur(10px)'
          }}
          exit={{
            opacity: 0,
            backdropFilter: 'blur(0px)'
          }}
          className={styles.wrapper}>
          <Overlay />

          <motion.div
            ref={modalRef}
            className={styles.modal}
            initial={{
              opacity: 0,
              scale: 0.5,
              rotateX: 40,
              y: 40
            }}
            animate={{
              opacity: 1,
              scale: 1,
              rotateX: 0,
              y: 0
            }}
            exit={{
              opacity: 0,
              scale: 0.8,
              rotateX: 10
            }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 15
            }}>
            {/* <CloseBtn /> */}
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ModalWrapper;
