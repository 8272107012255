import React from 'react';
import styles from './avatar.module.css';

const Avatar = () => {
  return (
    <div className={styles.avatarBorder}>
      <img className={styles.avatar} src="./account-avatar.png" alt="" />
    </div>
  );
};

export default Avatar;
