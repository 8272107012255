import React, { useCallback, useEffect, useState } from 'react';
import styles from './accordion.module.css';

import ArrowDown from '../../../components/Icons/ArrowDown/ArrowDown';
import { useFaqTopic } from '../../../context/FaqContextProvider';

interface AccordionProps {
  items: { title: string; content: React.ReactNode | string }[];
  opts?: {
    allowMultipleActive?: boolean;
    defaultActiveIndex?: number;
    searchQuery?: string;
  };
}

interface AccordionItemProps {
  index: number;
  title: string;
  content: React.ReactNode;
  isActive: boolean;
  handleToggle: (index: number) => void;
  searchQuery?: string;
}

const AccordionItem = ({
  index,
  title,
  content,
  isActive,
  searchQuery,
  handleToggle
}: AccordionItemProps) => {
  const { activeFaqTopic, highlightTextRecursive } = useFaqTopic();

  return (
    <div className={styles.accordionItem}>
      <div
        className={styles.accordionHeader}
        onClick={() => handleToggle(index)}>
        <div className={styles.accordionTitle}>
          {typeof title === 'string' && searchQuery
            ? highlightText(title, searchQuery)
            : title}
        </div>
        <div className={isActive ? styles.arrowActive : styles.arrowInactive}>
          <ArrowDown />
        </div>
      </div>
      <div
        className={`${styles.accordionContent} ${
          isActive ? styles.accordionContentActive : ''
        }`}>
        <div className={styles.accordionContentInner}>
          {searchQuery ? highlightTextRecursive(content, searchQuery) : content}
        </div>
      </div>
    </div>
  );
};

const highlightText = (text: string, query: string) => {
  if (!query) return text;
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <mark
            key={index}
            style={{ backgroundColor: 'yellow', color: 'black' }}>
            {part}
          </mark>
        ) : (
          part
        )
      )}
    </>
  );
};

const Accordion = ({ items, opts }: AccordionProps) => {
  const initialActiveIndices =
    opts?.defaultActiveIndex !== undefined ? [opts.defaultActiveIndex] : [];
  const [activeIndices, setActiveIndices] =
    useState<number[]>(initialActiveIndices);

  useEffect(() => {
    if (opts?.allowMultipleActive && opts?.searchQuery) {
      // Set all indices as active
      setActiveIndices(items.map((_, index) => index));
    }
  }, [opts?.allowMultipleActive, opts?.searchQuery, items]);

  const handleToggle = useCallback(
    (index: number) => {
      if (opts?.allowMultipleActive) {
        // If the index is already active, remove it from the activeIndices array.
        // Otherwise, add it to the array.
        setActiveIndices((prevIndices) =>
          prevIndices.includes(index)
            ? prevIndices.filter((i) => i !== index)
            : [...prevIndices, index]
        );
      } else {
        // If allowMultipleActive is false, toggle a single active index.
        setActiveIndices((prevIndices) =>
          prevIndices.includes(index) ? [] : [index]
        );
      }
    },
    [opts?.allowMultipleActive]
  );

  return (
    <div className={styles.accordion}>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          index={index}
          title={item.title}
          content={item.content}
          isActive={activeIndices.includes(index)}
          handleToggle={handleToggle}
          searchQuery={opts?.searchQuery ? opts?.searchQuery : undefined}
        />
      ))}
    </div>
  );
};

export default Accordion;
