import React from 'react';
import styles from './contactDetails.module.css';

import PhoneIconYellow from '../../../assets/icons/PhoneIconYellow';
import EmailIconYellow from '../../../assets/icons/EmailIconYellow';
import LocationIconYellow from '../../../assets/icons/LocationIconYellow';

const contactDetails = [
  {
    Icon: PhoneIconYellow,
    label: 'Phone',
    value: '+63917-SCRATCH'
  },
  {
    Icon: EmailIconYellow,
    label: 'Email',
    value: 'support@scratchmania8.com'
  },
  {
    Icon: LocationIconYellow,
    label: 'Location',
    value: 'Quezon City, Philippines'
  }
];

const ContactDetails = () => {
  return (
    <div className={styles.root}>
      <ul className={styles.contactDetailsList}>
        {contactDetails.map((contact, index) => (
          <li key={index} className={styles.contactDetail}>
            <contact.Icon />
            <p className={styles.label}>{contact.label}</p>
            <p className={styles.value}>{contact.value}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactDetails;
