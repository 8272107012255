// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.termsAndCondition_termsConditionBanner__EVM9o {
    max-width: 70.4375rem;
    margin: 4.625rem auto;
    display: flex;
}

.termsAndCondition_termsConditionTitle__RYH6F {
    font-family: var(--font-rajdhani);
    font-size: 5.625rem;
    line-height: 5.625rem;
    font-weight: 600;
}

.termsAndCondition_termsConditionCopy__8Rs0J {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.termsAndCondition_termsSvg__B5AFY {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.termsAndCondition_termsConditionCopy__8Rs0J p {
    font-family: var(--font-nunito-sans);
    font-size: 1.180rem;
    max-width: 58ch;
    color: #d4d4d4;
    font-weight: 380;
    line-height: 1.700rem;
}`, "",{"version":3,"sources":["webpack://./src/components/TermsAndConditon/termsAndCondition.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":[".termsConditionBanner {\n    max-width: 70.4375rem;\n    margin: 4.625rem auto;\n    display: flex;\n}\n\n.termsConditionTitle {\n    font-family: var(--font-rajdhani);\n    font-size: 5.625rem;\n    line-height: 5.625rem;\n    font-weight: 600;\n}\n\n.termsConditionCopy {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: start;\n}\n\n.termsSvg {\n    width: 50%;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n.termsConditionCopy p {\n    font-family: var(--font-nunito-sans);\n    font-size: 1.180rem;\n    max-width: 58ch;\n    color: #d4d4d4;\n    font-weight: 380;\n    line-height: 1.700rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"termsConditionBanner": `termsAndCondition_termsConditionBanner__EVM9o`,
	"termsConditionTitle": `termsAndCondition_termsConditionTitle__RYH6F`,
	"termsConditionCopy": `termsAndCondition_termsConditionCopy__8Rs0J`,
	"termsSvg": `termsAndCondition_termsSvg__B5AFY`
};
export default ___CSS_LOADER_EXPORT___;
