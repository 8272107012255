import React, { useCallback } from 'react';
import styles from './howToPlaySection.module.css';
import HowToPlayCard from './HowToPlayCard/HowToPlayCard';

import data from './data.json';
import { ModalType, useModal } from '../../context/ModalContextProvider';
import { AuthType } from '../Modal/Auth/Auth';
import useRequireLogin from '../../hooks/useRequiredLogin';
import routes from '../../router/routes.json';

const HowToPlaySection = () => {
  const { openModal } = useModal();
  const useRequiredLogin = useRequireLogin();

  const handleCreateAccount = useCallback(() => {
    openModal(ModalType.AUTH, { authType: AuthType.Register });
  }, [openModal]);

  const handleSelectCard = useCallback(() => {
    useRequiredLogin(routes.cards.url, { hash: '#cards' });
  }, [useRequiredLogin]);

  const handleTopUpandPlay = useCallback(() => {}, []);

  const handleWinAndWithdraw = useCallback(() => {}, []);

  const handleStepActions = useCallback((step: string) => {
    switch (step) {
      case 'Create Account':
        handleCreateAccount();
        break;
      case 'Select Your Scratch Card':
        handleSelectCard();
        break;
      case 'Top-up and Play':
        handleTopUpandPlay();
        break;
      case 'Win and Withdraw':
        handleWinAndWithdraw();
        break;
      default:
        console.warn('Unknown step: ', step);
        break;
    }
  }, []);

  return (
    <div className={`${styles.howToPlayContainer} container`}>
      <div className={styles.howToPlayBackground}>
        <img src="./violet.svg" alt="" />
      </div>
      <div className={styles.howToPlaySection}>
        <div className={styles.howToPlayHeaderWrapper}>
          <div>
            <p className={'sectionTitle'}>HOW TO PLAY</p>
            <h2 className={styles.howToPlayHeader}>Start Scratching</h2>
          </div>
          <div className={styles.descriptionContainer}>
            <p className={styles.description}>
              Experience the thrill of instant wins with ScratchMania! Follow
              these simple steps to begin your adventure.
            </p>
          </div>
        </div>
        <div className={styles.howToPlayContent}>
          <ol className={styles.steps}>
            {data.map((data, index) => {
              return (
                <li
                  key={data.step}
                  onClick={() => handleStepActions(data.step)}>
                  <HowToPlayCard
                    number={index + 1}
                    step={data.step}
                    description={data.description}
                  />
                </li>
              );
            })}
          </ol>
          <div>
            <img src="./how-to-play.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPlaySection;
