import React from 'react';
import AccountHeader from './AccountHeader/AccountHeader';
import ProtectedPage from '../../components/ProtectedRoute/ProtectedPage';

const MyAccount = () => {
  return (
    <ProtectedPage>
      <div>
        <AccountHeader />

        <div>
          <h3>Security</h3>
          <table>
            <tbody>
              <tr>
                <td>Email Address</td>
                <td>emailaddress@email.com</td>
                <td>Verified</td>
                <td>Change Email</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>+639xx-xxx-xxxx</td>
                <td>Not Linked</td>
                <td>Verify</td>
              </tr>
              <tr>
                <td>Password</td>
                <td>**********</td>
                <td>Secure</td>
                <td>secure</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h3>ACTIVITY TRACKER</h3>
          <table>
            <thead>
              <tr>
                <th>CASH REFERENCE #</th>
                <th>DATE AND TIME</th>
                <th>TRANSACTION TYPE</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>sm-yyyymmdd-000008</td>
                <td>12/31/2024, 11:38PM </td>
                <td>Cash-In</td>
                <td>+ 1,500.00 PHP</td>
                <td>complete</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ProtectedPage>
  );
};

export default MyAccount;
