// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quickMenu_quickMenu__nvAiq {
  max-width: 9rem;
}

.quickMenu_quickMenu__nvAiq h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

.quickMenu_quickMenu__nvAiq ul {
  margin-top: 2rem;
  list-style: none;
  color: white;
}

.quickMenu_quickMenu__nvAiq ul li {
  margin: 0.5rem 0;
}

.quickMenu_quickMenu__nvAiq ul li a {
  color: white;
  text-decoration: none;
  font-weight: 250;
  font-family: var(--font-nunito-sans);
  font-size: 1.125rem;
  cursor: pointer;
}

.quickMenu_quickMenu__nvAiq ul li a:hover,
.quickMenu_quickMenu__nvAiq ul li a:active {
  color: var(--primary-orange-yellow);
}
`, "",{"version":3,"sources":["webpack://./src/components/FooterSection/QuickMenu/quickMenu.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;EAChB,oCAAoC;EACpC,mBAAmB;EACnB,eAAe;AACjB;;AAEA;;EAEE,mCAAmC;AACrC","sourcesContent":[".quickMenu {\n  max-width: 9rem;\n}\n\n.quickMenu h3 {\n  font-size: 1.5rem;\n  font-weight: 700;\n}\n\n.quickMenu ul {\n  margin-top: 2rem;\n  list-style: none;\n  color: white;\n}\n\n.quickMenu ul li {\n  margin: 0.5rem 0;\n}\n\n.quickMenu ul li a {\n  color: white;\n  text-decoration: none;\n  font-weight: 250;\n  font-family: var(--font-nunito-sans);\n  font-size: 1.125rem;\n  cursor: pointer;\n}\n\n.quickMenu ul li a:hover,\n.quickMenu ul li a:active {\n  color: var(--primary-orange-yellow);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quickMenu": `quickMenu_quickMenu__nvAiq`
};
export default ___CSS_LOADER_EXPORT___;
