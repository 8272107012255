import React, { useEffect, type ReactNode } from 'react';
import { useAuth } from '../../context/AuthContextProvider';
import { ModalType, useModal } from '../../context/ModalContextProvider';
import { AuthType } from '../Modal/Auth/Auth';

type ProtectedPageProps = {
  children: ReactNode;
};

const ProtectedPage = ({ children }: ProtectedPageProps) => {
  const { isAuthenticated } = useAuth();
  const { openModal } = useModal();

  useEffect(() => {
    if (!isAuthenticated) {
      openModal(ModalType.AUTH, { authType: AuthType.Login });
    }
  }, [isAuthenticated, openModal]);

  if (!isAuthenticated) {
    return null;
  }

  return children;
};

export default ProtectedPage;
