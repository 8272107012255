import React from 'react';
import styles from './topupSection.module.css';

import PaymentMethods from './PaymentMethods/PaymentMethods';
import GameStory from './GameStory/GameStory';
import TopUpStats from './TopUpStats/TopUpStats';

const TopupSection = () => {
  return (
    <div className={styles.sectionWrapper}>
      <div className={`${styles.topUpContainer} container`}>
        <div className={styles.topUpThrough}>
          <div>
            <h2>TOPUP NOW THROUGH</h2>
          </div>
          <PaymentMethods />
          <GameStory />
          <TopUpStats />
        </div>
      </div>
    </div>
  );
};

export default TopupSection;
