// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gamesCollection_gamesCollectionContainer__uXm9v {
  padding: 6.0625rem 0;
  width: 100%;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gamesCollection_gamesCollectionContainer__uXm9v .gamesCollection_gamesCollectionCopy__cix-- {
  max-width: 70%;
  opacity: 60%;
  font-size: 18px;
}

.gamesCollection_gamesCollectionHeader__5B\\+28 {
  font-size: 4.6875rem;
  line-height: 4.6875rem;

  font-weight: 600;
  text-transform: capitalize;
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
}

.gamesCollection_gamesContainer__KgS76 {
  display: flex;
  gap: 1.875rem;
  margin-top: 4.6875rem;
}

.gamesCollection_viewCardsBtn__qWVOE {
  background-color: rgb(245, 41, 161);
  width: 12.3125rem;
  height: 3.4375rem;

  margin-top: 3.5rem;
  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/GamesCollectionSection/gamesCollection.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,WAAW;;EAEX,kBAAkB;;EAElB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,sBAAsB;;EAEtB,gBAAgB;EAChB,0BAA0B;EAC1B,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,mCAAmC;EACnC,iBAAiB;EACjB,iBAAiB;;EAEjB,kBAAkB;EAClB,0BAA0B;;EAE1B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".gamesCollectionContainer {\n  padding: 6.0625rem 0;\n  width: 100%;\n\n  text-align: center;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.gamesCollectionContainer .gamesCollectionCopy {\n  max-width: 70%;\n  opacity: 60%;\n  font-size: 18px;\n}\n\n.gamesCollectionHeader {\n  font-size: 4.6875rem;\n  line-height: 4.6875rem;\n\n  font-weight: 600;\n  text-transform: capitalize;\n  margin-top: 1.25rem;\n  margin-bottom: 1.875rem;\n}\n\n.gamesContainer {\n  display: flex;\n  gap: 1.875rem;\n  margin-top: 4.6875rem;\n}\n\n.viewCardsBtn {\n  background-color: rgb(245, 41, 161);\n  width: 12.3125rem;\n  height: 3.4375rem;\n\n  margin-top: 3.5rem;\n  text-transform: capitalize;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gamesCollectionContainer": `gamesCollection_gamesCollectionContainer__uXm9v`,
	"gamesCollectionCopy": `gamesCollection_gamesCollectionCopy__cix--`,
	"gamesCollectionHeader": `gamesCollection_gamesCollectionHeader__5B+28`,
	"gamesContainer": `gamesCollection_gamesContainer__KgS76`,
	"viewCardsBtn": `gamesCollection_viewCardsBtn__qWVOE`
};
export default ___CSS_LOADER_EXPORT___;
