import React, { useEffect } from 'react';
import FaqCard from './FaqCard/FaqCard';
import classNames from 'classnames';
import { topicCardsData } from './TopicCardsData';
import style from './faqCardGrid.module.css';
import { useFaqTopic } from '../../context/FaqContextProvider';

const _topicCardsData = topicCardsData.slice(1);

const TopicCards = () => {
  const { handleFaqTopicClick, activeFaqTopic, searchQuery } = useFaqTopic();

  return (
    <>
      <ul
        className={classNames(style.root, {
          [style.hidden]: searchQuery !== ''
        })}>
        {_topicCardsData.map(({ title, icon, content }, index) => {
          return (
            <li
              onClick={() => handleFaqTopicClick(title)}
              className={style.cardWrapper}
              key={`${title}-${index}`}>
              <FaqCard
                title={title}
                icon={icon}
                content={content}
                isActive={activeFaqTopic === title}
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default TopicCards;
