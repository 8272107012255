import React from 'react';
import styles from './howToPlayCard.module.css';

type HowToPlayCardProps = {
  number: number;
  step: string;
  description: string;
};

const HowToPlayCard = ({ number, step, description }: HowToPlayCardProps) => {
  return (
    <div className={styles.howToPlayCard}>
      <div className={styles.cardTitle}>
        <p className={styles.number}>0{number}</p>
        <p className={styles.step}>{step}</p>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default HowToPlayCard;
