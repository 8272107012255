import React from 'react';
import { IconState } from '../../../types/iconState';

type AliPayProps = {
  state?: IconState;
};

const AliPay = ({ state = IconState.Inactive }: AliPayProps) => {
  const fillColor = state === IconState.Inactive ? '#544E65' : '';

  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.1218 13.7369C16.9863 12.2387 17.6762 10.5318 18.1305 8.67759H13.3861V6.97376H19.1975V6.02307H13.3861V3.18202H11.0143C10.5982 3.18202 10.5982 3.59118 10.5982 3.59118V6.02307H4.7205V6.97376H10.5982V8.67759H5.7454V9.62828H15.1581C14.8141 10.8116 14.3508 11.9228 13.8022 12.9316C10.7486 11.9268 7.48934 11.1125 5.44154 11.6139C4.13283 11.9358 3.28944 12.5094 2.79304 13.1111C0.519594 15.87 2.15021 20.0598 6.95182 20.0598C9.79086 20.0598 12.5266 18.4814 14.6466 15.88C17.8086 17.3963 24.0683 19.9987 24.0683 19.9987V20.1561C24.0683 22.2791 22.3444 24 20.2144 24H3.85605C1.72501 24 0.00012207 22.2781 0.00012207 20.1551V3.84489C0.00012207 1.72088 1.72501 0 3.85605 0H20.2144C22.3444 0 24.0683 1.72088 24.0683 3.84489V16.2892C24.0683 16.2892 23.2821 16.227 19.8163 15.0697C18.8535 14.7478 17.5609 14.2564 16.1218 13.7369ZM5.8507 13.0369C5.249 13.0961 4.1208 13.3619 3.50305 13.9054C1.65281 15.514 2.75994 18.4553 6.50455 18.4553C8.68072 18.4553 10.8559 17.0674 12.5637 14.8461C10.1328 13.6637 8.075 12.8183 5.8507 13.0369Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#089CE6' : fillColor}`}
        />
      </svg>
    </>
  );
};

export default AliPay;
