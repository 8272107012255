/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import styles from './responsiblePlayer.module.css'

function ResponsiblePlayer() {
    return (
        <div className={styles.sectionWrapper}>
            <div className={`${styles.playerContainer} container`}>
                <div className={styles.playerCopy}>
                <p>
                    Scratch cards are one of the most convenient types of Casino Games that give entertainment and instant gratification to the Players. Now
                    that you can play this type of game anytime and anywhere thanks to Scratch Mania Mobile App, it's good to be reminded once in a while of
                    the best ways you can enjoy it.
                </p>
                    <h1 className={styles.playerTitle}>Be a Responsible Player</h1>
                    <p>
                        When playing online casino games, we recommend you consider the prizes relative to the amount you spend. We understand everyone 
                        wants to achieve maximum payouts and playing scratch cards may be a thrilling and enjoyable game. However, if you do not play 
                        sensibly, you are at risk of developing an addiction and may have financial losses.
                    </p>
                    <br />
                    <p>
                        Thus, ScratchMania card games are meant for entertainment purposes and think of the prizes secondarily. We encourage you not to make 
                        this your only way to make money. 
                    </p>
                    <h1 className={styles.playerTitle}>Manage How Much You Can Bet</h1>
                    <p>
                        Before you start playing, know your spending limit and stick to it. Never risk money you can't afford to lose. Never gamble with funds that 
                        are necessary for your financial stability. 
                    </p>
                    <br />
                    <p>
                        Instead of using your balance, you can alternatively use your Elevate Gems which you earn when you refer a friend or share the game online.
                    </p>
                    <br />
                    <p>
                        Never chase your losses by increasing your tier selection or playing for longer periods. We discourage you from playing more as a way to 
                        recoup your losses.
                    </p>
                    <h1 className={styles.playerTitle}>Take Breaks and Know Your Limits</h1>
                    <p>
                        While we appreciate your continued patronage of our platform, taking regular breaks from playing ScratchMania games is highly 
                        encouraged. We want you not to dedicate your entire time and attention to playing our Scratch Cards. We encourage you to spend time with 
                        your family and friends, focus on work, and do recreational studies apart from playing.
                    </p>
                    <h1 className={styles.playerTitle}>Seek Help</h1>
                    <p>
                        If you think or feel that you may be struggling with gambling-related issues, we encourage you to talk to your friends and family. You may 
                        also contact our customer service team to give advice on how to get over it or contact Gambling Anonymous by visiting their website - 
                        https://gamblersanonymous.ph/
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ResponsiblePlayer
