import React from 'react';
import { IconState } from '../../../types/iconState';

import TikTokActive from './TikTokActive';
import TikTokNormal from './TikTokNormal';

type TikTokProps = {
  state?: IconState;
};

const TikTok = ({ state }: TikTokProps) => {
  return (
    <>
      {state === IconState.Active || state === IconState.Hover ? (
        <TikTokActive />
      ) : (
        <TikTokNormal />
      )}
    </>
  );
};

export default TikTok;
