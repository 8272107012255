// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerSection_footer__yoSkE {
  margin: 0 auto;
  margin-top: 10.063rem;
}

.footerSection_footerInfo__0EmLi {
  background-color: rgba(201, 223, 219, 0.3);
  padding: 2.375rem;
  border-radius: 0.625rem;

  display: flex;
  flex-direction: row;
  gap: 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/FooterSection/footerSection.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0CAA0C;EAC1C,iBAAiB;EACjB,uBAAuB;;EAEvB,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".footer {\n  margin: 0 auto;\n  margin-top: 10.063rem;\n}\n\n.footerInfo {\n  background-color: rgba(201, 223, 219, 0.3);\n  padding: 2.375rem;\n  border-radius: 0.625rem;\n\n  display: flex;\n  flex-direction: row;\n  gap: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footerSection_footer__yoSkE`,
	"footerInfo": `footerSection_footerInfo__0EmLi`
};
export default ___CSS_LOADER_EXPORT___;
