// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQSection_faqSection__4D2Hb {
  padding: 5.9375rem 0;
  background-size: 100% 100%;
  background-position: center;

  display: flex;
  column-gap: 2rem;

  width: 100%;
}

.FAQSection_faqHeader__AEwdg {
  width: 60rem;
  max-width: 29.688rem;
}

.FAQSection_faqContainer__fAlI9 {
  max-width: 69.4375rem;
  margin: 4.625rem auto 0 auto;

  display: flex;
  transition: height 1s ease-in-out;
}

.FAQSection_faqDescription__uN4Hs {
  font-family: var(--font-nunito-sans);
  font-size: 1.125rem;
  color: rgba(255, 255, 255, 0.6);
  max-width: 86%;
}

.FAQSection_faqTitle__x-02Y {
  margin-top: 1rem;
  font-family: var(--font-rajdhani);
  font-size: 4.6875rem;
  line-height: 4.6875rem;
  font-weight: 600;
  margin-bottom: 3.125rem;
}

.FAQSection_faqLink__NjJ3V {
  color: var(--primary-orange-yellow);
  margin: 0 0.2em;
  cursor: pointer;
  font-weight: 700;
}

.FAQSection_faqBackground__tHJtV {
  position: absolute;
  z-index: -10;
  transform: translate(-30rem, -5rem);
}
`, "",{"version":3,"sources":["webpack://./src/components/FAQSection/FAQSection.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,0BAA0B;EAC1B,2BAA2B;;EAE3B,aAAa;EACb,gBAAgB;;EAEhB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,4BAA4B;;EAE5B,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,+BAA+B;EAC/B,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iCAAiC;EACjC,oBAAoB;EACpB,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,mCAAmC;EACnC,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,mCAAmC;AACrC","sourcesContent":[".faqSection {\n  padding: 5.9375rem 0;\n  background-size: 100% 100%;\n  background-position: center;\n\n  display: flex;\n  column-gap: 2rem;\n\n  width: 100%;\n}\n\n.faqHeader {\n  width: 60rem;\n  max-width: 29.688rem;\n}\n\n.faqContainer {\n  max-width: 69.4375rem;\n  margin: 4.625rem auto 0 auto;\n\n  display: flex;\n  transition: height 1s ease-in-out;\n}\n\n.faqDescription {\n  font-family: var(--font-nunito-sans);\n  font-size: 1.125rem;\n  color: rgba(255, 255, 255, 0.6);\n  max-width: 86%;\n}\n\n.faqTitle {\n  margin-top: 1rem;\n  font-family: var(--font-rajdhani);\n  font-size: 4.6875rem;\n  line-height: 4.6875rem;\n  font-weight: 600;\n  margin-bottom: 3.125rem;\n}\n\n.faqLink {\n  color: var(--primary-orange-yellow);\n  margin: 0 0.2em;\n  cursor: pointer;\n  font-weight: 700;\n}\n\n.faqBackground {\n  position: absolute;\n  z-index: -10;\n  transform: translate(-30rem, -5rem);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faqSection": `FAQSection_faqSection__4D2Hb`,
	"faqHeader": `FAQSection_faqHeader__AEwdg`,
	"faqContainer": `FAQSection_faqContainer__fAlI9`,
	"faqDescription": `FAQSection_faqDescription__uN4Hs`,
	"faqTitle": `FAQSection_faqTitle__x-02Y`,
	"faqLink": `FAQSection_faqLink__NjJ3V`,
	"faqBackground": `FAQSection_faqBackground__tHJtV`
};
export default ___CSS_LOADER_EXPORT___;
