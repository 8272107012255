// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar_avatarBorder__Pyhf2 {
  border: 10px solid;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem;
  border-radius: 50%;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    180deg,
    #f9d030 0%,
    #f62aa0 47.69%,
    #ff5412 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar_avatar__flEaO {
  width: 11.143rem;
  height: 11.143rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MyAccount/AccountHeader/Avatar/avatar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAkB;EAAlB,kBAAkB;EAClB,wBAAmB;EAAnB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB;;;;;GAKC;;EAED,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".avatarBorder {\n  border: 10px solid;\n  width: fit-content;\n  height: fit-content;\n  padding: 1rem;\n  border-radius: 50%;\n  border-image-slice: 1;\n  border-image-source: linear-gradient(\n    180deg,\n    #f9d030 0%,\n    #f62aa0 47.69%,\n    #ff5412 100%\n  );\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.avatar {\n  width: 11.143rem;\n  height: 11.143rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarBorder": `avatar_avatarBorder__Pyhf2`,
	"avatar": `avatar_avatar__flEaO`
};
export default ___CSS_LOADER_EXPORT___;
