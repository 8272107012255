// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .PurposePolicy_playerContainer__wkpcf {
     /* position: relative; */
     margin: 4.625rem auto;

     display: flex;
 }

 .PurposePolicy_playerCopy__d5NB4 {
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: start;
    
 }


 .PurposePolicy_playerCopy__d5NB4 p {
     font-family: var(--font-nunito-sans);
     font-size: 18px;
     max-width: 100ch;
     color: #d4d4d4;
     font-weight: 380;
     line-height: 1.700rem;
     text-align: justify;
 }

 .PurposePolicy_playerTitle__Qo-lI {
     margin-top: 2rem;
     font-size: 30px;
     font-family: var(--font-rajdhani);
     color: var(--primary-orange-yellow);
     font-weight: 700;
 }

 .PurposePolicy_orderedList__KsXLY{
    margin-top: .5rem;
    margin-left: 2rem;
    list-style-type: decimal;
    font-size: 1.180rem;
    max-width: 100ch;
    font-family: var(--font-nunito-sans);
    color: #d4d4d4;
    font-weight: 400;

 }

 .PurposePolicy_orderedList__KsXLY strong{
     color: white;
 }`, "",{"version":3,"sources":["webpack://./src/components/PrivacyPolicy/PurposePolicy/PurposePolicy.module.css"],"names":[],"mappings":"CAAC;KACI,wBAAwB;KACxB,qBAAqB;;KAErB,aAAa;CACjB;;CAEA;KACI,WAAW;KACX,aAAa;KACb,sBAAsB;KACtB,uBAAuB;KACvB,kBAAkB;;CAEtB;;;CAGA;KACI,oCAAoC;KACpC,eAAe;KACf,gBAAgB;KAChB,cAAc;KACd,gBAAgB;KAChB,qBAAqB;KACrB,mBAAmB;CACvB;;CAEA;KACI,gBAAgB;KAChB,eAAe;KACf,iCAAiC;KACjC,mCAAmC;KACnC,gBAAgB;CACpB;;CAEA;IACG,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;IACxB,mBAAmB;IACnB,gBAAgB;IAChB,oCAAoC;IACpC,cAAc;IACd,gBAAgB;;CAEnB;;CAEA;KACI,YAAY;CAChB","sourcesContent":[" .playerContainer {\n     /* position: relative; */\n     margin: 4.625rem auto;\n\n     display: flex;\n }\n\n .playerCopy {\n     width: 100%;\n     display: flex;\n     flex-direction: column;\n     justify-content: center;\n     align-items: start;\n    \n }\n\n\n .playerCopy p {\n     font-family: var(--font-nunito-sans);\n     font-size: 18px;\n     max-width: 100ch;\n     color: #d4d4d4;\n     font-weight: 380;\n     line-height: 1.700rem;\n     text-align: justify;\n }\n\n .playerTitle {\n     margin-top: 2rem;\n     font-size: 30px;\n     font-family: var(--font-rajdhani);\n     color: var(--primary-orange-yellow);\n     font-weight: 700;\n }\n\n .orderedList{\n    margin-top: .5rem;\n    margin-left: 2rem;\n    list-style-type: decimal;\n    font-size: 1.180rem;\n    max-width: 100ch;\n    font-family: var(--font-nunito-sans);\n    color: #d4d4d4;\n    font-weight: 400;\n\n }\n\n .orderedList strong{\n     color: white;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playerContainer": `PurposePolicy_playerContainer__wkpcf`,
	"playerCopy": `PurposePolicy_playerCopy__d5NB4`,
	"playerTitle": `PurposePolicy_playerTitle__Qo-lI`,
	"orderedList": `PurposePolicy_orderedList__KsXLY`
};
export default ___CSS_LOADER_EXPORT___;
