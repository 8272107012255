// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBar_wrapper__VULCt {
  width: 100%;
  height: 3.75rem;
  border: 1px solid rgba(249, 207, 49, 1);
  border-radius: 0.5rem;

  display: flex;
}

.searchBar_searchBar__KLmG3 {
  color: white;
  position: relative;
  height: 100%;

  background-color: transparent;
  border: none;
  flex-grow: 1;

  padding: 1rem;
}

.searchBar_searchBar__KLmG3:focus {
  outline: none;
}

.searchBar_searchButton__y6mg9 {
  width: 58px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/searchBar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,uCAAuC;EACvC,qBAAqB;;EAErB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;;EAEZ,6BAA6B;EAC7B,YAAY;EACZ,YAAY;;EAEZ,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,+BAA+B;EAC/B,kCAAkC;AACpC","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 3.75rem;\n  border: 1px solid rgba(249, 207, 49, 1);\n  border-radius: 0.5rem;\n\n  display: flex;\n}\n\n.searchBar {\n  color: white;\n  position: relative;\n  height: 100%;\n\n  background-color: transparent;\n  border: none;\n  flex-grow: 1;\n\n  padding: 1rem;\n}\n\n.searchBar:focus {\n  outline: none;\n}\n\n.searchButton {\n  width: 58px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  border-top-right-radius: 0.5rem;\n  border-bottom-right-radius: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `searchBar_wrapper__VULCt`,
	"searchBar": `searchBar_searchBar__KLmG3`,
	"searchButton": `searchBar_searchButton__y6mg9`
};
export default ___CSS_LOADER_EXPORT___;
