import { useNavigate, useLocation } from 'react-router-dom';

/**
 * Converts a string into a URL-friendly format by removing or replacing unwanted characters.
 * @param {string} str - The input string to be converted.
 * @returns {string} - The URL-friendly string.
 */
export const sanitizeForUrl = (str: string) => {
  // Convert to lowercase
  return (
    str
      .toLowerCase()
      // Replace spaces and consecutive whitespace with a single hyphen
      .replace(/\s+/g, '-')
      // Remove any character that is not a letter, number, hyphen, or dot
      .replace(/[^a-z0-9.-]/g, '')
      // Remove leading and trailing hyphens and dots
      .replace(/^-+|-+$/g, '')
      .replace(/^\.+|\.+$/g, '')
  );
};
