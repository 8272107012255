import React from 'react';
import styles from './contactForm.module.css';
import ArrowRight from '../../../components/Icons/ArrowRight/ArrowRight';
import classNames from 'classnames';
import globalStyle from '../../../global.module.css';

const ContactForm = () => {
  return (
    <div className={styles.root}>
      <h2 className={styles.formTitle}>Drop Us Your Message</h2>
      <p className={styles.description}>
        Freely contact with us anytime. We&apos;re available here for you.
      </p>
      <form className={styles.form} action="">
        <div className={styles.formGroup}>
          <input
            className={`${styles.contactFormField} ${styles.contactFormFieldHalf}`}
            type="text"
            name=""
            id=""
            placeholder="Full Name"
          />
          <input
            className={`${styles.contactFormField} ${styles.contactFormFieldHalf}`}
            type="email"
            name=""
            id=""
            placeholder="Your Email"
          />
        </div>

        <input
          className={styles.contactFormField}
          type="text"
          placeholder="Subject"
        />
        <textarea
          className={`${styles.contactFormField} ${styles.textArea}`}
          id="story"
          name="story"
          placeholder="Message..."></textarea>

        <button
          className={classNames(styles.sendBtn, globalStyle.btnIconReveal)}
          type="submit">
          <span> Send Message</span>
          <ArrowRight className={globalStyle.icon} />
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
