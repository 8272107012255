import React, { ElementType, useState } from 'react';
import styles from './paymentItem.module.css';
import { IconState } from '../../../../types/iconState';

type PaymentMethodProps = {
  IconComponent: ElementType<{ state: IconState }>;
};

const PaymentItem = ({ IconComponent }: PaymentMethodProps) => {
  const [state, setState] = useState(IconState.Inactive);

  return (
    <li
      className={styles.paymentWrapper}
      onClick={() => setState(IconState.Active)}
      onMouseEnter={() => setState(IconState.Hover)}
      onMouseLeave={() => setState(IconState.Inactive)}>
      <IconComponent state={state} />
    </li>
  );
};

export default PaymentItem;
