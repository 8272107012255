import React from 'react';

const LocationIconYellow = () => {
  return (
    <div>
      <svg
        width="43"
        height="39"
        viewBox="0 0 43 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.5488 0.543945C30.6619 0.543945 38.0489 7.54547 38.0489 16.1831C38.0489 22.7916 33.1496 29.9937 23.4781 37.8662C22.9403 38.304 22.2546 38.5445 21.5457 38.5439C20.8367 38.5434 20.1515 38.3017 19.6145 37.863L18.9748 37.3368C9.73145 29.6697 5.04883 22.6441 5.04883 16.1831C5.04883 7.54547 12.4358 0.543945 21.5488 0.543945ZM21.5488 10.168C19.8657 10.168 18.2516 10.8018 17.0614 11.9298C15.8713 13.0578 15.2027 14.5878 15.2027 16.1831C15.2027 17.7784 15.8713 19.3083 17.0614 20.4364C18.2516 21.5644 19.8657 22.1982 21.5488 22.1982C23.232 22.1982 24.8461 21.5644 26.0363 20.4364C27.2264 19.3083 27.895 17.7784 27.895 16.1831C27.895 14.5878 27.2264 13.0578 26.0363 11.9298C24.8461 10.8018 23.232 10.168 21.5488 10.168Z"
          fill="#F9CF31"
        />
      </svg>
    </div>
  );
};

export default LocationIconYellow;
