// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentItem_paymentWrapper__ESz0b {
  width: 5.0625rem;
  height: 2.5rem;

  background-color: #241d3a;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.paymentItem_paymentWrapper__ESz0b:hover {
  border: 1px solid rgba(255, 255, 255, 1);
  background-color: #5e596d;
}
`, "",{"version":3,"sources":["webpack://./src/components/TopupSection/PaymentMethods/PaymentItem/paymentItem.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;;EAEd,yBAAyB;EACzB,0CAA0C;EAC1C,kBAAkB;;EAElB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,wCAAwC;EACxC,yBAAyB;AAC3B","sourcesContent":[".paymentWrapper {\n  width: 5.0625rem;\n  height: 2.5rem;\n\n  background-color: #241d3a;\n  border: 1px solid rgba(255, 255, 255, 0.2);\n  border-radius: 3px;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n\n.paymentWrapper:hover {\n  border: 1px solid rgba(255, 255, 255, 1);\n  background-color: #5e596d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentWrapper": `paymentItem_paymentWrapper__ESz0b`
};
export default ___CSS_LOADER_EXPORT___;
