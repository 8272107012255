import React from 'react';
import styles from './quickMenu.module.css';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes.json';

const QuickMenu = () => {
  return (
    <div className={styles.quickMenu}>
      <h3>Quick Menu</h3>
      <ul>
        <li>
          <Link to={routes.home.url}>Home</Link>
        </li>
        <li>
          <Link to={routes.cards.url}>Cards</Link>
        </li>
        <li>
          <Link to={routes.home.url}>Dashboard</Link>
        </li>
        <li>
          <Link to={routes.contactUs.url}>Contact Us</Link>
        </li>
      </ul>
    </div>
  );
};

export default QuickMenu;
