// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.howToPlayCard_howToPlayCard__8qIDi {
  width: 21.875rem;
  height: 18.875rem;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.07);
}

.howToPlayCard_cardTitle__bdJcG {
  display: flex;
  align-items: center;
}

.howToPlayCard_number__GkkHV {
  color: var(--primary-orange-yellow);
  font-weight: 700;
  font-size: 1.5rem;

  margin-right: 1rem;
}

.howToPlayCard_step__dmAi1 {
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  padding-left: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  white-space: nowrap;
  line-height: 1.91375rem;
}

.howToPlayCard_description__whmAH {
  font-family: var(--font-nunito-sans);
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.534375rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/HowToPlaySection/HowToPlayCard/howToPlayCard.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,qBAAqB;EACrB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,gBAAgB;EAChB,iBAAiB;;EAEjB,kBAAkB;AACpB;;AAEA;EACE,+CAA+C;EAC/C,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,+BAA+B;EAC/B,wBAAwB;AAC1B","sourcesContent":[".howToPlayCard {\n  width: 21.875rem;\n  height: 18.875rem;\n  padding: 2rem;\n  border-radius: 0.5rem;\n  background-color: rgba(255, 255, 255, 0.07);\n}\n\n.cardTitle {\n  display: flex;\n  align-items: center;\n}\n\n.number {\n  color: var(--primary-orange-yellow);\n  font-weight: 700;\n  font-size: 1.5rem;\n\n  margin-right: 1rem;\n}\n\n.step {\n  border-left: 2px solid rgba(255, 255, 255, 0.2);\n  padding-left: 1rem;\n  font-weight: 700;\n  font-size: 1.5rem;\n  white-space: nowrap;\n  line-height: 1.91375rem;\n}\n\n.description {\n  font-family: var(--font-nunito-sans);\n  font-weight: 400;\n  font-size: 1rem;\n  margin-top: 1rem;\n  color: rgba(255, 255, 255, 0.6);\n  line-height: 1.534375rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"howToPlayCard": `howToPlayCard_howToPlayCard__8qIDi`,
	"cardTitle": `howToPlayCard_cardTitle__bdJcG`,
	"number": `howToPlayCard_number__GkkHV`,
	"step": `howToPlayCard_step__dmAi1`,
	"description": `howToPlayCard_description__whmAH`
};
export default ___CSS_LOADER_EXPORT___;
