import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
  useEffect
} from 'react';
import { AuthType } from '../components/Modal/Auth/Auth';

// add more modal types if in future we start adding more modal
export enum ModalType {
  AUTH = 'auth'
}

interface ModalContextType {
  isModalOpen: boolean;
  type: ModalType | null;
  authType: AuthType | null;
  openModal: (type: ModalType, opts?: { authType?: AuthType }) => void;
  closeModal: () => void;
}

interface ModalProviderProps {
  children: ReactNode;
}

type OpenModal = {
  type: ModalType;
  opts?: { authType?: AuthType };
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [type, setType] = useState<ModalType | null>(null);
  const [authType, setAuthType] = useState<AuthType | null>(null);

  const openModal = useCallback(
    (type: ModalType, opts?: { authType?: AuthType }) => {
      const _authType = opts?.authType;

      if (type === ModalType.AUTH && _authType === undefined) {
        throw new Error(
          'authType must be provided when the modal type is AUTH'
        );
      }
      setIsModalOpen(true);
      setType(type);

      if (type === ModalType.AUTH && _authType) {
        setAuthType(_authType);
      } else {
        setAuthType(null);
      }
    },
    []
  );

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setType(null);
    setAuthType(null);
  }, []);

  return (
    <ModalContext.Provider
      value={{ isModalOpen, type, authType, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useCategory must be used within a AuthModalProvider');
  }
  return context;
};
