import React from 'react';
import { IconState } from '../../../types/iconState';

type TwitterProps = {
  state?: IconState;
};

const Twitter = ({ state }: TwitterProps) => {
  const fillColor = state === IconState.Hover ? '#1D9BF0' : '';

  return (
    <>
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.1367 3.73438C16.1367 3.91016 16.1367 4.05078 16.1367 4.22656C16.1367 9.11328 12.4453 14.7031 5.66016 14.7031C3.55078 14.7031 1.61719 14.1055 0 13.0508C0.28125 13.0859 0.5625 13.1211 0.878906 13.1211C2.60156 13.1211 4.18359 12.5234 5.44922 11.5391C3.83203 11.5039 2.46094 10.4492 2.00391 8.97266C2.25 9.00781 2.46094 9.04297 2.70703 9.04297C3.02344 9.04297 3.375 8.97266 3.65625 8.90234C1.96875 8.55078 0.703125 7.07422 0.703125 5.28125V5.24609C1.19531 5.52734 1.79297 5.66797 2.39062 5.70312C1.37109 5.03516 0.738281 3.91016 0.738281 2.64453C0.738281 1.94141 0.914062 1.30859 1.23047 0.78125C3.05859 2.99609 5.80078 4.47266 8.85938 4.64844C8.78906 4.36719 8.75391 4.08594 8.75391 3.80469C8.75391 1.76562 10.4062 0.113281 12.4453 0.113281C13.5 0.113281 14.4492 0.535156 15.1523 1.27344C15.9609 1.09766 16.7695 0.78125 17.4727 0.359375C17.1914 1.23828 16.6289 1.94141 15.8555 2.39844C16.5938 2.32812 17.332 2.11719 17.9648 1.83594C17.4727 2.57422 16.8398 3.20703 16.1367 3.73438Z"
          fill={`${state === IconState.Hover ? fillColor : 'white'}`}
        />
      </svg>
    </>
  );
};

export default Twitter;
