import React, { useMemo } from 'react';
import styles from './helpCenter.module.css';
import PageHeader from './PageHeader/PageHeader';
import SearchBar from '../../components/SearchBar/SearchBar';
import TopicCards from '../../components/FaqCardGrid/TopicCards';
import Accordion from './Accordion/Accordion';
import { helpCenterData } from './data/data';
import { useFaqTopic, FaqProvider } from '../../context/FaqContextProvider';
import { topicCardsData } from '../../components/FaqCardGrid/TopicCardsData';
import { extractTextFromContent } from '../../utils/getTextFromContent';

const findCardByTitle = (title: string) => {
  return topicCardsData.find((card) => card.title === title);
};

const HelpCenterContent = () => {
  const { activeFaqTopic, searchQuery } = useFaqTopic();

  const cardTopic = useMemo(() => {
    return findCardByTitle(activeFaqTopic ?? 'Popular Questions');
  }, [activeFaqTopic]);

  // update this every time user clicks a card topic
  const activeTopicData = searchQuery
    ? Object.values(helpCenterData).flat()
    : helpCenterData[activeFaqTopic ?? 'Popular Questions'];

  const filteredFAQs = useMemo(() => {
    if (!searchQuery) return [];

    return Object.values(helpCenterData)
      .flat()
      .filter(({ title, content }) => {
        const textContent = extractTextFromContent(content);
        return (
          title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          textContent.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
  }, [searchQuery, activeFaqTopic]);

  return (
    <div>
      <PageHeader />

      <div className={`${styles.faqContentContainer} container`}>
        <SearchBar />
        <TopicCards />
      </div>

      <div className={styles.sectionWrapper}>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            {searchQuery ? (
              <h2 className={`${styles.faqContentTitle}`}>
                Result for &quot;{searchQuery}&quot;
              </h2>
            ) : (
              <>
                <h2 className={`${styles.faqContentTitle}`}>
                  {cardTopic?.title}
                </h2>
                <p className={styles.faqContentCopy}>{cardTopic?.content}</p>
              </>
            )}
          </div>
        </div>

        <div className={`${styles.faqContentContainer} container`}>
          <div className={styles.accordionWrapper}>
            {searchQuery ? (
              <Accordion
                items={filteredFAQs}
                opts={{ searchQuery: searchQuery, allowMultipleActive: true }}
              />
            ) : (
              <Accordion
                items={activeTopicData}
                opts={{ defaultActiveIndex: 0 }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const HelpCenter = () => {
  return (
    <FaqProvider>
      <HelpCenterContent />
    </FaqProvider>
  );
};

export default HelpCenter;
