import React from 'react';

type ResultProps = {
  iconType: 'light' | 'dark';
};

const Results = ({ iconType }: ResultProps) => {
  const fillOpacity = iconType === 'dark' ? 1 : 0.3;
  const fill = iconType === 'dark' ? '#000000' : '#C9DFDB';

  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.1938 16.2023C40.7063 15.4901 40.3682 14.6864 40.2 13.8398C39.7916 11.588 38.7058 9.51432 37.0875 7.89604C35.4692 6.27775 33.3956 5.1919 31.1437 4.78359C30.302 4.6185 29.5038 4.28008 28.8 3.78986C26.9356 2.55185 24.7474 1.89148 22.5094 1.89148C20.2714 1.89148 18.0831 2.55185 16.2188 3.78986C15.5065 4.27738 14.7028 4.61545 13.8563 4.78366C11.6044 5.19205 9.53081 6.27792 7.91254 7.89619C6.29427 9.51446 5.20839 11.5881 4.8 13.8399C4.63481 14.6816 4.29641 15.4797 3.80628 16.1836C2.54775 18.0401 1.875 20.2314 1.875 22.4742C1.875 24.7171 2.54775 26.9084 3.80628 28.7649C4.29369 29.4772 4.63173 30.2808 4.8 31.1274C5.20839 33.3792 6.29427 35.4528 7.91254 37.0711C9.53081 38.6893 11.6044 39.7752 13.8563 40.1836C14.6979 40.3488 15.4961 40.6872 16.2 41.1773C18.0565 42.4359 20.2478 43.1086 22.4906 43.1086C24.7335 43.1086 26.9248 42.4359 28.7813 41.1773C29.4936 40.6899 30.2972 40.3519 31.1438 40.1836C33.3956 39.7752 35.4692 38.6893 37.0875 37.0711C38.7058 35.4528 39.7916 33.3792 40.2 31.1274C40.3652 30.2856 40.7036 29.4874 41.1939 28.7836C42.4523 26.9271 43.125 24.7358 43.125 22.493C43.125 20.2501 42.4523 18.0588 41.1938 16.2023ZM31.3257 18.1843L22.727 26.783C21.6716 27.8365 20.2412 28.4281 18.75 28.4281C17.2587 28.4281 15.8284 27.8365 14.7729 26.783L13.6743 25.6843C13.3227 25.3327 13.1252 24.8559 13.1252 24.3586C13.1252 23.8614 13.3227 23.3845 13.6743 23.0329C14.0259 22.6813 14.5028 22.4838 15 22.4838C15.4972 22.4838 15.9741 22.6813 16.3257 23.0329L17.4243 24.1313C17.7763 24.4822 18.253 24.6793 18.75 24.6793C19.247 24.6793 19.7238 24.4822 20.0757 24.1313L28.6743 15.533C28.8481 15.3573 29.0548 15.2177 29.2827 15.1221C29.5106 15.0266 29.7551 14.9771 30.0022 14.9764C30.2493 14.9757 30.494 15.0239 30.7225 15.1181C30.9509 15.2123 31.1584 15.3508 31.3331 15.5255C31.5078 15.7002 31.6463 15.9078 31.7405 16.1362C31.8348 16.3646 31.8829 16.6094 31.8822 16.8565C31.8815 17.1035 31.832 17.3481 31.7365 17.5759C31.641 17.8038 31.5014 18.0106 31.3257 18.1843Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default Results;
