import React from 'react';

type GameInterfaceProps = {
  iconType: 'light' | 'dark';
};

const GameInterface = ({ iconType }: GameInterfaceProps) => {
  const fillOpacity = iconType === 'dark' ? 1 : 0.3;
  const fill = iconType === 'dark' ? '#000000' : '#C9DFDB';

  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_391_899)">
        <path
          d="M36.5625 12.0375V9.37498C36.5616 6.88885 35.5736 4.50481 33.8156 2.74686C32.0577 0.9889 29.6736 0.000894524 27.1875 0L12.1875 0C9.70135 0.000894524 7.31731 0.9889 5.55936 2.74686C3.8014 4.50481 2.81339 6.88885 2.8125 9.37498V35.625C2.81339 38.1111 3.8014 40.4951 5.55935 42.2531C7.3173 44.0111 9.70135 44.9991 12.1875 45H27.1875C29.6737 44.9991 32.0577 44.0111 33.8156 42.2531C35.5736 40.4951 36.5616 38.1111 36.5625 35.625V32.9625C38.2316 32.2303 39.6518 31.0289 40.6505 29.5042C41.6492 27.9796 42.1831 26.1976 42.1875 24.375V20.625C42.1831 18.8024 41.6491 17.0204 40.6504 15.4958C39.6518 13.9712 38.2315 12.7697 36.5625 12.0375ZM24.5438 3.74998L23.2125 5.0812C22.8531 5.42362 22.3777 5.61782 21.8813 5.625H17.4937C16.9973 5.61783 16.5219 5.42364 16.1624 5.0812L14.8312 3.74998H24.5438ZM23.4375 39.375H15.9375C15.4402 39.375 14.9633 39.1775 14.6117 38.8258C14.26 38.4742 14.0625 37.9973 14.0625 37.5C14.0625 37.0027 14.26 36.5258 14.6117 36.1741C14.9633 35.8225 15.4402 35.625 15.9375 35.625H23.4375C23.9348 35.625 24.4117 35.8225 24.7633 36.1741C25.115 36.5258 25.3125 37.0027 25.3125 37.5C25.3125 37.9973 25.115 38.4742 24.7633 38.8258C24.4117 39.1775 23.9348 39.375 23.4375 39.375ZM38.4375 24.375C38.4331 25.8654 37.8391 27.2936 36.7852 28.3476C35.7312 29.4015 34.303 29.9956 32.8125 30H17.8125C17.3157 29.9985 16.8396 29.8005 16.4883 29.4492C16.137 29.0979 15.939 28.6218 15.9375 28.125V20.625C15.9419 19.1345 16.5359 17.7062 17.5898 16.6523C18.6438 15.5983 20.072 15.0043 21.5625 15H32.8125C34.303 15.0043 35.7312 15.5984 36.7852 16.6523C37.8391 17.7063 38.4332 19.1345 38.4375 20.625V24.375Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
        <path
          d="M32.8123 16.875H25.3123C25.0651 16.8735 24.82 16.9209 24.5912 17.0145C24.3624 17.108 24.1544 17.2459 23.979 17.4201C23.8037 17.5944 23.6646 17.8016 23.5696 18.0298C23.4747 18.2581 23.4258 18.5028 23.4258 18.75C23.4258 18.9972 23.4747 19.242 23.5696 19.4702C23.6646 19.6985 23.8037 19.9057 23.979 20.0799C24.1544 20.2542 24.3624 20.392 24.5912 20.4856C24.82 20.5791 25.0651 20.6265 25.3123 20.625H32.8123C33.3076 20.622 33.7816 20.4231 34.1307 20.0718C34.4799 19.7205 34.6759 19.2453 34.6759 18.75C34.6759 18.2547 34.4799 17.7795 34.1307 17.4282C33.7816 17.0769 33.3076 16.8781 32.8123 16.875Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
        <path
          d="M32.8123 22.5H25.3123C25.0651 22.4985 24.82 22.5459 24.5912 22.6395C24.3624 22.733 24.1544 22.8709 23.979 23.0451C23.8037 23.2194 23.6646 23.4266 23.5696 23.6548C23.4747 23.8831 23.4258 24.1278 23.4258 24.375C23.4258 24.6222 23.4747 24.867 23.5696 25.0952C23.6646 25.3235 23.8037 25.5307 23.979 25.7049C24.1544 25.8792 24.3624 26.017 24.5912 26.1106C24.82 26.2041 25.0651 26.2515 25.3123 26.25H32.8123C33.3076 26.247 33.7816 26.0481 34.1307 25.6968C34.4799 25.3455 34.6759 24.8703 34.6759 24.375C34.6759 23.8797 34.4799 23.4045 34.1307 23.0532C33.7816 22.7019 33.3076 22.5031 32.8123 22.5Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
      </g>
      <defs>
        <clipPath id="clip0_391_899">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GameInterface;
