// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topCards_topCards__nDWku {
  margin: 7.25rem auto;
  opacity: 1;
}

.topCards_description__ZRb0L {
  margin-top: 1rem;
  font-family: var(--font-nunito-sans);
  font-size: 1.125rem;
  opacity: 0.6;

  max-width: 60ch;
}

.topCards_GamesContainer__rTJxc {
  display: flex;
  justify-content: space-between;

  margin-top: 2.375rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/TopCards/topCards.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,mBAAmB;EACnB,YAAY;;EAEZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;;EAE9B,oBAAoB;AACtB","sourcesContent":[".topCards {\n  margin: 7.25rem auto;\n  opacity: 1;\n}\n\n.description {\n  margin-top: 1rem;\n  font-family: var(--font-nunito-sans);\n  font-size: 1.125rem;\n  opacity: 0.6;\n\n  max-width: 60ch;\n}\n\n.GamesContainer {\n  display: flex;\n  justify-content: space-between;\n\n  margin-top: 2.375rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topCards": `topCards_topCards__nDWku`,
	"description": `topCards_description__ZRb0L`,
	"GamesContainer": `topCards_GamesContainer__rTJxc`
};
export default ___CSS_LOADER_EXPORT___;
