import React, { useCallback } from 'react';
import styles from './navBar.module.css';
import Logo from '../Logo/Logo';
import NavButton from './GuestNavButtons/GuestNavButtons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import routes from '../../router/routes.json';
import classNames from 'classnames';
import globalStyle from '../../global.module.css';
import ArrowRight from '../Icons/ArrowRight/ArrowRight';
import { useAuth } from '../../context/AuthContextProvider';

const navLinks = ['home', 'cards', 'contactUs', 'helpCenter'];

const NavBar = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleAccountRedirect = useCallback(() => {
    navigate(routes.myAccount.url);
  }, [navigate]);

  return (
    <div className={styles.navBar}>
      <div className={styles.navBarContent}>
        <Logo />

        <ul className={styles.navbarTitle}>
          {navLinks.map((nav, index) => {
            const { label, url } = routes[nav as keyof typeof routes];
            const isHome =
              url === '/home' &&
              (location.pathname === '/' || location.pathname === '/home');
            // set Home active when the url is "./"
            const isActive = isHome || location.pathname === url;

            return (
              <li key={`${label}-${index}`}>
                <Link
                  to={url}
                  className={classNames({
                    [styles.activeLink]: isActive
                  })}>
                  {label}
                </Link>
              </li>
            );
          })}
        </ul>

        {!isAuthenticated ? (
          <NavButton />
        ) : (
          <button
            className={classNames(styles.accountBtn, globalStyle.btnIconReveal)}
            onClick={handleAccountRedirect}>
            <span>My Account</span>
            <ArrowRight className={globalStyle.icon} />
          </button>
        )}
      </div>
    </div>
  );
};

export default NavBar;
