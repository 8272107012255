import React from 'react';
import { IconState } from '../../../types/iconState';

type GCashProps = {
  state?: IconState;
};

const GCash = ({ state = IconState.Inactive }: GCashProps) => {
  const fillColor = state === IconState.Inactive ? '#544E65' : '';

  return (
    <>
      <svg
        width="24"
        height="21"
        viewBox="-1 -1 25 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.6785 10.363C21.6836 12.1796 21.2582 13.9715 20.4373 15.5921C20.3046 15.8505 20.2735 16.1492 20.3499 16.4294C20.4264 16.7096 20.6049 16.9511 20.8504 17.1064C21.1092 17.2704 21.4225 17.3252 21.7216 17.2588C22.0207 17.1923 22.2813 17.01 22.4463 16.7517C22.4652 16.7222 22.4823 16.692 22.4977 16.6613C23.4853 14.7131 24 12.5596 24 10.3754C24 8.19114 23.4853 6.03765 22.4977 4.08946C22.3566 3.81627 22.1127 3.61031 21.8198 3.51687C21.5268 3.42344 21.2088 3.45017 20.9355 3.5912L20.8469 3.64262C20.6018 3.79782 20.4236 4.03899 20.3471 4.31881C20.2707 4.59863 20.3016 4.8969 20.4337 5.15514C21.2525 6.76901 21.6789 8.55328 21.6785 10.363Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#51C1FF' : fillColor}`}
        />
        <path
          d="M17.9246 10.363C17.9273 11.489 17.6853 12.6022 17.2154 13.6256C17.0976 13.8816 17.0769 14.1717 17.1572 14.4418C17.2374 14.7119 17.4131 14.9436 17.6516 15.0938C17.7919 15.1827 17.9497 15.2406 18.1143 15.2634C18.2789 15.2862 18.4465 15.2735 18.6058 15.2261C18.7651 15.1788 18.9124 15.0978 19.0377 14.9887C19.1631 14.8796 19.2636 14.745 19.3325 14.5938C19.9384 13.2668 20.2519 11.8252 20.2519 10.3665C20.2519 8.90781 19.9384 7.46617 19.3325 6.13925C19.2635 5.98881 19.1632 5.85483 19.0382 5.74628C18.9133 5.63772 18.7666 5.55708 18.608 5.50975C18.4493 5.46242 18.2825 5.44949 18.1184 5.47181C17.9544 5.49414 17.7971 5.55121 17.6569 5.63921C17.4185 5.78937 17.2427 6.0211 17.1625 6.29121C17.0822 6.56133 17.1029 6.85141 17.2207 7.1074C17.6878 8.129 17.9279 9.23966 17.9246 10.363Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#51C1FF' : fillColor}`}
        />
        <path
          d="M10.1227 18.1632C8.88217 18.1629 7.65962 17.8669 6.55644 17.2996C5.45326 16.7323 4.50125 15.9101 3.77939 14.9013C3.05754 13.8924 2.58664 12.726 2.40578 11.4987C2.22492 10.2715 2.3393 9.01882 2.73943 7.84463C3.13956 6.67044 3.81392 5.60858 4.70655 4.74715C5.59917 3.88573 6.68434 3.24956 7.87202 2.89144C9.0597 2.53332 10.3157 2.46356 11.5357 2.68796C12.7557 2.91235 13.9047 3.42443 14.8872 4.18171C15.11 4.35494 15.3884 4.44097 15.6701 4.42367C15.9518 4.40637 16.2175 4.28693 16.4175 4.08773C16.5339 3.97134 16.6241 3.83147 16.6822 3.67744C16.7403 3.52341 16.7649 3.35876 16.7543 3.19448C16.7438 3.0302 16.6983 2.87006 16.6209 2.72475C16.5436 2.57943 16.4361 2.45229 16.3057 2.35179C14.8077 1.19545 13.0155 0.482269 11.1325 0.2932C9.24954 0.10413 7.3513 0.446749 5.65325 1.28216C3.95521 2.11757 2.52538 3.4123 1.5261 5.01939C0.526826 6.62647 -0.00188111 8.48152 5.02892e-06 10.3739C0.00189117 12.2664 0.534295 14.1204 1.53678 15.7254C2.53926 17.3305 3.97166 18.6224 5.67136 19.4544C7.37107 20.2865 9.27 20.6253 11.1526 20.4325C13.0351 20.2396 14.826 19.5229 16.3217 18.3636C16.4494 18.2643 16.5546 18.139 16.6303 17.996C16.7059 17.853 16.7503 17.6956 16.7606 17.5342C16.7708 17.3727 16.7466 17.2109 16.6896 17.0595C16.6326 16.9082 16.544 16.7706 16.4299 16.656L16.405 16.6312C16.2073 16.4366 15.9456 16.3205 15.6687 16.3045C15.3917 16.2885 15.1184 16.3737 14.8996 16.5443C13.5316 17.6001 11.8507 18.1698 10.1227 18.1632Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#007CFF' : fillColor}`}
        />
        <path
          d="M16.3448 9.51361C16.1215 9.28822 15.818 9.16066 15.5007 9.15898H9.94182C9.78041 9.15277 9.61941 9.1792 9.46846 9.23669C9.31751 9.29417 9.17971 9.38153 9.06332 9.49354C8.94693 9.60554 8.85434 9.73988 8.79109 9.88851C8.72785 10.0371 8.69525 10.197 8.69525 10.3585C8.69525 10.5201 8.72785 10.6799 8.79109 10.8286C8.85434 10.9772 8.94693 11.1115 9.06332 11.2235C9.17971 11.3355 9.31751 11.4229 9.46846 11.4804C9.61941 11.5379 9.78041 11.5643 9.94182 11.5581H14.1247C13.8715 12.3926 13.3629 13.1268 12.6706 13.6573C11.9784 14.1877 11.1372 14.4878 10.2655 14.5153C9.39379 14.5428 8.53539 14.2964 7.81106 13.8106C7.08672 13.3248 6.5329 12.6242 6.22751 11.8073C5.92211 10.9903 5.88051 10.0982 6.10856 9.25641C6.3366 8.41461 6.82281 7.66548 7.4988 7.11441C8.17478 6.56333 9.00652 6.23805 9.87701 6.18431C10.7475 6.13057 11.6129 6.35109 12.3516 6.81484C12.5832 6.95849 12.8567 7.01918 13.1273 6.98696C13.398 6.95474 13.6496 6.83153 13.841 6.63752C13.9673 6.5109 14.0635 6.35762 14.1227 6.18892C14.182 6.02023 14.2026 5.8404 14.1833 5.66267C14.1639 5.48494 14.1049 5.31381 14.0107 5.16185C13.9165 5.00989 13.7895 4.88096 13.6389 4.78455C12.1721 3.86264 10.4025 3.55201 8.70946 3.91923C7.47338 4.18122 6.34085 4.79883 5.45118 5.69605C4.56152 6.59328 3.95353 7.731 3.70201 8.96924C3.50927 9.87043 3.50814 10.8021 3.69868 11.7037C3.88921 12.6054 4.26717 13.4569 4.80802 14.2031C5.34887 14.9492 6.04055 15.5734 6.83817 16.035C7.63579 16.4966 8.52154 16.7854 9.43796 16.8826C10.3544 16.9798 11.281 16.8833 12.1577 16.5993C13.0344 16.3153 13.8417 15.8502 14.527 15.2341C15.2124 14.618 15.7606 13.8647 16.136 13.0231C16.5115 12.1815 16.7059 11.2703 16.7065 10.3488C16.7 10.0337 16.5701 9.73384 16.3448 9.51361Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#002CB8' : fillColor}`}
        />
      </svg>
    </>
  );
};

export default GCash;
