import React from 'react';

const TikTokActive = () => {
  return (
    <>
      <svg
        width="16"
        height="18"
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8404 6.49688C13.0023 7.32656 14.4262 7.81523 15.9643 7.81523V4.85859C15.6725 4.85859 15.3824 4.82871 15.0977 4.76719V7.09453C13.5596 7.09453 12.1357 6.60586 10.9738 5.77617V11.8125C10.9738 14.8324 8.5252 17.2793 5.50352 17.2793C4.37676 17.2793 3.3291 16.9383 2.45898 16.3547C3.45215 17.3689 4.8373 18 6.37012 18C9.3918 18 11.8404 15.5531 11.8404 12.5332V6.49688ZM12.9092 3.51387C12.315 2.86523 11.9248 2.02676 11.8404 1.10039V0.720703H11.0195C11.227 1.89844 11.9301 2.90391 12.9092 3.51387ZM4.36973 14.0379C4.0375 13.6037 3.8582 13.0711 3.85996 12.5244C3.85996 11.1428 4.97969 10.023 6.36133 10.023C6.61973 10.023 6.87461 10.0617 7.1207 10.1408V7.11738C6.83418 7.07871 6.54414 7.06113 6.25586 7.06816V9.42188C6.00977 9.34453 5.75488 9.3041 5.49648 9.3041C4.11484 9.3041 2.99512 10.4238 2.99512 11.8055C2.99336 12.7811 3.5541 13.6266 4.36973 14.0379Z"
          fill="#DE335B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9741 5.77793C12.136 6.60762 13.5599 7.09629 15.0979 7.09629V4.76895C14.2401 4.58613 13.479 4.13789 12.9077 3.51387C11.9304 2.90391 11.2272 1.89844 11.0198 0.720703H8.86475V12.5332C8.85947 13.9096 7.7415 15.0258 6.36338 15.0258C5.55127 15.0258 4.82881 14.6391 4.37178 14.0396C3.55615 13.6283 2.99541 12.7828 2.99541 11.8055C2.99541 10.4238 4.11514 9.3041 5.49678 9.3041C5.76221 9.3041 6.01709 9.34453 6.25615 9.42188V7.06816C3.28721 7.12969 0.901855 9.55195 0.901855 12.5332C0.901855 14.0203 1.496 15.3703 2.46104 16.3547C3.33115 16.94 4.37881 17.2793 5.50557 17.2793C8.52549 17.2793 10.9759 14.8324 10.9759 11.8125V5.77793H10.9741Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0979 4.76895V4.13965C14.3244 4.14141 13.565 3.92344 12.9076 3.51387C13.4895 4.1502 14.2559 4.58789 15.0979 4.76895ZM11.0197 0.720703C11.0004 0.608203 10.9846 0.495703 10.974 0.381445V0H7.99805V11.8125C7.99277 13.1889 6.8748 14.3051 5.49668 14.3051C5.09238 14.3051 4.70918 14.2084 4.37168 14.0379C4.82871 14.6373 5.55117 15.024 6.36328 15.024C7.74141 15.024 8.86113 13.9096 8.86465 12.5314V0.720703H11.0197ZM6.25605 7.06816V6.39844C6.00645 6.36504 5.75684 6.34746 5.50547 6.34746C2.48379 6.3457 0.0351562 8.79434 0.0351562 11.8125C0.0351562 13.7057 0.99668 15.3738 2.46094 16.3547C1.4959 15.3703 0.901758 14.0203 0.901758 12.5332C0.901758 9.55195 3.28711 7.12969 6.25605 7.06816Z"
          fill="#01A9BF"
        />
      </svg>
    </>
  );
};

export default TikTokActive;
