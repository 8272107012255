import React from 'react';
import styles from './legal.module.css';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes.json';

const Legal = () => {
  return (
    <div className={styles.legal}>
      <p>Copyright &copy; ScratchMania | All rights reserved</p>

      <ul>
        <li>
          <Link to={routes.privacyPolicy.url}>
            {routes.privacyPolicy.label}
          </Link>
        </li>
        <li>
          <Link to={routes.responsibleGaming.url}>
            {routes.responsibleGaming.label}
          </Link>
        </li>
        <li>
          <Link to={routes.termsAndCondition.url}>
            {routes.termsAndCondition.label}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Legal;
