// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.global_btnIconReveal__GqtAJ {
  display: inline-flex;
  align-items: center;
}

.global_btnIconReveal__GqtAJ .global_icon__kLbRC {
  display: none;
  opacity: 0;
  transition:
    opacity 0.2s linear,
    margin-left 0.2s linear;
}

.global_btnIconReveal__GqtAJ:hover .global_icon__kLbRC {
  opacity: 1;
  display: unset;
}

.global_btnIconReveal__GqtAJ:hover span {
  margin-right: 1rem;
  transition: margin-right 0.2s linear;
}

.global_btnIconReveal__GqtAJ span {
  transition: margin-right 0.2s linear;
}
`, "",{"version":3,"sources":["webpack://./src/global.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,UAAU;EACV;;2BAEyB;AAC3B;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".btnIconReveal {\n  display: inline-flex;\n  align-items: center;\n}\n\n.btnIconReveal .icon {\n  display: none;\n  opacity: 0;\n  transition:\n    opacity 0.2s linear,\n    margin-left 0.2s linear;\n}\n\n.btnIconReveal:hover .icon {\n  opacity: 1;\n  display: unset;\n}\n\n.btnIconReveal:hover span {\n  margin-right: 1rem;\n  transition: margin-right 0.2s linear;\n}\n\n.btnIconReveal span {\n  transition: margin-right 0.2s linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnIconReveal": `global_btnIconReveal__GqtAJ`,
	"icon": `global_icon__kLbRC`
};
export default ___CSS_LOADER_EXPORT___;
