// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gameCardByCategory_gameCard__Sgi\\+P {
  padding: 0.875rem;
  width: 15.833rem;
  height: 11.75;

  background-color: rgba(201, 223, 219, 0.3);
  border-radius: 0.295rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gameCardByCategory_gameCard__Sgi\\+P:hover,
.gameCardByCategory_gameCard__Sgi\\+P:active,
.gameCardByCategory_gameCard__Sgi\\+P.gameCardByCategory_active__\\+0lmB {
  background-color: var(--primary-orange-yellow);
  color: black;
}

.gameCardByCategory_category__GT1yO {
  font-family: var(--font-nunito-sans);
  font-weight: 600;
  color: inherit;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/components/BrowseByCategory/GameCardByCategory/gameCardByCategory.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;;EAEb,0CAA0C;EAC1C,uBAAuB;;EAEvB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;;;EAGE,8CAA8C;EAC9C,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,0BAA0B;AAC5B","sourcesContent":[".gameCard {\n  padding: 0.875rem;\n  width: 15.833rem;\n  height: 11.75;\n\n  background-color: rgba(201, 223, 219, 0.3);\n  border-radius: 0.295rem;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.gameCard:hover,\n.gameCard:active,\n.gameCard.active {\n  background-color: var(--primary-orange-yellow);\n  color: black;\n}\n\n.category {\n  font-family: var(--font-nunito-sans);\n  font-weight: 600;\n  color: inherit;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gameCard": `gameCardByCategory_gameCard__Sgi+P`,
	"active": `gameCardByCategory_active__+0lmB`,
	"category": `gameCardByCategory_category__GT1yO`
};
export default ___CSS_LOADER_EXPORT___;
