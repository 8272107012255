import React from 'react';
import CardsContent from './CardsContent';
import { CategoryProvider } from '../../context/CategoryContextProvider';
import ProtectedPage from '../../components/ProtectedRoute/ProtectedPage';

const CardsPage = () => {
  return (
    <>
      <ProtectedPage>
        <CategoryProvider>
          <CardsContent />
        </CategoryProvider>
      </ProtectedPage>
    </>
  );
};

export default CardsPage;
