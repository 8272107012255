import React, { useState } from 'react';
import styles from './searchBar.module.css';
import GalaSearch from '../Icons/GalaSearch/GalaSearch';
import { useFaqTopic } from '../../context/FaqContextProvider';

const SearchBar = () => {
  const { searchQuery, handleSearch } = useFaqTopic();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <input
          className={styles.searchBar}
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleInputChange}
          aria-label="Search"
        />
        <button className={styles.searchButton}>
          <GalaSearch />
        </button>
      </div>
    </>
  );
};

export default SearchBar;
