import React, { useCallback } from 'react';
import style from './modal.module.css';
import { useModal } from '../../context/ModalContextProvider';
import CloseModal from '../../assets/icons/CloseModal';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from '../../router/routes.json';
import { useAuth } from '../../context/AuthContextProvider';

const CloseBtn = () => {
  const { closeModal } = useModal();
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleCloseModal = useCallback(() => {
    const { pathname } = location;

    closeModal();

    if (pathname === routes.cards.url && !isAuthenticated) {
      navigate(-1);
    }
  }, [location.pathname]);

  return (
    <button onClick={handleCloseModal} className={style.closeBtn}>
      <CloseModal
        className={`${style.closeModalIcon} ${style.closeModalIconDark}`}
      />
    </button>
  );
};

export default CloseBtn;
