// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faqCardGrid_root__t2eBS {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 1.875rem;
  row-gap: 0.875rem;

  margin: 3.313rem 0;
}

.faqCardGrid_cardWrapper__xPIhv {
  max-width: 21.813rem;
}

.faqCardGrid_hidden__k-eEL {
  animation:
    faqCardGrid_fadeOut__tGTWM 0.5s ease-in forwards,
    faqCardGrid_shrink__CtMBj 0.8s ease-in forwards;
  overflow: hidden;
}

@keyframes faqCardGrid_fadeOut__tGTWM {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes faqCardGrid_shrink__CtMBj {
  100% {
    height: 0;
    margin: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/FaqCardGrid/faqCardGrid.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,kCAAkC;EAClC,oBAAoB;EACpB,iBAAiB;;EAEjB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE;;mDAE8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,SAAS;IACT,SAAS;EACX;AACF","sourcesContent":[".root {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-template-rows: repeat(2, 1fr);\n  column-gap: 1.875rem;\n  row-gap: 0.875rem;\n\n  margin: 3.313rem 0;\n}\n\n.cardWrapper {\n  max-width: 21.813rem;\n}\n\n.hidden {\n  animation:\n    fadeOut 0.5s ease-in forwards,\n    shrink 0.8s ease-in forwards;\n  overflow: hidden;\n}\n\n@keyframes fadeOut {\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n@keyframes shrink {\n  100% {\n    height: 0;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `faqCardGrid_root__t2eBS`,
	"cardWrapper": `faqCardGrid_cardWrapper__xPIhv`,
	"hidden": `faqCardGrid_hidden__k-eEL`,
	"fadeOut": `faqCardGrid_fadeOut__tGTWM`,
	"shrink": `faqCardGrid_shrink__CtMBj`
};
export default ___CSS_LOADER_EXPORT___;
