import React from 'react';
import PurposePolicy from './PurposePolicy/PurposePolicy';
import styles from './privacyPolicy.module.css';

const PrivacyPolicy = () => {
  return (
    <>
      <div className={`${styles.privacyBanner} container`}>
        <div className={styles.privacyCopy}>
          <h1 className={styles.privacyTitle}>Privacy Policy</h1>
          <p>
            Your privacy is important to us. Read our Privacy Policy to
            understand how we collect, use, and protect your personal
            information while using ScratchMania. By continuing to use our
            services, you agree to the terms outlined here.
          </p>
        </div>
        <div className={styles.heroSvg}>
          <img src="./privacy.png" alt="" />
        </div>
      </div>
      <PurposePolicy />
    </>
  );
};

export default PrivacyPolicy;
