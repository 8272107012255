// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards_hidden__C0BIH {
  animation:
    cards_fadeOut__x7o1P 0.5s ease-in forwards,
    cards_shrink__js2sr 1s ease-in forwards;
  overflow: hidden;
}

@keyframes cards_fadeOut__x7o1P {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes cards_shrink__js2sr {
  100% {
    height: 0;
    margin: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/CardsPage/cards.module.css"],"names":[],"mappings":"AAAA;EACE;;2CAE4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,SAAS;IACT,SAAS;EACX;AACF","sourcesContent":[".hidden {\n  animation:\n    fadeOut 0.5s ease-in forwards,\n    shrink 1s ease-in forwards;\n  overflow: hidden;\n}\n\n@keyframes fadeOut {\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n@keyframes shrink {\n  100% {\n    height: 0;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `cards_hidden__C0BIH`,
	"fadeOut": `cards_fadeOut__x7o1P`,
	"shrink": `cards_shrink__js2sr`
};
export default ___CSS_LOADER_EXPORT___;
