import React from 'react';

type ArrowRightProps = {
  className?: string;
};

const ArrowRight = ({ className }: ArrowRightProps) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M5.19531 1.08203L5.79688 0.480469C6.07031 0.234375 6.48047 0.234375 6.72656 0.480469L12.0586 5.78516C12.3047 6.05859 12.3047 6.46875 12.0586 6.71484L6.72656 12.0469C6.48047 12.293 6.07031 12.293 5.79688 12.0469L5.19531 11.4453C4.94922 11.1719 4.94922 10.7617 5.19531 10.4883L8.50391 7.34375H0.65625C0.273438 7.34375 0 7.07031 0 6.6875V5.8125C0 5.45703 0.273438 5.15625 0.65625 5.15625H8.50391L5.19531 2.03906C4.94922 1.76562 4.92188 1.35547 5.19531 1.08203Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowRight;
