import React from 'react';

const PhoneIconYellow = () => {
  return (
    <div>
      <svg
        width="39"
        height="39"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_902_1440)">
          <path
            d="M16.9232 22.6393C20.6789 26.4044 25.0299 29.9986 26.7541 28.2759C29.2194 25.8059 30.7394 23.6637 36.1765 28.0305C41.6137 32.3974 37.4369 35.3107 35.0492 37.6984C32.2942 40.4534 22.0247 37.8424 11.8692 27.6917C1.71369 17.5409 -0.892479 7.26986 1.86252 4.51169C4.25652 2.12878 7.16194 -2.04172 11.5288 3.38594C15.8956 8.81361 13.7502 10.3368 11.2865 12.8004C9.56386 14.5326 13.158 18.882 16.9232 22.6393Z"
            fill="#F9CF31"
          />
        </g>
        <defs>
          <clipPath id="clip0_902_1440">
            <rect
              width="38"
              height="38"
              fill="white"
              transform="matrix(-1 0 0 1 38.835 0.72583)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default PhoneIconYellow;
