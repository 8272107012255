// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards_cards__OIACK {
  margin: 7.25rem auto;
}

.cards_gamesContainer__x4UzM {
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;
  margin-top: 2.9375rem;
}

.cards_headerWrapper__a9fx9 {
  display: flex;

  justify-content: space-between;
}

.cards_pageNavigation__0eFzz {
  display: flex;
  column-gap: 1rem;
}

.cards_pageNavigation__0eFzz li {
  font-family: var(--font-nunito-sans);
  font-weight: 400;
  width: 3.063rem;

  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cards_pageNavigation__0eFzz li:hover {
  background-color: var(--secondary-persian-rose);
}

.cards_pageNavigation__0eFzz li.cards_active__sw8ia {
  background-color: var(--secondary-persian-rose);
}

.cards_exploreBtn__lyHnx {
  background-color: var(--secondary-cyan);
  padding: 0.625rem 1.625rem;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Cards/cards.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;;EAEb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,eAAe;;EAEf,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,uCAAuC;EACvC,0BAA0B;EAC1B,kBAAkB;AACpB","sourcesContent":[".cards {\n  margin: 7.25rem auto;\n}\n\n.gamesContainer {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 1.875rem;\n  margin-top: 2.9375rem;\n}\n\n.headerWrapper {\n  display: flex;\n\n  justify-content: space-between;\n}\n\n.pageNavigation {\n  display: flex;\n  column-gap: 1rem;\n}\n\n.pageNavigation li {\n  font-family: var(--font-nunito-sans);\n  font-weight: 400;\n  width: 3.063rem;\n\n  border-radius: 3px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.pageNavigation li:hover {\n  background-color: var(--secondary-persian-rose);\n}\n\n.pageNavigation li.active {\n  background-color: var(--secondary-persian-rose);\n}\n\n.exploreBtn {\n  background-color: var(--secondary-cyan);\n  padding: 0.625rem 1.625rem;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cards": `cards_cards__OIACK`,
	"gamesContainer": `cards_gamesContainer__x4UzM`,
	"headerWrapper": `cards_headerWrapper__a9fx9`,
	"pageNavigation": `cards_pageNavigation__0eFzz`,
	"active": `cards_active__sw8ia`,
	"exploreBtn": `cards_exploreBtn__lyHnx`
};
export default ___CSS_LOADER_EXPORT___;
