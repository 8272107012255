// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helpCenter_header__Ny3Pq {
  padding: 2em 0 2.25em 0;
  max-width: 69.4375rem;
  margin: 0 auto;
}

.helpCenter_sectionWrapper__5Iey2 {
  width: 100%;
}

.helpCenter_headerWrapper__WkgWA {
  width: 100%;
  position: relative;
}

.helpCenter_faqContentContainer__nzcP0 {
  left: 0;
  right: 0;
  width: 100%;

  max-width: 69.4375rem;
  margin: 0 auto;
}

.helpCenter_faqContentTitle__DapV9 {
  font-family: var(--font-rajdhani);
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.276;
}

.helpCenter_faqContentTitle__DapV9::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}

.helpCenter_faqContentCopy__KU7kK {
  font-family: var(--font-nunito-sans);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.364;
}

.helpCenter_accordionItems__yncXC {
  margin: auto;
}

.helpCenter_accordionWrapper__hGA3z {
  margin-top: 2.25em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/HelpCenter/helpCenter.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,OAAO;EACP,QAAQ;EACR,WAAW;;EAEX,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,WAAW;EACX,0CAA0C;AAC5C;;AAEA;EACE,oCAAoC;EACpC,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".header {\n  padding: 2em 0 2.25em 0;\n  max-width: 69.4375rem;\n  margin: 0 auto;\n}\n\n.sectionWrapper {\n  width: 100%;\n}\n\n.headerWrapper {\n  width: 100%;\n  position: relative;\n}\n\n.faqContentContainer {\n  left: 0;\n  right: 0;\n  width: 100%;\n\n  max-width: 69.4375rem;\n  margin: 0 auto;\n}\n\n.faqContentTitle {\n  font-family: var(--font-rajdhani);\n  font-weight: 700;\n  font-size: 3rem;\n  line-height: 1.276;\n}\n\n.faqContentTitle::before {\n  content: '';\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  width: 100vw;\n  height: 1px;\n  background-color: rgba(255, 255, 255, 0.2);\n}\n\n.faqContentCopy {\n  font-family: var(--font-nunito-sans);\n  font-weight: 400;\n  font-size: 1rem;\n  line-height: 1.364;\n}\n\n.accordionItems {\n  margin: auto;\n}\n\n.accordionWrapper {\n  margin-top: 2.25em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `helpCenter_header__Ny3Pq`,
	"sectionWrapper": `helpCenter_sectionWrapper__5Iey2`,
	"headerWrapper": `helpCenter_headerWrapper__WkgWA`,
	"faqContentContainer": `helpCenter_faqContentContainer__nzcP0`,
	"faqContentTitle": `helpCenter_faqContentTitle__DapV9`,
	"faqContentCopy": `helpCenter_faqContentCopy__KU7kK`,
	"accordionItems": `helpCenter_accordionItems__yncXC`,
	"accordionWrapper": `helpCenter_accordionWrapper__hGA3z`
};
export default ___CSS_LOADER_EXPORT___;
