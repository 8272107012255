import React from 'react';
import CardIcon from '../Icons/CardIcon/CardIcon';
import PhoneBook from '../Icons/PhoneBook/PhoneBook';
import GameMechanics from '../Icons/GameMechanics/GameMechanics';
import GameInterface from '../Icons/GameInterface/GameInterface';
import Results from '../Icons/Results/Results';

export const topicCardsData = [
  {
    title: 'Popular Questions',
    content:
      'Find answers to some of the most common questions asked by our players. Whether you’re curious about account settings, game mechanics, or just looking for tips, our FAQs cover it all.'
  },
  {
    icon: <CardIcon iconType={'light'} />,
    title: 'Registration Access',
    content:
      'Find out how to easily create and access your ScratchMania account, and troubleshoot any issues that may arise.'
  },
  {
    icon: <PhoneBook iconType={'light'} />,
    title: 'Account Management',
    content:
      'Find out how to easily create and access your ScratchMania account, and troubleshoot any issues that may arise.'
  },
  {
    icon: <GameMechanics iconType={'light'} />,
    title: 'Game Mechanics',
    content:
      'Understand the rules and gameplay mechanics of our scratch card games to maximize your chances of winning.'
  },
  {
    icon: <GameInterface iconType={'light'} />,
    title: 'Game Interface',
    content:
      'Navigate through the ScratchMania platform with ease by learning about our user-friendly game interface.'
  },
  {
    icon: <Results iconType={'light'} />,
    title: 'Results',
    content:
      'Discover how game results are determined, and where to find your game history and winnings.'
  },
  {
    icon: <Results iconType={'light'} />,
    title: 'Prices',
    content:
      'Get information on game pricing, in-game purchases, and how to manage your spending within the app.'
  }
];
