import React, { useEffect } from 'react';
import ModalWrapper from './ModalWrapper';
import { ModalType, useModal } from '../../context/ModalContextProvider';
import styles from './modal.module.css';

import CloseBtn from './CloseBtn';

import Auth from './Auth/Auth';

type ModalProps = {
  modalType: ModalType | null;
};

const Modal = ({ modalType }: ModalProps) => {
  const { isModalOpen } = useModal();

  if (modalType === null || !isModalOpen) return;

  return (
    <div className={styles.root}>
      <ModalWrapper>
        {/* content */}
        <>
          <CloseBtn />
          <Auth />
        </>
      </ModalWrapper>
    </div>
  );
};

export default Modal;
