import React, { cloneElement, useState, type ReactElement } from 'react';
import classNames from 'classnames';
import style from './FaqCard.module.css';

type FaqCardProps = {
  icon?: ReactElement;
  title: string;
  content: string;
  isActive?: boolean;
};

const FaqCard = ({ icon, title, content, isActive }: FaqCardProps) => {
  const [hovered, setHovered] = useState(false);

  // Determine the iconType based on isActive and hovered state
  const iconType = isActive || hovered ? 'dark' : 'light';

  // Clone the icon with the determined iconType
  const clonedElement = icon ? cloneElement(icon, { iconType }) : null;

  return (
    <div
      className={classNames([style.card], { [style.isActive]: isActive })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {clonedElement}
      <h3 className={style.cardTitle}>{title}</h3>
      <p className={style.cardContent}>{content}</p>
    </div>
  );
};

export default FaqCard;
