import React from 'react';
import styles from './banner.module.css';

const Banner = () => {
  return (
    <div className={`${styles.contactUsBanner} container`}>
      <div className={styles.contactUsCopy}>
        <h1 className={styles.contactUsTitle}>Contact Us</h1>
        <p className={styles.description}>
          We’re here to help! Whether you have questions, feedback, or need
          support, feel free to reach out to our team. Your satisfaction is our
          priority.
        </p>
      </div>
      <div className={styles.heroSvg}>
        <img src="./contact.svg" alt="" />
      </div>
    </div>
  );
};

export default Banner;
