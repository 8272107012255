import React from 'react';
import style from './gameCardByCategory.module.css';
import classNames from 'classnames';

type GameCardByCategoryProps = {
  category: string;
  thumbnail: string;
  isActive?: boolean;
};

const GameCardByCategory = ({
  isActive,
  category,
  thumbnail
}: GameCardByCategoryProps) => {
  return (
    <div
      className={classNames({
        [style.gameCard]: true,
        [style.active]: isActive
      })}>
      <div className={style.cardImage}>
        <img src={thumbnail} alt="" />
      </div>
      <p className={style.category}>{category}</p>
    </div>
  );
};

export default GameCardByCategory;
