// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loggedInHeroBanner_heroBanner__INPLM {
  max-width: 69.4375rem;
  margin: 4.625rem auto;

  display: flex;
}

.loggedInHeroBanner_heroTitle__lgfIB {
  font-family: var(--font-rajdhani);
  font-size: 5.625rem;
  line-height: 5.625rem;

  font-weight: 600;
}

.loggedInHeroBanner_heroCopy__Bfuwe {
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.loggedInHeroBanner_heroSvg__rp8Do {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loggedInHeroBanner_heroCopy__Bfuwe p {
  font-family: var(--font-nunito-sans);
  font-size: 1.125rem;
  max-width: 43ch;
  color: #d4d4d4;
  font-weight: 500;
  line-height: 1.688rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoggedInHeroBanner/loggedInHeroBanner.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,qBAAqB;;EAErB,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;EACnB,qBAAqB;;EAErB,gBAAgB;AAClB;;AAEA;EACE,UAAU;;EAEV,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".heroBanner {\n  max-width: 69.4375rem;\n  margin: 4.625rem auto;\n\n  display: flex;\n}\n\n.heroTitle {\n  font-family: var(--font-rajdhani);\n  font-size: 5.625rem;\n  line-height: 5.625rem;\n\n  font-weight: 600;\n}\n\n.heroCopy {\n  width: 50%;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: start;\n}\n\n.heroSvg {\n  width: 50%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.heroCopy p {\n  font-family: var(--font-nunito-sans);\n  font-size: 1.125rem;\n  max-width: 43ch;\n  color: #d4d4d4;\n  font-weight: 500;\n  line-height: 1.688rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroBanner": `loggedInHeroBanner_heroBanner__INPLM`,
	"heroTitle": `loggedInHeroBanner_heroTitle__lgfIB`,
	"heroCopy": `loggedInHeroBanner_heroCopy__Bfuwe`,
	"heroSvg": `loggedInHeroBanner_heroSvg__rp8Do`
};
export default ___CSS_LOADER_EXPORT___;
