import React from 'react';
import styles from './data.module.css';

export type Question = {
  title: string;
  content: string | React.ReactElement;
};

type HelpCenterData = {
  [topic: string]: {
    title: string;
    content: string | React.ReactElement;
  }[];
};

export const helpCenterData: HelpCenterData = {
  'Popular Questions': [
    {
      title: 'What should I do if I forgot my password?',
      content: (
        <div>
          <p className={styles['mb-1']}>
            If you forgot your password follow these simple steps:
          </p>

          <ol>
            <li className={styles['mtb-1']}>
              <strong>Step 1:</strong> Click on the &quot;Forgot Password&quot;
              link on the login page, and it will send a 6 digit OTP to your
              registered email address.
            </li>
            <li className={styles['mtb-1']}>
              <strong>Step 2:</strong> Enter the 6 digit OTP on your app.
            </li>
            <li className={styles['mtb-1']}>
              <strong>Step 3:</strong> Create a new password and re-enter it to
              confirm.
            </li>
          </ol>

          <p className={styles['mtb-1']}>
            Remember the new password you just created.
          </p>

          <p className={styles['mtb-1']}>
            Once done, you will be redirected back to the login page. Try
            logging in again and you should be able to access the game and start
            playing.
          </p>

          <p className={styles['mtb-1']}>
            If you continue to experience difficulties, please don&apos;t
            hesitate to reach out to our dedicated player support team via the
            Help Center feature within the app.
          </p>
        </div>
      )
    },
    {
      title: 'How to Deactivate and Reactivate My Account?',
      content: (
        <div>
          <p className={styles['mb-1']}>
            Deactivation places your account in a &quot;Paused&quot; state.
            During this period, no activities are recorded, and you are
            effectively offline. You can choose to reactivate your account at
            any time to resume activities.
          </p>

          <h3>Steps to Deactivate Your Account:</h3>
          <ul className={styles['ml-1']}>
            <li className={styles['list-disc']}>
              Navigate to the <strong>Profile</strong> tab.
            </li>
            <li className={styles['list-disc']}>
              Tap on <strong>Security</strong>.
            </li>
            <li className={styles['list-disc']}>
              Choose <strong>Deactivate Account</strong>.
            </li>
            <li className={styles['list-disc']}>
              Specify your <strong>Reason For Deactivation</strong>.
            </li>
            <li className={styles['list-disc']}>Read the Disclaimer</li>
            <li className={styles['list-disc']}>
              Click Deactivate My Account. Confirm deactivation by selecting{' '}
              <strong>Yes</strong> in the confirmation pop-up.
            </li>
          </ul>

          <p className={styles['mtb-1']}>
            Upon confirming deactivation by clicking on “Yes,” you will be
            automatically logged out of the app.
          </p>

          <div className={styles['mtb-1']}>
            <h3>Reactivating Your Account:</h3>
            <p>
              To reactivate your account, simply log in with your existing
              credentials. Important Note: If your account remains deactivated,
              it will be automatically archived after 6 months.
            </p>
          </div>

          <div className={styles['mtb-1']}>
            <h3>Important Note:</h3> If your account remains deactivated, it
            will be automatically archived after 6 months.
          </div>
        </div>
      )
    },
    {
      title: 'How to report unauthorized use of my account?',
      content: (
        <div>
          <p>
            If you suspect that your account has unauthorized activities on your
            account, you may do the following activities.
          </p>

          <ul className={`${styles['mtb-1']} ${styles['ml-1']}`}>
            <li className={styles['list-disc']}>Change your password</li>
            <li className={styles['list-disc']}>Change your MPIN</li>
            <li className={styles['list-disc']}>Deactivate your account</li>
            <li className={styles['list-disc']}>
              Fill out the Request to Suspend account form and we will take
              action on your request.
            </li>
            <li className={styles['list-disc']}>
              Contact support support for us to suspend and investigate.
            </li>
          </ul>

          <p className={styles['mtb-1']}>
            We take your account security very seriously and rest assured we
            take the necessary steps to keep your account safe.
          </p>
        </div>
      )
    },
    {
      title: 'Guide to Playing ScratchMania Cards.',
      content: (
        <div className={styles['indent-4']}>
          <p className={styles['mb-1']}>
            ScratchMania8 card games offer a digital twist on the classic
            scratch cards we all enjoy.
          </p>
          <h3>Here&apos;s how to immerse yourself in the excitement:</h3>

          <ul
            className={`${styles['mtb-1']} ${styles['ml-1']} styles['list-disc']}`}>
            <li>
              <strong>Ensure a Balance</strong> : Make sure you have funds in
              your wallet to buy a Scratch card.
            </li>

            <li>
              <strong>Select Your Card</strong> : Pick the tier and type of
              ScratchMania8 card you wish to play. Tiers indicate the cost of
              playing, and each tier offers a collection of unique ScratchMania8
              card games.
            </li>

            <li>
              <strong>Scratch to Play</strong> : Begin the game by scratching
              the card just like you do physical scratch cards.
            </li>

            <li>
              <strong>Winning and Losing</strong> : If your scratching meets the
              criteria outlined in the game&apos;s mechanics, you&apos;ll win
              the prize specified. If not, the game is lost.
            </li>
          </ul>
        </div>
      )
    },
    {
      title: 'How to win?',
      content: (
        <div>
          Achieve a win by matching three identical amounts on your card. The
          prize mirrors the matched amounts. For instance, matching three
          instances of ₱50.00 awards you ₱50.00, credited to your balance.
        </div>
      )
    },
    {
      title: 'How do I get my Cash Prize?',
      content: (
        <div>
          Congratulations! Your winnings will directly be credited to your
          balance. You are free to cash out these winnings at any time,
          providing immediate access to your prize.
        </div>
      )
    }
  ],
  'Registration Access': [
    {
      title: 'How do I create an account?',
      content: (
        <div className={styles['max-w-8']}>
          <p className={styles['mb-1']}>
            Getting started with ScratchMania8 is easy, just follow these simple
            steps:
          </p>
          <ol className={styles['ml-1']}>
            <li>
              <strong>Step 1:</strong> Go to www.ScratchMania8.com to download
              the apk file.
            </li>
            <li>
              <strong>Step 2:</strong> During installation, give the app
              permission to your Location, Data, Storage, Camera and
              Notification for the app to run smoothly.
            </li>
            <li>
              <strong> Step 3:</strong>
              Click on the “Register Now” link and fill in your First Name, Last
              Name, Active Mobile Number, valid Email Address.
            </li>
            <li>
              <strong>Step 4:</strong> Accept the Terms of Use and hit submit.
            </li>
            <li>
              <strong>Step 5: </strong>Validate your account by entering the
              6-digit OTP sent to your mobile number.
            </li>
            <li>
              <strong>Step 6:</strong> Create your MPIN.
            </li>
          </ol>

          <p className={styles['mtb-1']}>
            Now you are in, you have access to the exciting games we have in
            store for you.
          </p>
        </div>
      )
    },
    {
      title: 'Can I have multiple accounts on the platform?',
      content: (
        <div className={styles['max-w-8']}>
          <p>
            Yes. You may have multiple accounts, as long as each account has its
            own number and email address. No more than one account can have the
            same email address and mobile number linked to it.
          </p>
        </div>
      )
    },
    {
      title: 'How do I set up my login?',
      content: (
        <div className={styles['max-w-8']}>
          <p>
            After creating an account, the app will prompt you to create your
            own Password and 4 digit Mobile PIN (MPIN).
          </p>
          <ol className={styles['ml-1']}>
            <li>
              I. Username: By default your username is your registered email
              address.
            </li>
            <li>
              II. Password: Players are asked to create a strong password. This
              should met the following conditions:{' '}
              <ul
                className={`${styles['ml-2']} ${styles['max-w-8']} ${styles['mtb-1']}`}>
                <li>
                  <strong>Length: </strong>Ensure your password is at least 8
                  characters long. While longer passwords are generally more
                  secure, a minimum of 8 characters is essential for basic
                  security.
                </li>
                <li>
                  <strong>Complexity: </strong>Use a combination of upper and
                  lower case letters, numbers, and special characters (such as
                  @, #, $, %, etc.) in your password. This mix of different
                  types of characters significantly enhances the security of the
                  password.
                </li>
              </ul>
            </li>
            <li>
              III. MPIN: For added security, players must elect a 4-digit mobile
              pin. We recommend that the number combination be NOT in sequential
              order such as 1234, or all 4 digits are just a singular number
              like 1111.
            </li>
          </ol>
        </div>
      )
    },
    {
      title: 'What should I do if I forgot my password?',
      content: (
        <div className={styles['max-w-8']}>
          <p className={styles['mb-1']}>
            If you forgot your password follow these simple steps:
          </p>

          <ol className={`${styles['ml-1']} ${styles['ml-1']}`}>
            <li className={styles['mtb-1']}>
              <strong>Step 1:</strong> Click on the &quot;Forgot Password&quot;
              link on the login page, and it will send a 6 digit OTP to your
              registered email address.
            </li>
            <li className={styles['mtb-1']}>
              <strong>Step 2:</strong> Enter the 6 digit OTP on your app.
            </li>
            <li className={styles['mtb-1']}>
              <strong>Step 3:</strong> Create a new password and re-enter it to
              confirm.
            </li>
          </ol>

          <p className={styles['mtb-1']}>
            Remember the new password you just created.
          </p>

          <p className={styles['mtb-1']}>
            Once done, you will be redirected back to the login page. Try
            logging in again and you should be able to access the game and start
            playing.
          </p>

          <p className={styles['mtb-1']}>
            If you continue to experience difficulties, please don&apos;t
            hesitate to reach out to our dedicated player support team via the
            Help Center feature within the app.
          </p>
        </div>
      )
    },
    {
      title: 'What should I do if I forgot my MPIN?',
      content: (
        <div className={styles['max-w-8']}>
          <p>If you forgot your password follow these simple steps:</p>
          <ol>
            <li>
              <strong>Step 1: </strong>
              Click on the &quot;Forgot MPIN&quot; link on the login page, and
              it will send a 6 digit OTP to your registered mobile number.
            </li>
            <li>
              <strong>Step 2: </strong>
              Enter the 6 digit OTP on your app.
            </li>
            <li>
              <strong>Step 3: </strong>
              Create a new MPIN.
            </li>
          </ol>

          <p className={styles['mt-1']}>
            Remember the new MPIN you just created.
          </p>

          <p>
            Once done, you will be redirected back to the login page. Try
            logging in again and you should be able to access your App and start
            playing.
          </p>

          <p>
            If you continue to experience difficulties, please don&apos;t
            hesitate to reach out to our dedicated player support team via the
            Help Center feature within the app.
          </p>
        </div>
      )
    },
    {
      title: 'Why do I have to accept the Terms of Use?',
      content: (
        <div className={styles['max-w-8']}>
          The &quot;Terms of Use&quot; is a legal agreement between
          ScratchMania8 and you, our player, which outlines the rules,
          conditions, and guidelines for using our ScratchMania8 platform. It is
          a binding contract that sets forth both the rights and
          responsibilities of Elevate Games and players. For more information,
          you check our Terms of Use.
        </div>
      )
    }
  ],
  'Account Management': [
    {
      title: 'How can I change my password?',
      content: (
        <div className={styles['max-w-8']}>
          <ol>
            <li>1. Go to the Profile tab.</li>
            <li>2. Click on Security.</li>
            <li>
              3. Select Change password option. This will send an OTP to your
              registered email address.
            </li>
            <li>4. Enter OTP</li>
            <li>5. Create the new password.</li>
          </ol>
        </div>
      )
    },
    {
      title: 'How can I change my MPIN?',
      content: (
        <div className={styles['max-w-8']}>
          <ol>
            <li>1. Go to the Profile tab.</li>
            <li>2. Click on Security.</li>
            <li>
              3. Select Change MPIN option. This will send an OTP to your
              registered mobile number.
            </li>
            <li>4. Enter the OTP. </li>
            <li>5. Create the new MPIN.</li>
          </ol>
        </div>
      )
    },
    {
      title: 'How can I change my information?',
      content: (
        <div className={styles['max-w-8']}>
          <div className={styles['mtb-1']}>
            <h3>Mobile Number</h3>
            <ul className={styles['list-disc']}>
              <li>Go to the Profile tab.</li>
              <li>Click on My Account.</li>
              <li>Edit the information.</li>
              <li>Click on Save.</li>
              <li>This will send an OTP to your new Mobile number.</li>
              <li>Enter the 6 Digit OTP.</li>
            </ul>
          </div>

          <div className={styles['mtb-1']}>
            <h3>Email Address</h3>
            <ul className={`${styles['list-disc']}`}>
              <li>Go to the Profile tab.</li>
              <li>Click on My Account.</li>
              <li>Edit the information.</li>
              <li>Click on Save.</li>
              <li>This will send an OTP to your new Email Address.</li>
              <li>Enter the 6 Digit OTP.</li>
            </ul>
          </div>

          <div className={styles['mtb-1']}>
            <h3>Other Information</h3>
            <ul className={styles['list-disc']}>
              <li>Go to the Profile tab.</li>
              <li>Click on My Account.</li>
              <li>Select the information you want to change.</li>
              <li>Edit the information.</li>
              <li>Click on Save. </li>
            </ul>
          </div>

          <p>
            If you experience difficulties, please don&apos;t hesitate to reach
            out to our dedicated player support team via the Help Center.
          </p>
        </div>
      )
    },
    {
      title: 'How to Deactivate and Reactivate My Account?',
      content: (
        <div className={styles['max-w-8']}>
          <p>
            Deactivation places your account in a &quot;Paused&quot; state.
            During this period, no activities are recorded, and you are
            effectively offline. You can choose to reactivate your account at
            any time to resume activities.
          </p>

          <div>
            <h3>Steps to Deactivate Your Account:</h3>
            <ul
              className={`${styles['ml-2']}  ${styles['mtb-1']} ${styles['list-disc']}`}>
              <li>Navigate to the Profile tab.</li>
              <li>Tap on Security.</li>
              <li>Choose Deactivate Account.</li>
              <li>Specify your Reason For Deactivation.</li>
              <li>Read the Disclaimer.</li>
              <li>Click Deactivate My Account.</li>
              <li>
                Confirm deactivation by selecting Yes in the confirmation
                pop-up.
              </li>
            </ul>
          </div>
        </div>
      )
    },
    {
      title: 'How to Delete My Account?',
      content: (
        <div className={styles['max-w-8']}>
          <p>
            Deleting your account initiates a 30-day period where you can
            reverse the decision. After 30 days, the deletion becomes permanent,
            requiring you to create a new account if you wish to return.
          </p>

          <div>
            <h3>Steps to Delete Your Account:</h3>
            <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
              <li>
                Navigate to the <strong>Profile</strong> tab.
              </li>
              <li>
                Tap on <strong>Security</strong>.
              </li>
              <li>
                Choose <strong>Delete Account.</strong>
              </li>
              <li>
                Specify your <strong>Reason For Deletion.</strong>
              </li>
              <li>Read the Disclaimer</li>
              <li>
                Click <strong>Delete My Account.</strong>
              </li>
              <li>
                Confirm deletion by selecting <strong>Yes</strong> in the
                confirmation pop-up.
              </li>
            </ul>
          </div>

          <p className={styles['mtb-1']}>
            Upon confirming deletion by clicking on “Yes,” you will be
            automatically logged out of the app.
          </p>

          <p className={styles['mtb-1']}>
            <h3>Reversing Account Deletion</h3>
            To reverse account deletion, simply log in with your existing
            credentials within 30 days.
          </p>

          <p className={styles['mtb-1']}>
            <h3>Important Note</h3>
            If you reverse the deletion within 30 days, your account is
            recoverable. After the 30-day grace period, a permanent deletion
            means if you wish to return, a new account creation and validation
            process is necessary.
          </p>
        </div>
      )
    },
    {
      title: 'How to report unauthorized use of my account?',
      content: (
        <div className={styles['max-w-8']}>
          <p>
            If you suspect that your account has unauthorized activities on your
            account, you may do the following activities.
          </p>

          <ul className={`${styles['ml-2']} ${styles['list-disc']}`}>
            <li>Change your password</li>
            <li>Change your MPIN</li>
            <li>Deactivate your account</li>
            <li>
              Fill out the Request to Suspend account form and we will take
              action on your request.
            </li>
            <li>Contact support support for us to suspend and investigate.</li>
          </ul>

          <p className={styles['mtb-1']}>
            We take your account security very seriously and rest assured we
            take the necessary steps to keep your account safe.
          </p>
        </div>
      )
    },
    {
      title: 'How can I appeal a suspended account?',
      content: (
        <div className={styles['max-w-8']}>
          If you received a notice that your account was suspended, go to
          www.ScratchMania8.com and fill out the appeal form under the Help
          Center.
        </div>
      )
    }
  ],
  'Game Mechanics': [
    {
      title:
        'ScratchMania8 card games offer a digital twist on the classic scratch cards we all enjoy.',
      content: (
        <div>
          <div className={styles['max-w-8']}>
            <h3>Here&apos;s how to immerse yourself in the excitement:</h3>
            <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
              <li className={`${styles['mtb-2']}`}>
                <strong>Ensure a Balance:</strong> Make sure you have funds in
                your wallet to buy a Scratch card.
              </li>
              <li className={`${styles['mtb-2']}`}>
                <strong>Select Your Card:</strong> Pick the tier and type of
                ScratchMania8 card you wish to play. Tiers indicate the cost of
                playing, and each tier offers a collection of unique
                ScratchMania8 card games.
              </li>
              <li className={`${styles['mtb-2']}`}>
                <strong>Scratch to Play:</strong> Begin the game by scratching
                the card just like you do physical scratch cards.
              </li>
              <li className={`${styles['mtb-2']}`}>
                <strong>Winning and Losing:</strong> If your scratching meets
                the criteria outlined in the game&apos;s mechanics, you&apos;ll
                win the prize specified. If not, the game is lost.
              </li>
            </ul>
          </div>
        </div>
      )
    },
    {
      title: 'What are the Card Tiers?',
      content: (
        <div className={styles['max-w-8']}>
          <p className={`${styles['mtb-2']}`}>
            Card tiers in ScratchMania8 games indicate both the cost of playing
            a single card and the range of prizes you can win.
          </p>

          <p className={`${styles['mtb-2']}`}>
            Selecting a tier sets the price of each card in that category and
            outlines the potential winnings available.
          </p>

          <p className={`${styles['mtb-2']}`}>
            For instance, choosing the ₱5.00 tier means each card costs ₱5.00,
            and your potential winnings range from ₱5.00 to ₱10,000.00.
          </p>

          <div>
            <h3>Overview of Card Tiers and Potential Winnings:</h3>
            <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
              <li>
                <strong>5 PHP:</strong>
                Win anywhere from ₱5.00 to ₱5,000.00
              </li>
              <li>
                <strong>10 PHP:</strong>
                Win anywhere from ₱10.00 to ₱10,000.00.
              </li>
              <li>
                <strong>20 PHP:</strong>
                Win anywhere from ₱20.00 to ₱20,000.00.
              </li>
              <li>
                <strong>50 PHP:</strong>
                Win anywhere from ₱50.00 and ₱50,000.00
              </li>
              <li>
                <strong>100 PHP:</strong>
                Win anywhere from ₱100.00 up to ₱100,000.00.
              </li>
            </ul>

            <p className={`${styles['mtb-2']}`}>
              Each card tier offers a selection of different ScratchMania8 card
              games, providing a variety of gameplay experiences and prize
              opportunities within the same cost category.
            </p>
          </div>
        </div>
      )
    },
    {
      title: 'How to win?',
      content: (
        <div className={styles['max-w-8']}>
          Achieve a win by matching three identical amounts on your card. The
          prize mirrors the matched amounts. For instance, matching three
          instances of ₱50.00 awards you ₱50.00, credited to your balance.
        </div>
      )
    }
  ],
  'Game Interface': [
    {
      title: 'Where can I find the scratch cards',
      content: (
        <div className={styles['max-w-8']}>
          <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
            <li>
              At your ScratcMania8 app, tap on the scratch icon and it will take
              you to different tiers with 2 scratch games for each tier.
            </li>
            <li>Choose your tier and type of card.</li>
            <li>
              You can read the ScratchMania8 card mechanics by clicking on “?”
              Sign.
            </li>
            <li>You must have an account balance to get a card and play.</li>
          </ul>
        </div>
      )
    },
    {
      title: 'Understanding Your ScratchMania8 Card References.',
      content: (
        <div className={styles['max-w-8']}>
          <p>
            Each ScratchMania8 card produced by our system is uniquely
            identified by a serial number, which acts as its reference. The
            serial numbers are generated randomly, ensuring that each card you
            receive, whether through ScratchMania8 play or by selecting the next
            card, is random. Currently, there is no option to select a card
            based on serial number preference.
          </p>
        </div>
      )
    },
    {
      title: 'Guide to Playing ScratchMania8 Cards',
      content: (
        <div className={styles['max-w-8']}>
          <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
            <li>
              <strong>Select the Card:</strong> Choose the tier and type of
              ScratchMania8 card you wish to play.
            </li>
            <li>
              <strong>Initiate Scratching:</strong> Tap on “Scratch” to be
              randomly assigned a card by the system, ready for scratching.
            </li>
            <li>
              <strong>Position Your Phone:</strong> Ensure your phone remains
              upright. Your balance is displayed at the top of the screen, and
              opposite it, a “?” icon provides the game instructions.
            </li>
            <li>
              <strong>Scratch the Card:</strong> Use your pointer finger to
              gently scratch the screen.
            </li>
            <li>
              <strong>Reveal the Outcome:</strong> Once you&apos;ve finished
              scratching, a pop-up will appear indicating whether you&apos;ve
              won or lost. You then have the option to proceed to the next card
              and play again.
            </li>
            <li>
              <strong>Card Recovery:</strong> If you accidentally hit the back
              button or exit the app, the card will auto-reveal and be saved in
              your game history.
            </li>
          </ul>
        </div>
      )
    },
    {
      title: 'Understanding Your ScratchMania8 Balance.',
      content: (
        <div className={styles['max-w-8']}>
          <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
            <li>
              <strong>Purpose:</strong> Your ScratchMania8 Balance represents
              the total funds available in your app for purchasing the scratch
              cards.
            </li>
            <li>
              <strong>Increasing Balance:</strong>
              <strong>Increasing Balance:</strong> Add funds by topping up.
            </li>
            <li>
              <strong>Cash-Out:</strong> The balance can be withdrawn as cash.
            </li>
            <li>
              <strong>Winnings:</strong> All cash prizes are credited back to
              your ScratchMania8 Balance.
            </li>
            <li>
              <strong>Currency</strong>: All amounts are in Philippine Peso (₱).
            </li>
          </ul>
        </div>
      )
    }
  ],
  Results: [
    {
      title: 'How will I know the result?',
      content: (
        <div className={styles['max-w-8']}>
          <p>
            To discover the outcome of your Scratch card, follow these steps:
          </p>
          <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
            <li>
              <strong>Scratch the Card:</strong> The result will be revealed
              once you&apos;ve scratched off the covering.
            </li>
            <li>
              <strong>Winning a Prize:</strong> If you fulfill the winning
              criteria, a pop-up will appear, announcing your win. Following
              this, an animation will display coins being added to your balance.
            </li>
            <li>
              <strong>Not Winning:</strong> Should you not win, a pop-up with
              the message “Try Again” will be displayed on your screen.
            </li>
          </ul>
          <p className={styles['mtb-1']}>
            This process ensures a clear and immediate notification of your
            game&apos;s outcome, enhancing your playing experience.
          </p>
        </div>
      )
    },
    {
      title: 'I wanna dispute the result.',
      content: (
        <div className={styles['max-w-8']}>
          <p className={styles['mtb-1']}>
            If you have any disputes on your card, keep the serial number of the
            card. You may also take a screenshot of the card.
          </p>

          <p className={styles['mtb-1']}>
            If you experience any difficulties, please don&apos;t hesitate to
            reach out to our dedicated player support team via the Help Center
            feature within the app.
          </p>
        </div>
      )
    },
    {
      title: 'I lost because of technical reasons.',
      content: (
        <div className={styles['max-w-8']}>
          <p className={styles['mtb-1']}>
            If you encountered a loss due to technical reasons, please consider
            the following before reaching out to our customer service team:
          </p>

          <div>
            <h3>Is My Connection Stable?</h3>
            <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
              <li>
                <strong>NO:</strong> Delays or losses due to internet connection
                issues are beyond our control. To improve your gaming experience
                we suggest:
                <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
                  <li>Switching between data and Wi-Fi, or vice versa.</li>
                  <li>Finding a location with better signal or reception.</li>
                  <li>Connecting to a different internet source.</li>
                  <li>
                    Adding load to your device or subscribing to better data
                    plans for improved connection.
                  </li>
                </ul>
              </li>
              <li>
                <strong>YES:</strong> Proceed to the next question.
              </li>
            </ul>
          </div>

          <div className={styles['mtb-2']}>
            <h3>Is Your Device or App Updated?</h3>
            <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
              <li>
                <strong>NO: </strong>Missing updates can cause performance
                issues. Ensure your device and app are up to date by downloading
                and installing any available updates.
              </li>
              <li>
                <strong>YES:</strong> If you&apos;ve confirmed that your
                connection is stable and your app and device are updated yet you
                still experienced a loss due to technical reasons, it&apos;s
                time to seek further assistance
              </li>
            </ul>
          </div>

          <p>
            Next Steps: If your connection is stable and your app is updated,
            note the serial number of the affected game and contact our customer
            service team at 1-800-XXX-XXXX for support. This approach ensures we
            address your technical concerns effectively.
          </p>
        </div>
      )
    },
    {
      title: 'I lost because of Faulty Cards.',
      content: (
        <div className={styles['max-w-8']}>
          <p className={styles['mtb-1']}>
            If your loss resulted from encountering faulty cards, please follow
            these steps to address the issue:
          </p>

          <div className={styles['mtb-2']}>
            <h3>Identifying Faulty Cards</h3>
            <p className={styles['mtb-1']}>
              Faulty cards are considered defective if they are:
            </p>
            <ul className={`${styles['ml-2']} ${styles['list-disc']}`}>
              <li>
                <strong>Blank:</strong> Completely devoid of content.
              </li>
              <li>
                <strong>Missing Numbers or Icons:</strong> Lacking essential
                game elements.
              </li>
              <li>
                <strong>Unscratchable:</strong> The card does not respond to
                attempts to scratch it.
              </li>
            </ul>
          </div>

          <div className={styles['mtb-2']}>
            <h3>Preparing Your Proof</h3>
            <p className={styles['mtb-1']}>
              Before contacting customer service, please prepare:
            </p>
            <ul className={`${styles['ml-2']}  ${styles['list-disc']}`}>
              <li>
                Serial Number:
                <strong>Serial Number:</strong> Note the serial number of the
                faulty card.
              </li>
              <li>
                <strong>Screenshot:</strong> Take a screenshot of the defective
                card as evidence of the issue.
              </li>
            </ul>
          </div>

          <div className={styles['mtb-2']}>
            <h3>Seeking Assistance</h3>
            <ul>
              <li>
                <strong>Contact Customer Service:</strong> Reach out to our
                dedicated player support team via the Help Center feature within
                the app. Provide the serial number and the screenshot of the
                faulty card for our team to review.
              </li>
            </ul>
          </div>

          <div className={styles['mtb-2']}>
            <h3>Resolution Process</h3>
            <ul>
              <li>
                Investigation and Validation: We will conduct a thorough
                investigation into the reported fault. If your claim is
                validated as a genuine issue with the card,
              </li>
              <li>
                Compensation: We will credit your account with the amount
                equivalent to the card&apos;s value. Please note, a faulty card
                is not considered a winning card, and compensation is limited to
                the card&apos;s purchase value.
              </li>
            </ul>
          </div>

          <p className={styles['mtb-2']}>
            This process ensures that any issues with faulty cards are addressed
            fairly and promptly, maintaining the integrity of the gaming
            experience.
          </p>
        </div>
      )
    }
  ],
  Prices: [
    {
      title: 'How do I get my Cash Prize?',
      content: (
        <div className={styles['max-w-8']}>
          Congratulations! Your winnings will directly be credited to your
          balance. You are free to cash out these winnings at any time,
          providing immediate access to your prize.
        </div>
      )
    },
    {
      title:
        'I have complaints about the winnings I should be getting in my balance.',

      content: (
        <div className={styles['max-w-8']}>
          <p className={styles['mb-1']}>
            If you&apos;re concerned about the winnings credited to your
            balance, evaluate if you made any recent purchases or cash out after
            winning?
          </p>

          <ul className={`${styles['ml-2']} ${styles['list-disc']}`}>
            <li>YES: These activities could affect your balance. </li>
            <li>
              YES, but the balance still seems incorrect, or NO: Move to the
              next step
            </li>
          </ul>

          <p className={styles['mtb-2']}>
            Next Step: Note your ScratchMania8 Card Serial Number and reach out
            to our dedicated player support team via the Help Center feature
            within the app.
          </p>
        </div>
      )
    }
  ]
};
