import React from 'react';
import styles from './termsAndCondition.module.css';
import AcceptanceTerms from './AcceptanceTerms/AcceptanceTerms';

const TermsAndCondition = () => {
  return (
    <>
      <div className={`${styles.termsConditionBanner} container`}>
        <div className={styles.termsConditionCopy}>
          <h1 className={styles.termsConditionTitle}>Terms and Conditions</h1>
          <p>
            Please read our Terms and Conditions carefully before using our
            services. By accessing or using the ScratchMania app, you agree to
            comply with these terms, ensuring a safe and enjoyable experience
            for all users.
          </p>
        </div>
        <div className={styles.termsSvg}>
          <img src="./termscondition.svg" alt="" />
        </div>
      </div>
      <AcceptanceTerms />
    </>
  );
};

export default TermsAndCondition;
