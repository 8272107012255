// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageHeader_helpCenter__zgc5X {
  max-width: 70.4375rem;
  margin: 4.625rem auto;
}

.pageHeader_helpCenterBanner__BtWNh {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pageHeader_contentWrapper__LSDC9 {
  max-width: 39.688rem;
}

.pageHeader_title__l7Rxe {
  font-family: var(--font-rajdhani);
  font-size: 5.313rem;
  max-width: 36.528rem;
  line-height: 5.313rem;
  font-weight: 600;
}

.pageHeader_copy__\\+NoIy {
  color: rgba(212, 212, 212, 1);
  font-family: var(--font-nunito-sans);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.688rem;
  text-align: left;
  max-width: 55ch;
}
`, "",{"version":3,"sources":["webpack://./src/pages/HelpCenter/PageHeader/pageHeader.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,oCAAoC;EACpC,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".helpCenter {\n  max-width: 70.4375rem;\n  margin: 4.625rem auto;\n}\n\n.helpCenterBanner {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.contentWrapper {\n  max-width: 39.688rem;\n}\n\n.title {\n  font-family: var(--font-rajdhani);\n  font-size: 5.313rem;\n  max-width: 36.528rem;\n  line-height: 5.313rem;\n  font-weight: 600;\n}\n\n.copy {\n  color: rgba(212, 212, 212, 1);\n  font-family: var(--font-nunito-sans);\n  font-size: 1.125rem;\n  font-weight: 500;\n  line-height: 1.688rem;\n  text-align: left;\n  max-width: 55ch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpCenter": `pageHeader_helpCenter__zgc5X`,
	"helpCenterBanner": `pageHeader_helpCenterBanner__BtWNh`,
	"contentWrapper": `pageHeader_contentWrapper__LSDC9`,
	"title": `pageHeader_title__l7Rxe`,
	"copy": `pageHeader_copy__+NoIy`
};
export default ___CSS_LOADER_EXPORT___;
