import React from 'react';

const InstagramColored = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_326_803)">
          <path
            d="M13.7651 3.21289C13.1833 3.21289 12.7104 3.68574 12.7104 4.26758C12.7104 4.85117 13.1833 5.32227 13.7651 5.32227C14.3487 5.32227 14.8198 4.84941 14.8198 4.26758C14.8198 3.68574 14.347 3.21289 13.7651 3.21289Z"
            fill="url(#paint0_linear_326_803)"
          />
          <path
            d="M9.07383 4.56641C6.63047 4.56641 4.64062 6.55449 4.64062 8.99961C4.64062 11.443 6.62871 13.4328 9.07383 13.4328C11.5189 13.4328 13.507 11.443 13.507 8.99961C13.507 6.55449 11.5189 4.56641 9.07383 4.56641ZM9.07383 11.8385C7.50762 11.8385 6.23496 10.5641 6.23496 8.99961C6.23496 7.4334 7.50938 6.16074 9.07383 6.16074C10.6383 6.16074 11.9127 7.4334 11.9127 8.99961C11.9127 10.5658 10.64 11.8385 9.07383 11.8385Z"
            fill="url(#paint1_linear_326_803)"
          />
          <path
            d="M12.593 18H5.40703C2.42578 18 0 15.5742 0 12.593V5.40703C0 2.42578 2.42578 0 5.40703 0H12.593C15.5742 0 18 2.42578 18 5.40703V12.593C18 15.5742 15.5742 18 12.593 18ZM5.40703 1.69277C3.35918 1.69277 1.69453 3.35742 1.69453 5.40527V12.5912C1.69453 14.6391 3.36094 16.3037 5.40703 16.3037H12.593C14.6408 16.3037 16.3072 14.6373 16.3072 12.5912V5.40703C16.3072 3.35918 14.6408 1.69453 12.593 1.69453H5.40703V1.69277Z"
            fill="url(#paint2_linear_326_803)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_326_803"
            x1="4.87621"
            y1="-4.62005"
            x2="21.801"
            y2="12.3047"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.0011048" stopColor="#FFE185" />
            <stop offset="0.2094" stopColor="#FFBB36" />
            <stop offset="0.3765" stopColor="#FF5176" />
            <stop offset="0.5238" stopColor="#F63395" />
            <stop offset="0.7394" stopColor="#A436D2" />
            <stop offset="1" stopColor="#5F4EED" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_326_803"
            x1="0.165319"
            y1="0.0910683"
            x2="17.0902"
            y2="17.016"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.0011048" stopColor="#FFE185" />
            <stop offset="0.2094" stopColor="#FFBB36" />
            <stop offset="0.3765" stopColor="#FF5176" />
            <stop offset="0.5238" stopColor="#F63395" />
            <stop offset="0.7394" stopColor="#A436D2" />
            <stop offset="1" stopColor="#5F4EED" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_326_803"
            x1="0.128324"
            y1="0.128401"
            x2="17.0533"
            y2="17.0534"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.0011048" stopColor="#FFE185" />
            <stop offset="0.2094" stopColor="#FFBB36" />
            <stop offset="0.3765" stopColor="#FF5176" />
            <stop offset="0.5238" stopColor="#F63395" />
            <stop offset="0.7394" stopColor="#A436D2" />
            <stop offset="1" stopColor="#5F4EED" />
          </linearGradient>
          <clipPath id="clip0_326_803">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default InstagramColored;
