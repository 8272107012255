import React from 'react';

const InstagramWhite = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.7654 3.21338C13.1835 3.21338 12.7107 3.68623 12.7107 4.26807C12.7107 4.85166 13.1835 5.32275 13.7654 5.32275C14.349 5.32275 14.8201 4.8499 14.8201 4.26807C14.8201 3.68623 14.3472 3.21338 13.7654 3.21338Z"
          fill="white"
        />
        <path
          d="M9.07383 4.56689C6.63047 4.56689 4.64062 6.55498 4.64062 9.0001C4.64062 11.4435 6.62871 13.4333 9.07383 13.4333C11.5189 13.4333 13.507 11.4435 13.507 9.0001C13.507 6.55498 11.5189 4.56689 9.07383 4.56689ZM9.07383 11.839C7.50762 11.839 6.23496 10.5646 6.23496 9.0001C6.23496 7.43389 7.50938 6.16123 9.07383 6.16123C10.6383 6.16123 11.9127 7.43389 11.9127 9.0001C11.9127 10.5663 10.64 11.839 9.07383 11.839Z"
          fill="white"
        />
        <path
          d="M12.593 18H5.40703C2.42578 18 0 15.5742 0 12.593V5.40703C0 2.42578 2.42578 0 5.40703 0H12.593C15.5742 0 18 2.42578 18 5.40703V12.593C18 15.5742 15.5742 18 12.593 18ZM5.40703 1.69277C3.35918 1.69277 1.69453 3.35742 1.69453 5.40527V12.5912C1.69453 14.6391 3.36094 16.3037 5.40703 16.3037H12.593C14.6408 16.3037 16.3072 14.6373 16.3072 12.5912V5.40703C16.3072 3.35918 14.6408 1.69453 12.593 1.69453H5.40703V1.69277Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default InstagramWhite;
