import React from 'react';
import styles from './footerSection.module.css';
import FooterAbout from './FooterAbout/FooterAbout';
import NewsLetter from './NewsLetter/NewsLetter';
import QuickMenu from './QuickMenu/QuickMenu';
import Legal from './Legal/Legal';

const FooterSection = () => {
  return (
    <footer className={`${styles.footer} container`}>
      <div className={styles.footerInfo}>
        <NewsLetter />
        <FooterAbout />
        <QuickMenu />
      </div>
      <Legal />
    </footer>
  );
};

export default FooterSection;
