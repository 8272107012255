/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import ResponsiblePlayer from './ResponsiblePlayer/ResponsiblePlayer';
import styles from './gamingGuidelines.module.css';

const GamingGuidelines = () => {
  return (
    <>
      <div className={`${styles.gamingGuidelinesBanner} container`}>
        <div className={styles.gamingCopy}>
          <h1 className={styles.gamingTitle}>Responsible Gaming</h1>
          <p>
            Your well-being is important to us. Read our Responsible Gaming
            guidelines to learn how to enjoy ScratchMania safely and
            responsibly. By playing our games, you agree to adhere to these
            practices for a safe gaming experience.
          </p>
        </div>
        <div className={styles.heroSvg}>
          <img src="./responsible.png" alt="" />
        </div>
      </div>
      <ResponsiblePlayer />
    </>
  );
};

export default GamingGuidelines;
