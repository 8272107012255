import React from 'react';
import styles from './gameCard.module.css';

type GameCardProps = {
  name: string;
  gameType: string;
  cost: number;
  thumbnail?: string;
};

const GameCard = ({ name, gameType, cost, thumbnail }: GameCardProps) => {
  return (
    <div className={styles.gameCard}>
      <div className={styles.thumbnailContainer}>
        <img src={thumbnail} alt="" />
      </div>
      <div className={styles.gameCardContent}>
        <p className={styles.gameName}>{name}</p>
        <p className={styles.gameType}>{gameType}</p>
        <p className={styles.cost}>
          {cost}
          <img className={styles.coin} src="./coin-small.png" alt="" />
        </p>
      </div>
    </div>
  );
};

export default GameCard;
