// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gameStory_gameStoryContainer__X37sz {
  display: flex;
  margin: 6.75rem 0;
}

.gameStory_gameStoryCopy__CaOcM {
  margin-left: 4rem;
}

.gameStory_gameStoryCopy__CaOcM h3 {
  font-weight: 500;
  margin: 0.875rem 0 3rem 0;
  line-height: 4.6875rem;
  font-size: 4.6875rem;
  font-weight: 600;

  /* the font starts a bit right */
  /* to align it we use translate */
  transform: translateX(-5px);
}

.gameStory_gameStoryCopy__CaOcM p:not(:first-child) {
  font-family: var(--font-nunito-sans);
  line-height: 1.534375rem;
  margin: 2.125rem 0;
  max-width: 90%;
}

.gameStory_playNowBtn__cXWCC {
  width: 10.5625rem;
  height: 3.4375rem;
  background-color: var(--secondary-cyan);

  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/TopupSection/GameStory/gameStory.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;EACtB,oBAAoB;EACpB,gBAAgB;;EAEhB,gCAAgC;EAChC,iCAAiC;EACjC,2BAA2B;AAC7B;;AAEA;EACE,oCAAoC;EACpC,wBAAwB;EACxB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,uCAAuC;;EAEvC,yBAAyB;;EAEzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".gameStoryContainer {\n  display: flex;\n  margin: 6.75rem 0;\n}\n\n.gameStoryCopy {\n  margin-left: 4rem;\n}\n\n.gameStoryCopy h3 {\n  font-weight: 500;\n  margin: 0.875rem 0 3rem 0;\n  line-height: 4.6875rem;\n  font-size: 4.6875rem;\n  font-weight: 600;\n\n  /* the font starts a bit right */\n  /* to align it we use translate */\n  transform: translateX(-5px);\n}\n\n.gameStoryCopy p:not(:first-child) {\n  font-family: var(--font-nunito-sans);\n  line-height: 1.534375rem;\n  margin: 2.125rem 0;\n  max-width: 90%;\n}\n\n.playNowBtn {\n  width: 10.5625rem;\n  height: 3.4375rem;\n  background-color: var(--secondary-cyan);\n\n  text-transform: uppercase;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gameStoryContainer": `gameStory_gameStoryContainer__X37sz`,
	"gameStoryCopy": `gameStory_gameStoryCopy__CaOcM`,
	"playNowBtn": `gameStory_playNowBtn__cXWCC`
};
export default ___CSS_LOADER_EXPORT___;
