import React from 'react';
import styles from './browseByCategory.module.css';
import gameCategories from '../../data/categories.json';

import GameCardByCategory from './GameCardByCategory/GameCardByCategory';
import { useCategory } from '../../context/CategoryContextProvider';

const BrowseByCategory = () => {
  const { activeCategory, handleCategoryClick } = useCategory();

  return (
    <div className={`${styles.browseByCategory} container`}>
      <h2 className="sectionTitle">BROWSE BY CATEGORY</h2>
      <ul className={`${styles.displayGameByCategory}`}>
        {gameCategories.map((game, index) => {
          const { category, thumbnail } = game;
          const isActiveCategory =
            category.toLowerCase() === activeCategory?.toLowerCase();
          return (
            <li
              key={`${index}-${category}`}
              onClick={() => handleCategoryClick(category)}>
              <GameCardByCategory
                category={category}
                thumbnail={thumbnail}
                isActive={isActiveCategory}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BrowseByCategory;
