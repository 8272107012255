import React from 'react';
import styles from './accountHeader.module.css';
import Avatar from './Avatar/Avatar';

const AccountHeader = () => {
  return (
    <div className={styles.headerContainer}>
      <Avatar />

      <div className={styles.accountInfo}>
        <p className={styles.name}>
          Gordon, Norman{' '}
          <span>
            <img src="verified-check.png" alt="" />
          </span>
        </p>
        <p>sm-yyyymmdd-000001</p>

        <div className={styles.contactInfo}>
          <div>
            <p className={styles.label}>Mobile Number</p>
            <p className={styles.value}>+639123456789</p>
          </div>
          <div>
            <p className={styles.label}>Email Address</p>
            <p className={styles.value}>norman@example.com</p>
          </div>
        </div>

        <div className={styles.userBalance}>
          <div>
            <p className={styles.label}>credits</p>
            <p className={styles.value}>100,000,000 PHP</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountHeader;
