import React, { createContext, useState, useContext, ReactNode } from 'react';

interface CategoryContextType {
  activeCategory: string | null;
  isCategorySelected: boolean;
  handleCategoryClick: (category: string) => void;
}

const CategoryContext = createContext<CategoryContextType | undefined>(
  undefined
);

interface CategoryProviderProps {
  children: ReactNode;
}

export const CategoryProvider: React.FC<CategoryProviderProps> = ({
  children
}) => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [isCategorySelected, setIsCategorySelected] = useState<boolean>(false);

  const handleCategoryClick = (category: string) => {
    setActiveCategory(category);
    setIsCategorySelected(true);
  };

  return (
    <CategoryContext.Provider
      value={{ activeCategory, isCategorySelected, handleCategoryClick }}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = (): CategoryContextType => {
  const context = useContext(CategoryContext);
  if (context === undefined) {
    throw new Error('useCategory must be used within a CategoryProvider');
  }
  return context;
};
