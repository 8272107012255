// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topCard_topCard__J0BOQ {
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 15.938rem;
  height: 26rem;
  background-color: rgba(255, 255, 255, 0.07);
  cursor: pointer;
}

.topCard_topCard__J0BOQ:hover {
  background-color: rgba(94, 89, 109, 1);
}

.topCard_content__RlHUV {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  text-align: center;
  margin-top: 1.25rem;
}

.topCard_content__RlHUV img {
  width: 4.0625rem;
  display: block;
}

.topCard_name__umywH {
  font-size: 1.125rem;
  text-transform: capitalize;
  font-weight: 600;
  font-family: var(--font-rajdhani);
}

.topCard_count__edcMk {
  opacity: 0.6;
}

.topCard_playNowBtn__VRQ2D {
  background-color: var(--secondary-persian-rose);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  width: 8rem;
  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/TopCards/TopCard/topCard.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,2CAA2C;EAC3C,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;;EAEX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;EAC1B,gBAAgB;EAChB,iCAAiC;AACnC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,+CAA+C;EAC/C,oBAAoB;EACpB,kBAAkB;EAClB,WAAW;EACX,0BAA0B;;EAE1B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".topCard {\n  border-radius: 0.5rem;\n  padding: 0.5rem;\n  width: 15.938rem;\n  height: 26rem;\n  background-color: rgba(255, 255, 255, 0.07);\n  cursor: pointer;\n}\n\n.topCard:hover {\n  background-color: rgba(94, 89, 109, 1);\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 0.5rem;\n\n  text-align: center;\n  margin-top: 1.25rem;\n}\n\n.content img {\n  width: 4.0625rem;\n  display: block;\n}\n\n.name {\n  font-size: 1.125rem;\n  text-transform: capitalize;\n  font-weight: 600;\n  font-family: var(--font-rajdhani);\n}\n\n.count {\n  opacity: 0.6;\n}\n\n.playNowBtn {\n  background-color: var(--secondary-persian-rose);\n  padding: 0.5rem 1rem;\n  border-radius: 5px;\n  width: 8rem;\n  text-transform: capitalize;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topCard": `topCard_topCard__J0BOQ`,
	"content": `topCard_content__RlHUV`,
	"name": `topCard_name__umywH`,
	"count": `topCard_count__edcMk`,
	"playNowBtn": `topCard_playNowBtn__VRQ2D`
};
export default ___CSS_LOADER_EXPORT___;
