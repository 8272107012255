import React from 'react';
import style from './newCards.module.css';

import gameCardData from '../../data/gameCard.json';
import GameCard from '../GameCard/GameCard';
import { sanitizeForUrl } from '../../utils/helperFunctions';

const newCards = [
  'Pearl of the Orient',
  'Ice Cream Island Adventure',
  'Panda Paradise',
  'Cocktail Carnival Craze'
];

const NewCards = () => {
  return (
    <div className={`${style.newCards} container`}>
      <h2 className="sectionTitle">NEW CARDS</h2>

      <ul className={style.gamesContainer}>
        {gameCardData
          .filter((game) => newCards.includes(game.gameTitle))
          .map((game) => {
            const thumbnail = sanitizeForUrl(game.gameTitle);

            return (
              <li key={`new-game-${game.gameTitle}`}>
                <GameCard
                  name={game.gameTitle}
                  gameType={game.gameType}
                  cost={game.tier}
                  thumbnail={`./thumbnails/cards/${thumbnail}.png`}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default NewCards;
