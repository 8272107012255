// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newCards_newCards__lF0Xv {
  margin: 7.25rem auto;
}

.newCards_gamesContainer__tkPE8 {
  display: flex;
  gap: 1.875rem;
  margin-top: 2.9375rem;
}

.newCards_gamesContainer__tkPE8 li {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/NewCards/newCards.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".newCards {\n  margin: 7.25rem auto;\n}\n\n.gamesContainer {\n  display: flex;\n  gap: 1.875rem;\n  margin-top: 2.9375rem;\n}\n\n.gamesContainer li {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newCards": `newCards_newCards__lF0Xv`,
	"gamesContainer": `newCards_gamesContainer__tkPE8`
};
export default ___CSS_LOADER_EXPORT___;
