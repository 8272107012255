import React from 'react';

export const extractTextFromContent = (content: React.ReactNode): string => {
  if (typeof content === 'string') {
    return content;
  }

  let text = '';

  // Traverse the React element tree
  const traverseElement = (element: React.ReactNode) => {
    if (typeof element === 'string') {
      text += element;
    } else if (React.isValidElement(element)) {
      React.Children.forEach(element.props.children, traverseElement);
    } else if (Array.isArray(element)) {
      element.forEach(traverseElement);
    }
  };

  traverseElement(content);

  return text;
};
