import React from 'react';

type CoinProps = {
  width?: number;
  height?: number;
};

const Coin = ({ width = 100, height = 100 }: CoinProps) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1000 1000"
      width={width}
      height={height}
      xmlSpace="preserve">
      <style type="text/css">
        {`
        .st0{fill:#301D10;}
        .st1{fill:#BC6C28;}
        .st2{fill:#D89E2F;}
        .st3{fill:#F2CA51;}
        .st4{fill:#ECDCC2;}
        .st5{fill:#EFA733;}
        .st6{fill:#FFFFFF;}
      `}
      </style>
      <g id="coin_00000014603445194318816100000012727546036015670935_">
        <path
          className="st0"
          d="M948.2,322.7c-1.5,0-3,0.2-4.4,0.7L141.5,602.3c-7,2.4-10.6,10-8.2,17l31.6,92.7 c26.9,78.7,123,125.7,257.1,125.7c74.1,0,155.1-14.5,234.3-42.1c109.2-38,204.2-96.6,267.7-165c65.5-70.6,89.9-143.8,68.6-206.1 L960.9,332c-1.1-3.4-3.6-6.1-6.9-7.7C952.2,323.2,950.2,322.7,948.2,322.7L948.2,322.7z"
        />
        <g>
          <path
            className="st1"
            d="M177.3,707.6C217.7,826.2,430,860,651.6,783s368.4-235.6,328-354.2L948,336.1L145.8,614.9L177.3,707.6z"
          />
        </g>
        <path
          className="st0"
          d="M679.8,186.7c-70,0-145.5,13.4-218.3,38.6c-109.3,38-203.8,99.1-265.8,172.1c-30.6,36-51.7,73.1-62.9,110.2 c-11.7,39.2-11.7,76.9,0.1,111.6c15.5,45.6,50.9,83.1,102.2,108.5c48.5,24,110.3,36.6,178.7,36.6c70,0,145.5-13.4,218.3-38.7 c109.3-38,203.7-99.1,265.8-172.1c30.6-36,51.7-73.1,62.9-110.2c11.7-39.2,11.7-76.9-0.1-111.6c-15.5-45.6-50.9-83.1-102.2-108.5 C810.1,199.4,748.2,186.7,679.8,186.7L679.8,186.7z"
        />
        <g>
          <path
            className="st2"
            d="M463.8,238.8c221.3-77.7,437.9-34.7,483.8,96c22.3,63.4,0.3,135.8-53.4,202.4 C837.2,608,744.2,672.3,630.2,712.3c-221.3,77.7-437.9,34.7-483.8-96S242.6,316.5,463.8,238.8z"
          />
        </g>
        <path
          className="st0"
          d="M632.9,267.7c-47.5,0-98.6,9-148,26.2c-74.1,25.7-138.1,67.2-180.2,116.8c-43.7,51.5-59,105.9-42.9,153.1 c10.7,31.7,35.2,57.7,70.9,75.4c33.1,16.4,75.2,25,121.8,25c47.5,0,98.6-9,148-26.2c6.9-2.4,13.6-4.9,20.3-7.5 c65.5-25.9,121.7-64.3,159.9-109.3c43.7-51.5,59-105.9,42.9-153.1c-10.9-31.7-35.2-57.7-70.9-75.4 C721.6,276.3,679.5,267.7,632.9,267.7L632.9,267.7z"
        />
        <g>
          <ellipse
            transform="matrix(0.9435 -0.3314 0.3314 0.9435 -123.6916 206.4861)"
            className="st3"
            cx="543.6"
            cy="465.9"
            rx="284.9"
            ry="168.3"
          />
        </g>
        <path
          className="st4"
          d="M226.1,592c28.2,82.9,130.2,128,257.4,125.6C327.2,739.1,194,693.7,161.1,597.3 C119.8,476.1,252.3,320.2,457,249.1c64.9-22.5,129.1-33.9,188-35c-39.9,5.5-81.4,15.4-123,29.7C317.2,314.8,184.7,470.8,226.1,592z"
        />
        <g>
          <path
            className="st5"
            d="M595.7,163.7C364.5,269.2,344.5,373.2,381.9,473.4C324.6,384.7,220.7,327,0,424.6 c214.6-110.7,247.4-206.8,213.7-309.7C268.2,203.4,356.7,259.6,595.7,163.7z"
          />
        </g>
        <path
          className="st6"
          d="M510.5,201c-165.1,75.4-179.3,149.6-152.6,221.1c-41-63.4-115.1-104.6-272.7-35 c153.2-79,176.7-147.6,152.6-221.1C276.7,229.4,339.9,269.5,510.5,201z"
        />
      </g>
    </svg>
  );
};

export default Coin;
