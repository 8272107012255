import React, { useCallback, useState, type FormEvent } from 'react';
import styles from './signIn.module.css';
import authStyle from '../auth.module.css';
import globalStyle from '../../../../global.module.css';
import ArrowRight from '../../../Icons/ArrowRight/ArrowRight';
import { Link } from 'react-router-dom';

import routes from '../../../../router/routes.json';
import classNames from 'classnames';
import { AuthType } from '../Auth';
import { login } from '../../../../api/auth';
import { useAuth } from '../../../../context/AuthContextProvider';

type SignInProps = {
  isActive: boolean;
  handleChangeActiveAuth: (authType: AuthType) => void;
};

const SignIn = ({ isActive, handleChangeActiveAuth }: SignInProps) => {
  const { authenticateUser } = useAuth();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        const response = await login(userName, password);
        const { token_type, access_token } = response;
        authenticateUser(token_type, access_token);

        console.log('Login successful:', response);
      } catch (error) {
        console.error('Error :', error);
      }
    },
    [userName, password]
  );

  return (
    <>
      <img className={authStyle.logo} src="./logo.png" alt="" />

      <div className={styles.authHeader}>
        <div className={`${authStyle.authCta}`}>
          <button
            onClick={() => handleChangeActiveAuth(AuthType.Login)}
            className={classNames({
              [authStyle.activeButton]: isActive
            })}>
            Login
          </button>
          <button
            onClick={() => handleChangeActiveAuth(AuthType.Register)}
            className={classNames({
              [authStyle.activeButton]: !isActive
            })}>
            Register
          </button>
        </div>

        <h3 className={authStyle.header}>Login to Your Account</h3>
        <p className={authStyle.description}>
          Access your account to continue your winning journey.
        </p>
      </div>

      <form
        className={`${authStyle.form} ${styles.loginForm}`}
        onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Email Address or Username"
          onChange={(e) => setUserName(e.target.value)}
          className={authStyle.inputField}
        />
        <input
          type="text"
          placeholder="Create Password"
          onChange={(e) => setPassword(e.target.value)}
          className={authStyle.inputField}
        />
        <button
          type="submit"
          className={classNames(
            authStyle.formSubmitBtn,
            globalStyle.btnIconReveal
          )}>
          <span>Sign Up</span>
          <ArrowRight className={globalStyle.icon} />
        </button>
        <a href="#" className={styles.forgotPassword}>
          Forgot my password <ArrowRight />
        </a>
      </form>

      <p className={styles.termsAgreement}>
        By logging in, you agree to our
        <Link
          //   onClick={closeModal}
          className={styles.modalLink}
          to={routes.termsAndCondition.url}>
          Terms of Use
        </Link>
        and acknowledge our
        <Link
          //   onClick={closeModal}
          className={styles.modalLink}
          to={routes.privacyPolicy.url}>
          Privacy Policy
        </Link>
        .
      </p>
    </>
  );
};

export default SignIn;
