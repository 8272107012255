// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topupSection_topUpThrough__StzcR {
  margin: 6.75rem 0;
}

.topupSection_sectionWrapper__o4Q1S {
  position: relative;
  width: 100%;
}

.topupSection_topUpContainer__cRQVi {
  max-width: 69.4375rem;
  margin: -5.375rem auto;

  display: flex;
}

.topupSection_topUpThrough__StzcR h2:first-child {
  text-align: center;
  font-size: 1.5rem;

  padding: 1.625rem 0;
}

.topupSection_topUpThrough__StzcR h2:first-child::before {
  content: '';
  position: absolute;
  left: 0;
  width: 100vw;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(50px);
}

.topupSection_topUpThroughContent__cYm5h {
  padding-left: 10.3125rem;
  padding-right: 10.3125rem;
}

.topupSection_topUpThroughContent__cYm5h ul {
  display: flex;
  justify-content: space-evenly;

  width: 100%;
  margin: 2rem 0;
}

.topupSection_paymentWrapper__FOnwk {
  width: 5.0625rem;
  height: 2.5rem;

  background-color: #241d3a;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/TopupSection/topupSection.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;;EAEtB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;;EAEjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,YAAY;EACZ,WAAW;EACX,0CAA0C;EAC1C,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;EACxB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,6BAA6B;;EAE7B,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;;EAEd,yBAAyB;EACzB,0CAA0C;EAC1C,kBAAkB;;EAElB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".topUpThrough {\n  margin: 6.75rem 0;\n}\n\n.sectionWrapper {\n  position: relative;\n  width: 100%;\n}\n\n.topUpContainer {\n  max-width: 69.4375rem;\n  margin: -5.375rem auto;\n\n  display: flex;\n}\n\n.topUpThrough h2:first-child {\n  text-align: center;\n  font-size: 1.5rem;\n\n  padding: 1.625rem 0;\n}\n\n.topUpThrough h2:first-child::before {\n  content: '';\n  position: absolute;\n  left: 0;\n  width: 100vw;\n  height: 1px;\n  background-color: rgba(255, 255, 255, 0.2);\n  transform: translateY(50px);\n}\n\n.topUpThroughContent {\n  padding-left: 10.3125rem;\n  padding-right: 10.3125rem;\n}\n\n.topUpThroughContent ul {\n  display: flex;\n  justify-content: space-evenly;\n\n  width: 100%;\n  margin: 2rem 0;\n}\n\n.paymentWrapper {\n  width: 5.0625rem;\n  height: 2.5rem;\n\n  background-color: #241d3a;\n  border: 1px solid rgba(255, 255, 255, 0.2);\n  border-radius: 3px;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topUpThrough": `topupSection_topUpThrough__StzcR`,
	"sectionWrapper": `topupSection_sectionWrapper__o4Q1S`,
	"topUpContainer": `topupSection_topUpContainer__cRQVi`,
	"topUpThroughContent": `topupSection_topUpThroughContent__cYm5h`,
	"paymentWrapper": `topupSection_paymentWrapper__FOnwk`
};
export default ___CSS_LOADER_EXPORT___;
