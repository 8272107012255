// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal_legal__is93m {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;

  font-family: var(--font-nunito-sans);
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}

.legal_legal__is93m ul {
  display: flex;
}

.legal_legal__is93m ul li:not(:last-child) {
  margin-right: 2rem;
}

.legal_legal__is93m ul li a {
  text-decoration: none;
  color: inherit;
}

.legal_legal__is93m ul li a:hover,
.legal_legal__is93m ul li a:active {
  color: var(--primary-orange-yellow);
}
`, "",{"version":3,"sources":["webpack://./src/components/FooterSection/Legal/legal.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,8BAA8B;;EAE9B,oCAAoC;EACpC,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;;EAEE,mCAAmC;AACrC","sourcesContent":[".legal {\n  margin: 2rem 0;\n  display: flex;\n  justify-content: space-between;\n\n  font-family: var(--font-nunito-sans);\n  font-size: 0.875rem;\n  color: rgba(255, 255, 255, 0.6);\n}\n\n.legal ul {\n  display: flex;\n}\n\n.legal ul li:not(:last-child) {\n  margin-right: 2rem;\n}\n\n.legal ul li a {\n  text-decoration: none;\n  color: inherit;\n}\n\n.legal ul li a:hover,\n.legal ul li a:active {\n  color: var(--primary-orange-yellow);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legal": `legal_legal__is93m`
};
export default ___CSS_LOADER_EXPORT___;
