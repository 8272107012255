// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signIn_forgotPassword__Tp45K {
  font-family: var(--font-rajdhani);
  font-weight: 600;
  color: var(--primary-orange-yellow);
  text-align: left;
  align-self: flex-start;
  text-decoration: none;
  margin-top: 0.5rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.signIn_termsAgreement__naasC {
  margin-top: 1rem;
  text-align: center;
  width: 80%;
}

.signIn_modalLink__DNren {
  color: rgba(249, 207, 49, 1);
  text-decoration: none;
  font-weight: 700;
  margin: 0 0.2em;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/Auth/SignIn/signIn.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,mCAAmC;EACnC,gBAAgB;EAChB,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;;EAElB,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,4BAA4B;EAC5B,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".forgotPassword {\n  font-family: var(--font-rajdhani);\n  font-weight: 600;\n  color: var(--primary-orange-yellow);\n  text-align: left;\n  align-self: flex-start;\n  text-decoration: none;\n  margin-top: 0.5rem;\n\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.termsAgreement {\n  margin-top: 1rem;\n  text-align: center;\n  width: 80%;\n}\n\n.modalLink {\n  color: rgba(249, 207, 49, 1);\n  text-decoration: none;\n  font-weight: 700;\n  margin: 0 0.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgotPassword": `signIn_forgotPassword__Tp45K`,
	"termsAgreement": `signIn_termsAgreement__naasC`,
	"modalLink": `signIn_modalLink__DNren`
};
export default ___CSS_LOADER_EXPORT___;
