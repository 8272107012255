import React, { useEffect, useState } from 'react';
import style from './cards.module.css';
import GameCard from '../GameCard/GameCard';

import __gameCardData from '../../data/gameCard.json';
import { sanitizeForUrl } from '../../utils/helperFunctions';
import { useCategory } from '../../context/CategoryContextProvider';

const tierCosts = [5, 10, 20, 50, 100];

interface Game {
  gameType: string;
  tier: number;
  gameTitle: string;
  thumbnail?: string;
}

const Cards = () => {
  const { activeCategory } = useCategory();
  const [activePage, setActivePage] = useState(tierCosts[0]);
  const [filteredGames, setFilteredGames] = useState<Game[]>([]);
  const [gameCardData, setGameCardData] = useState(__gameCardData);

  const handlePageClick = (num: number) => {
    setActivePage(num);
  };

  useEffect(() => {
    if (activeCategory === null) return;
    const filterByCategory = __gameCardData.filter((game) => {
      return game.gameType === activeCategory;
    });
    setGameCardData(filterByCategory);
  }, [activeCategory]);

  useEffect(() => {
    const filtered = gameCardData.filter((game) => game.tier === activePage);
    setFilteredGames(filtered);
  }, [activePage, gameCardData]);

  return (
    <div id="cards" className={`${style.cards} container`}>
      <div>
        <div className={style.headerWrapper}>
          <h2 className="sectionTitle">CARDS</h2>

          <ul className={style.pageNavigation}>
            {tierCosts.map((num) => (
              <li
                key={num}
                className={num === activePage ? style.active : ''}
                onClick={() => handlePageClick(num)}>
                <a>{num}</a>
              </li>
            ))}
          </ul>

          <button className={style.exploreBtn}>Explore more</button>
        </div>

        <ul className={style.gamesContainer}>
          {filteredGames.map((game) => {
            const thumbnail = sanitizeForUrl(game.gameTitle);

            return (
              <li key={game.gameTitle}>
                <GameCard
                  name={game.gameTitle}
                  gameType={game.gameType}
                  cost={game.tier}
                  thumbnail={`./thumbnails/cards/${thumbnail}.png`}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Cards;
