import React from 'react';
import styles from './topUpStats.module.css';

const TopUpStats = () => {
  return (
    <div className={styles.topUpStats}>
      <div className={styles.statsHeader}>Millions Winning Big Every Day</div>
      <div>
        <ul className={styles.statsContainer}>
          <li>
            <div>500K+</div>
            <p>Cards Played</p>
          </li>
          <li>
            <div>20M+</div>
            <p>Total Prizes Won</p>
          </li>
          <li>
            <div>50K+</div>
            <p>Active Players</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TopUpStats;
