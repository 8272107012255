import React from 'react';
import styles from './contactUs.module.css';

import Banner from './Banner/Banner';
import ContactDetails from './ContactDetails/ContactDetails';
import ContactForm from './ContactForm/ContactForm';

const ContactUs = () => {
  return (
    <div className={styles.root}>
      <Banner />

      <div className={styles.contentWrapper}>
        <ContactDetails />
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactUs;
