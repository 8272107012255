import React from 'react';
import { IconState } from '../../../types/iconState';

type PayPalProps = {
  state?: IconState;
};

const PayPal = ({ state = IconState.Inactive }: PayPalProps) => {
  const fillColor = state === IconState.Inactive ? '#544E65' : '';

  return (
    <>
      <svg
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.15568 21.3302C3.64332 21.3351 2.12609 21.34 0.613724 21.3351C0.20524 21.3351 -0.0573575 20.9752 0.0107233 20.557C0.263595 18.9474 0.501877 17.3378 0.754749 15.733C0.983305 14.2887 1.23131 12.8444 1.45987 11.3953C1.62521 10.3498 1.7711 9.30424 1.93644 8.25872C2.17958 6.71717 2.43245 5.17563 2.68046 3.63408C2.82149 2.74903 2.95278 1.86398 3.08895 0.978934C3.17648 0.400248 3.63359 0.00635154 4.22687 0.00635154C6.78476 0.00635154 9.34751 -0.0131001 11.9054 0.0160774C13.3011 0.0306661 14.687 0.205731 15.9562 0.852498C17.2741 1.51385 18.0764 2.58856 18.339 4.04743C18.4606 4.72824 18.4168 5.40905 18.2855 6.08986C17.7409 5.81267 17.1671 5.63274 16.5738 5.51603C15.8346 5.37014 15.076 5.33124 14.3223 5.32152C12.4209 5.30206 10.5146 5.30693 8.61321 5.32152C8.20959 5.32638 7.84974 5.49172 7.68926 5.90507C7.61632 6.09472 7.592 6.30869 7.56282 6.51779C7.43152 7.31531 7.30509 8.11769 7.17865 8.92007C7.0814 9.53766 6.97441 10.1553 6.88202 10.7728C6.76531 11.5558 6.66319 12.3436 6.51243 13.1216C6.46381 13.3794 6.35682 13.642 6.43463 13.9191C6.37627 14.0942 6.32278 14.2693 6.2936 14.4541C6.15744 15.3537 6.01642 16.2533 5.87539 17.153C5.70519 18.2423 5.54472 19.3364 5.35992 20.4257C5.30157 20.7272 5.32102 21.0482 5.15568 21.3302Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#012D8A' : fillColor}`}
        />
        <path
          d="M5.15574 21.3303C5.32108 21.0531 5.30163 20.7273 5.35026 20.4258C5.53505 19.3365 5.69552 18.2472 5.86573 17.153C6.00675 16.2534 6.14777 15.3537 6.28394 14.4541C6.31311 14.2693 6.36661 14.0943 6.42496 13.9192C6.68756 13.5107 7.05228 13.3308 7.54829 13.3356C8.78834 13.3356 10.0284 13.3989 11.2636 13.2432C12.3674 13.1071 13.4227 12.825 14.405 12.2998C15.9174 11.4877 16.9483 10.2477 17.5951 8.67697C17.9014 7.92808 18.1446 7.15488 18.2467 6.34763C18.2564 6.32818 18.2613 6.30873 18.271 6.29414C18.2807 6.24551 18.2905 6.19688 18.3002 6.14825C19.4576 6.76098 20.0752 7.74329 20.2405 9.0271C20.3767 10.0824 20.2162 11.1084 19.9536 12.1296C19.7445 12.9417 19.4235 13.7052 18.9664 14.4055C18.2224 15.5531 17.1866 16.3506 15.8979 16.8078C15.2268 17.046 14.5363 17.1822 13.8312 17.2649C13.2428 17.333 12.6543 17.333 12.0659 17.3378C11.4727 17.3378 11.0934 17.6636 11.0107 18.2472C10.8308 19.4775 10.6508 20.7078 10.4369 21.9381C10.3639 22.3563 10.3056 22.7794 10.2326 23.1928C10.1402 23.6985 9.78037 24 9.26976 24C8.02972 24 6.78968 24 5.54964 24C5.07793 24 4.84938 23.7277 4.92718 23.2706C5.01958 22.7065 5.11197 22.1472 5.20437 21.5831C5.21896 21.4907 5.24327 21.3984 5.15574 21.3303Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#019AE0' : fillColor}`}
        />
        <path
          d="M18.305 6.13854C18.2952 6.18717 18.2855 6.23579 18.2758 6.28442C18.2661 6.30388 18.2612 6.32333 18.2515 6.33792C18.1493 7.14516 17.9062 7.91836 17.5998 8.66725C16.9531 10.238 15.9221 11.478 14.4098 12.2901C13.4275 12.8153 12.3673 13.0974 11.2683 13.2335C10.0331 13.3843 8.79311 13.3259 7.55306 13.3259C7.05705 13.3259 6.69233 13.501 6.42973 13.9095C6.35192 13.6372 6.45891 13.3697 6.50754 13.112C6.65342 12.3339 6.76041 11.5461 6.87712 10.7632C6.96951 10.1456 7.0765 9.52799 7.17376 8.9104C7.30019 8.10802 7.42663 7.3105 7.55793 6.50812C7.59197 6.30388 7.61142 6.08991 7.68436 5.89539C7.84484 5.48204 8.20469 5.31184 8.60831 5.31184C10.5097 5.29725 12.416 5.29239 14.3174 5.31184C15.0711 5.32157 15.8249 5.36047 16.5689 5.50636C17.1622 5.62307 17.7409 5.803 18.2806 6.08018C18.2904 6.10936 18.3001 6.12395 18.305 6.13854Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#001F6B' : fillColor}`}
        />
      </svg>
    </>
  );
};

export default PayPal;
