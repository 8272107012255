// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hoverRevealButton_triggerbtn__ZJPWK {
  height: 2.4375rem;
  width: 7.5rem;
  color: black;
  text-align: center;
  position: relative;
  overflow: hidden;

  background-color: var(--secondary-cyan);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverRevealButton_triggerbtn__ZJPWK:hover .hoverRevealButton_hoverView__TZt82 {
  transform: translateX(0);
}

.hoverRevealButton_triggerbtn__ZJPWK:hover .hoverRevealButton_defaultView__uU-Ov {
  transform: translateX(10rem);
}

.hoverRevealButton_defaultView__uU-Ov {
  transition: transform 0.5s;
  text-transform: capitalize;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverRevealButton_hoverView__TZt82 {
  background-color: var(--secondary-persian-rose);
  transform: translateX(-10rem);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.5s;
  color: white;
  z-index: 20;
}

.hoverRevealButton_primaryBgColor__9qJlA {
  background-color: var(--secondary-persian-rose);
}

.hoverRevealButton_secondaryBgColor__vzVsi {
  background-color: var(--secondary-cyan);
}
`, "",{"version":3,"sources":["webpack://./src/components/Buttons/HoverRevealButton/hoverRevealButton.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;;EAEhB,uCAAuC;EACvC,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,0BAA0B;EAC1B,0BAA0B;;EAE1B,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,+CAA+C;EAC/C,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,0BAA0B;EAC1B,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".triggerbtn {\n  height: 2.4375rem;\n  width: 7.5rem;\n  color: black;\n  text-align: center;\n  position: relative;\n  overflow: hidden;\n\n  background-color: var(--secondary-cyan);\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.triggerbtn:hover .hoverView {\n  transform: translateX(0);\n}\n\n.triggerbtn:hover .defaultView {\n  transform: translateX(10rem);\n}\n\n.defaultView {\n  transition: transform 0.5s;\n  text-transform: capitalize;\n\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.hoverView {\n  background-color: var(--secondary-persian-rose);\n  transform: translateX(-10rem);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  transition: transform 0.5s;\n  color: white;\n  z-index: 20;\n}\n\n.primaryBgColor {\n  background-color: var(--secondary-persian-rose);\n}\n\n.secondaryBgColor {\n  background-color: var(--secondary-cyan);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"triggerbtn": `hoverRevealButton_triggerbtn__ZJPWK`,
	"hoverView": `hoverRevealButton_hoverView__TZt82`,
	"defaultView": `hoverRevealButton_defaultView__uU-Ov`,
	"primaryBgColor": `hoverRevealButton_primaryBgColor__9qJlA`,
	"secondaryBgColor": `hoverRevealButton_secondaryBgColor__vzVsi`
};
export default ___CSS_LOADER_EXPORT___;
