import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type RequiredLoginOpts = {
  hash: string;
};

const useRequireLogin = () => {
  const navigate = useNavigate();

  const handleButtonClick = useCallback(
    (pathname: string, opts?: RequiredLoginOpts) => {
      const { hash } = opts || {};
      const url = hash ? `${pathname}${hash}` : pathname;
      navigate(url);
    },
    [navigate]
  );

  return handleButtonClick;
};

export default useRequireLogin;
