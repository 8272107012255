import React from 'react';
import { IconState } from '../../../types/iconState';
import InstagramColored from './InstagramColored';
import InstagramWhite from './InstagramWhite';

type TwitterProps = {
  state?: IconState;
};

const Instagram = ({ state }: TwitterProps) => {
  return (
    <>
      {state === IconState.Hover || state === IconState.Active ? (
        <InstagramColored />
      ) : (
        <InstagramWhite />
      )}
    </>
  );
};

export default Instagram;
