// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topUpStats_topUpStats__504Wm {
  display: flex;
}

.topUpStats_topUpStats__504Wm div {
  width: 50%;
}

.topUpStats_topUpStats__504Wm .topUpStats_statsHeader__E5C06 {
  font-size: 5rem;
  line-height: 5.10375rem;
  font-weight: 650;
}

.topUpStats_statsContainer__UJoUK {
  display: flex;
  margin-left: 5rem;
}

.topUpStats_statsContainer__UJoUK li {
  width: 16rem;
}

.topUpStats_statsContainer__UJoUK li div:first-child {
  color: var(--primary-orange-yellow);
  font-size: 3.125rem;
  font-weight: 700;
}

.topUpStats_statsContainer__UJoUK li p {
  font-size: 1.5rem;
  max-width: 10ch;
}
`, "",{"version":3,"sources":["webpack://./src/components/TopupSection/TopUpStats/topUpStats.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mCAAmC;EACnC,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".topUpStats {\n  display: flex;\n}\n\n.topUpStats div {\n  width: 50%;\n}\n\n.topUpStats .statsHeader {\n  font-size: 5rem;\n  line-height: 5.10375rem;\n  font-weight: 650;\n}\n\n.statsContainer {\n  display: flex;\n  margin-left: 5rem;\n}\n\n.statsContainer li {\n  width: 16rem;\n}\n\n.statsContainer li div:first-child {\n  color: var(--primary-orange-yellow);\n  font-size: 3.125rem;\n  font-weight: 700;\n}\n\n.statsContainer li p {\n  font-size: 1.5rem;\n  max-width: 10ch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topUpStats": `topUpStats_topUpStats__504Wm`,
	"statsHeader": `topUpStats_statsHeader__E5C06`,
	"statsContainer": `topUpStats_statsContainer__UJoUK`
};
export default ___CSS_LOADER_EXPORT___;
