// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .responsiblePlayer_playerContainer__rVkP7 {
     /* position: relative; */
    margin: 4.625rem auto;
    
    display: flex;
}
.responsiblePlayer_playerCopy__AjSlk {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}


.responsiblePlayer_playerCopy__AjSlk p {
    font-family: var(--font-nunito-sans);
    font-size: 18px;
    max-width: 100ch;
    color: #d4d4d4;
    font-weight: 380;
    line-height: 1.700rem;
    text-align: justify;
}

.responsiblePlayer_playerTitle__FTG5D{
    margin-top: 2rem;
    font-size: 30px;
    font-family: var(--font-rajdhani);
    color: var(--primary-orange-yellow);
    font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/GamingGuidelines/ResponsiblePlayer/responsiblePlayer.module.css"],"names":[],"mappings":"CAAC;KACI,wBAAwB;IACzB,qBAAqB;;IAErB,aAAa;AACjB;AACA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;AACtB;;;AAGA;IACI,oCAAoC;IACpC,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iCAAiC;IACjC,mCAAmC;IACnC,gBAAgB;AACpB","sourcesContent":[" .playerContainer {\n     /* position: relative; */\n    margin: 4.625rem auto;\n    \n    display: flex;\n}\n.playerCopy {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: start;\n}\n\n\n.playerCopy p {\n    font-family: var(--font-nunito-sans);\n    font-size: 18px;\n    max-width: 100ch;\n    color: #d4d4d4;\n    font-weight: 380;\n    line-height: 1.700rem;\n    text-align: justify;\n}\n\n.playerTitle{\n    margin-top: 2rem;\n    font-size: 30px;\n    font-family: var(--font-rajdhani);\n    color: var(--primary-orange-yellow);\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playerContainer": `responsiblePlayer_playerContainer__rVkP7`,
	"playerCopy": `responsiblePlayer_playerCopy__AjSlk`,
	"playerTitle": `responsiblePlayer_playerTitle__FTG5D`
};
export default ___CSS_LOADER_EXPORT___;
