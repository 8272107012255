import React, { type ReactNode } from 'react';
import styles from './hoverRevealButton.module.css';

type HoverRevealButtonProps = {
  children: ReactNode;
  onClick: () => void;
};

const HoverRevealButton = ({ children, onClick }: HoverRevealButtonProps) => {
  return (
    <button className={styles.triggerbtn} onClick={onClick}>
      {children}
    </button>
  );
};

export default HoverRevealButton;
