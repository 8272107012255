/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import styles from './acceptanceTerms.module.css'

const AcceptanceTerms = () => {
    return (
        <div className={styles.sectionWrapper}>
            <div className={`${styles.playerContainer} container`}>
                <div className={styles.playerCopy}>

                    {/* Acceptance of terms */}
                    <h1 className={styles.playerTitle}>ACCEPTANCE OF TERMS</h1>
                    <p>
                        By accessing and using ScratchMania application, you agree to abide by these terms and conditions. If you do not agree with any part of
                        these terms, please refrain from using our services. We reserve the right to modify these terms and conditions at any time. Users will be
                        promptly notified of changes through in-app push notifications, and continued use of the platform constitutes acceptance of the modified
                        terms.
                    </p>
                    <br />
                    <p>
                        These terms and conditions are governed by and construed in accordance with the laws of the Republic of the Philippines. International
                        users are subject to local laws where applicable.
                    </p>
                    {/* ELIGIBILITY */}
                    <h1 className={styles.playerTitle}>ELIGIBILITY</h1>
                    <p>
                        Users must be 18 years old and above to participate in gaming activities on this platform. Minors are not allowed to use this platform.
                    </p>
                    {/* ACCOUNT REGISTRATION and DATA PRIVACY */}
                    <h1 className={styles.playerTitle}>ACCOUNT REGISTRATION and DATA PRIVACY</h1>
                    <p>
                        Users must create an account to access the mobile application. All information provided during registration must be accurate and complete.
                        Users are responsible for maintaining the confidentiality of their account credentials.
                    </p>
                    <br />
                    <p>
                        Users must create an account to access the mobile application. All information provided during registration must be accurate and complete.
                        Users are responsible for maintaining the confidentiality of their account credentials.
                    </p>
                    <p>
                        In compliance with Republic Act 10173 - Data Privacy Act of 2012, we are committed to protecting your personal information. Your data
                        will be used only in accordance with our Privacy Policy, which explains your rights regarding your personal data and outlines our data
                        protection and privacy measures.
                    </p>
                    {/*DEPOSITS and WITHDRAWALS  */}
                    <h1 className={styles.playerTitle}>DEPOSITS and WITHDRAWALS</h1>
                    <p>
                        Users may deposit funds into their accounts using preferred payment methods. Withdrawals are subject to verification processes and may
                        be subject to fees and processing times of their chosen payment gateway.
                    </p>
                    <ul className={styles.unorderedList}>
                        <li>Minimum Top-up is Fifty Pesos (Php 50) per transaction;</li>
                        <li>There is no Maximum Top-up amount daily;</li>
                        <br />
                        <li>Minimum Cash-out is One Hundred Pesos (Php 100) per transaction;</li>
                        <li>Maximum Cash-out is Fifty Thousand Pesos (Php 50,000) daily;</li>
                        <br />
                        <li>Day starts at 12:00 AM and ends at 11:59 PM Philippine Time</li>
                    </ul>
                    <br />
                    <p>
                        Transaction and Wallet balance limits may be changed by ScratchMania at its option pursuant to applicable laws.
                        The Maximum capacity of the ScratchMania Wallet is Three Million Pesos (Php 3,000,000.00) Only.
                    </p>
                    {/* RESPONSIBLE GAMING */}
                    <h1 className={styles.playerTitle}>RESPONSIBLE GAMING</h1>
                    <p>
                        We promote responsible gaming. For assistance and resources on responsible gaming, please visit www.ScratchMania.com for more details.
                    </p>
                    {/* PRIVACY POLICY */}
                    <h1 className={styles.playerTitle}>PRIVACY POLICY</h1>
                    <p>
                        We consider your privacy with utmost importance and we have a policy and set of guidelines in place which would ensure that your information is handled properly. By agreeing to these terms of use, you agree to the provisions of ScratchMania8’s Privacy Policy.
                        For more details please click this link - <span className={styles.privacyLink}>Privacy Policy.</span>
                    </p>
                    {/* GAME USAGE */}
                    <h1 className={styles.playerTitle}>GAME USAGE</h1>
                    <p>
                        Users agree not to engage in fraudulent, illegal, or abusive activities, including cheating, collusion, or using automated programs.
                        Such violations may result in account suspension or termination.
                    </p>
                    <br />
                    <p>
                        All content, trademarks, and intellectual property on the platform are owned by the platform and its licensors. Users are granted a limited
                        license to access and use the platform for personal, non-commercial purposes in accordance with these terms.
                    </p>
                    {/* ACCOUNT STATUS */}
                    <h1 className={styles.playerTitle}>ACCOUNT STATUS</h1>
                    <p>
                        We reserve the right to suspend or terminate user accounts at our discretion for violations of these terms or applicable laws. Users will be
                        notified of the reasons for such actions and may contact our hotline for further information.
                    </p>
                    {/* LIMITATION OF LIABILITIES */}
                    <h1 className={styles.playerTitle}>LIMITATION OF LIABILITIES</h1>
                    <p>
                        ScratchMania8 explicitly disclaims any liability for damages and/or losses to an Account Holder that may arise directly or indirectly due to a
                        wide] array of occurrences. These include, but are not limited to:
                    </p>
                    <ul className={styles.unorderedList}>
                        <li>Any form of error such as mistakes, misprints, misinterpretations, misreadings, mistranslations, spelling mistakes, faults in reading,
                            and transaction errors.</li>
                        <li>Technical failures or hazards including but not limited to the failure of ScratchMania Card's central computer system or any part
                            thereof; delays, losses, errors, or omissions resulting from the failure of any telecommunications or other data transmission systems</li>
                        <li>Financial risks and losses which may arise, including variances in exchange rates and any financial instability resulting from the use of the service.</li>
                        <li>The inability of a player to perform or complete transactions through the use of payment gateways due to service, system, or line
                            unavailability.</li>
                        <li>Any delays, interruptions, or terminations of ScratchMania transactions caused by administrative errors, technical, mechanical,
                            electrical, or electronic faults or difficulties or any other reasons or circumstances beyond ScratchMania Card's control. This includes,
                            but is not limited to, acts of God, strikes, labor disputes, fires, disturbances, governmental actions, atmospheric conditions, lightning,
                            interference or damages by third parties, or any changes in legislation.</li>
                        <li>Theft or unauthorized use of ScratchMania balance and gems, or any loss, costs, damages caused by the actions of an ScratchMania account holder</li>
                    </ul>
                    <br />
                    <p>
                        Any misrepresentation, fraud, or misconduct by any third party, including but not limited to owners, employees, or ScratchMania staff.
                        In the event of any legal action initiated by an Account Holder against ScratchMania8, it is agreed that the liability of ScratchMania Card
                        shall not exceed TWO THOUSAND PESOS (PHP2,000.00) or the amount of the damages actually suffered by the Account Holder, as
                        judicially determined with finality in favor of the Account Holder, whichever is higher.
                    </p>
                    {/* LIMITATION OF LIABILITIES */}
                    <h1 className={styles.playerTitle}>LIMITATION OF LIABILITIES</h1>
                    <p>
                        ScratchMania8 provides the Service on an "as is" and "as available" basis without making any warranties, either express or implied, except
                        those that cannot be legally excluded under applicable law. This means that ScratchMania8 does not guarantee the Service will meet your
                        requirements or expectations, nor does it guarantee uninterrupted, timely, secure, or error-free operation of the Service
                    </p>
                    <br />
                    <strong className={styles.scratchList}>Specifically, ScratchMania8 does not warrant that:</strong>
                    <ul className={styles.unorderedList}>
                        <li>The Service, including the App and all content contained within, will be free from interruptions, secure, or error-free.</li>
                        <li>Any defects or errors in the Service or the App will be corrected.</li>
                        <li>The quality, accuracy, or reliability of any data, information, or content obtained through the App will meet your expectations or be free from mistakes, errors, or defects.</li>
                    </ul>
                    <br />
                    <p>
                        ScratchMania8 is not responsible for any content that you, another user, or a third party creates, posts, sends, or receives through the App.
                        Any material downloaded or otherwise obtained through the use of the Service is done at your own discretion and risk, and you will be
                        solely responsible for any damage or loss of data that results from the download of any such material.
                    </p>
                    <br />
                    <p>
                        Furthermore, except as expressly stated in these Terms and Conditions, ScratchMania8 disclaims all warranties and representations,
                        whether express or implied, to the fullest extent permitted by law, in respect of the Service. This includes, but is not limited to, warranties of
                        merchantability, fitness for a particular purpose, non-infringement, compatibility, security, and accuracy regarding the Service.
                    </p>
                    {/* DISPUTES AND ERRONEOUS TRANSACTIONS */}
                    <h1 className={styles.playerTitle}>DISPUTES AND ERRONEOUS TRANSACTIONS</h1>
                    <p>
                        ScratchMania Card is committed to ensuring the accuracy and integrity of all transactions made within the platform. However, in the event
                        that an Account Holder believes there has been a discrepancy, error, or any issue related to their transactions, the following process should
                        be followed to address and resolve such disputes:
                    </p>
                    <ul className={styles.alphabeticalList}>
                        <li><strong>Notification:</strong> Account Holders must report any disputed transactions or errors to ScratchMania Card within five (5) days from the
                            transaction date, providing all relevant details and documentation to support their claim.</li>
                        <li><strong>Investigation:</strong> Upon receiving a dispute notice, ScratchMania Card will initiate an investigation, reviewing transaction logs, account
                            activities, and other pertinent information to verify the claim.</li>
                        <li><strong>Resolution:</strong> If the investigation determines the dispute is valid, ScratchMania Card will adjust the Account Holder's balance, issue
                            refunds, or take other corrective actions as necessary to rectify the issue.</li>
                        <li><strong>Communication:</strong> Throughout the process, ScratchMania Card will keep the Account Holder informed of the investigation's status and resolution.</li>
                        <li><strong>Final Decision:</strong> The decision made by ScratchMania Card after the investigation will be considered final and binding.</li>
                    </ul>
                    <br />
                    <p>
                        This streamlined process ensures disputes and erroneous transactions are resolved promptly and fairly, maintaining trust and transparency between ScratchMania Card and its users.
                    </p>
                    {/* NON-WAIVER OF RIGHTS */}
                    <h1 className={styles.playerTitle}>NON-WAIVER OF RIGHTS</h1>
                    <p>
                        Failure, omission, or delay on the part of ScratchMania to exercise its rights or remedies under these Terms and Conditions shall not operate
                        as a waiver of the same. Any such waiver shall be valid only when reduced in writing and delivered to the Account Holder.
                    </p>
                    {/* SEPARABILITY CLAUSE */}
                    <h1 className={styles.playerTitle}>SEPARABILITY CLAUSE</h1>
                    <p>
                        Should any term or condition in this Agreement be rendered void, illegal or unenforceable in any respect under any law, the validity, legality
                        and enforceability of the remaining terms and conditions shall not be affected or impaired thereby.
                    </p>
                    {/* CUSTOMER COMPLAINTS HANDLING PROCESS */}
                    <h1 className={styles.playerTitle}>CUSTOMER COMPLAINTS HANDLING PROCESS</h1>
                    <p>
                        We are committed to addressing all customer complaints efficiently. Our aim is to resolve any issues within a 10-day timeframe. If a 
                        resolution requires more time, we pledge to keep you informed of our progress until a satisfactory outcome is reached. Your concerns are our 
                        priority, and we strive to resolve them promptly.
                    </p>
                    {/*DISPUTE RESOLUTION  */}
                    <h1 className={styles.playerTitle}>DISPUTE RESOLUTION</h1>
                    <p>
                        Any disputes arising from the use of our services shall be resolved through arbitration in accordance with Republic 
                        Act 876 and Republic Act 9285. Users waive their right to pursue class-action lawsuits. 
                    </p>
                    {/* SERVICE AVAILABILITY */}
                    <h1 className={styles.playerTitle}>SERVICE AVAILABILITY</h1>
                    <p>
                        While we strive to ensure the platform is available at all times, we cannot guarantee uninterrupted service. We will endeavor to inform 
                        users of any significant downtime.
                    </p>
                    {/* TERMINATION */}
                    <h1 className={styles.playerTitle}>TERMINATION</h1>
                    <p>
                        Users may terminate their agreement with the service at any time. Upon termination, your account will be deactivated, and any outstanding 
                        balance may be withdrawn subject to our standard withdrawal procedures.
                    </p>
                    <br />
                    <br/>
                    <p>
                        <em>
                            *** These Terms of Use are subject to changes, revisions, updates, and amendments from time to time without the need of prior notice or consent of the user.
                        </em>
                    </p>
                </div>
            </div>
        </div>

    )
}

export default AcceptanceTerms
