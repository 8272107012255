// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browseByCategory_browseByCategory__59s7y {
  margin: 0 auto;
}

.browseByCategory_browseByCategory__59s7y h2 {
  margin-bottom: 1.3125rem;
}

.browseByCategory_displayGameByCategory__-NHr3 {
  display: flex;
  column-gap: 1.875rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/BrowseByCategory/browseByCategory.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB","sourcesContent":[".browseByCategory {\n  margin: 0 auto;\n}\n\n.browseByCategory h2 {\n  margin-bottom: 1.3125rem;\n}\n\n.displayGameByCategory {\n  display: flex;\n  column-gap: 1.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"browseByCategory": `browseByCategory_browseByCategory__59s7y`,
	"displayGameByCategory": `browseByCategory_displayGameByCategory__-NHr3`
};
export default ___CSS_LOADER_EXPORT___;
