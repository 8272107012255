import React from 'react';
import classNames from 'classnames';
import globalStyle from '../../../global.module.css';
import styles from './newsLetter.module.css';
import ArrowRight from '../../Icons/ArrowRight/ArrowRight';

const NewsLetter = () => {
  return (
    <div className={styles.newsLetter}>
      <p className={'sectionTitle'}>NEWSLETTER</p>
      <h2 className={styles.header}>Join our Newsletter</h2>
      <p className={styles.description}>
        Stay updated with the latest news, promotions, and exciting updates from
        ScratchMania. Sign up now and never miss out!
      </p>
      <form className={styles.form} method="POST">
        <input
          type="email"
          className={styles.emailInput}
          placeholder="Email Address"
        />

        <button
          className={classNames(styles.subscribeBtn, globalStyle.btnIconReveal)}
          type="submit">
          <span>subscribe</span>
          <ArrowRight className={globalStyle.icon} />
        </button>
      </form>
    </div>
  );
};

export default NewsLetter;
