// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerAbout_footerAbout__uDKY5 {
  max-width: 18.75rem;
}

.footerAbout_description__b30Ck {
  margin: 2rem 0;

  font-family: var(--font-nunito-sans);
  font-weight: 250;
  font-size: 1.125rem;
  line-height: 1.534rem;
}

.footerAbout_socialMediaIcons__PJIOi {
  height: 1.5rem;
  display: flex;
}

.footerAbout_socialMediaIcons__PJIOi li {
  margin-right: 1rem;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/FooterSection/FooterAbout/footerAbout.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;;EAEd,oCAAoC;EACpC,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".footerAbout {\n  max-width: 18.75rem;\n}\n\n.description {\n  margin: 2rem 0;\n\n  font-family: var(--font-nunito-sans);\n  font-weight: 250;\n  font-size: 1.125rem;\n  line-height: 1.534rem;\n}\n\n.socialMediaIcons {\n  height: 1.5rem;\n  display: flex;\n}\n\n.socialMediaIcons li {\n  margin-right: 1rem;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerAbout": `footerAbout_footerAbout__uDKY5`,
	"description": `footerAbout_description__b30Ck`,
	"socialMediaIcons": `footerAbout_socialMediaIcons__PJIOi`
};
export default ___CSS_LOADER_EXPORT___;
