import React from 'react';

const EmailIconYellow = () => {
  return (
    <div>
      <svg
        width="38"
        height="39"
        viewBox="0 0 38 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M34.8371 14.9442V29.2259C34.8371 30.9676 33.4121 32.3926 31.6704 32.3926H6.33708C4.59541 32.3926 3.17041 30.9676 3.17041 29.2259V10.2259C3.17041 8.48424 4.59541 7.05924 6.33708 7.05924H22.3287C22.2337 7.56591 22.1704 8.10424 22.1704 8.64258C22.1704 10.9859 23.1996 13.0601 24.8146 14.5167L19.0037 18.1426L6.33708 10.2259V13.3926L19.0037 21.3092L27.3954 16.0526C28.2504 16.3692 29.1371 16.5592 30.0871 16.5592C31.8762 16.5592 33.5071 15.9417 34.8371 14.9442ZM25.3371 8.64258C25.3371 11.2709 27.4587 13.3926 30.0871 13.3926C32.7154 13.3926 34.8371 11.2709 34.8371 8.64258C34.8371 6.01424 32.7154 3.89258 30.0871 3.89258C27.4587 3.89258 25.3371 6.01424 25.3371 8.64258Z"
          fill="#F9CF31"
        />
      </svg>
    </div>
  );
};

export default EmailIconYellow;
