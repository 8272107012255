// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner_contactUsBanner__DkY-7 {
  margin: 4.625rem auto;
  display: flex;
}

.banner_contactUsTitle__qiLXS {
  font-family: var(--font-rajdhani);
  font-size: 5.625rem;
  line-height: 5.625rem;
  font-weight: 600;
}

.banner_contactUsCopy__3Ka7B {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.banner_heroSvg__KFEcr {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.banner_description__9DSVH {
  font-family: var(--font-nunito-sans);
  font-size: 1.18rem;
  max-width: 55ch;
  color: #d4d4d4;
  font-weight: 350;
  line-height: 1.7rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ContactUs/Banner/banner.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".contactUsBanner {\n  margin: 4.625rem auto;\n  display: flex;\n}\n\n.contactUsTitle {\n  font-family: var(--font-rajdhani);\n  font-size: 5.625rem;\n  line-height: 5.625rem;\n  font-weight: 600;\n}\n\n.contactUsCopy {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: start;\n}\n\n.heroSvg {\n  width: 50%;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.description {\n  font-family: var(--font-nunito-sans);\n  font-size: 1.18rem;\n  max-width: 55ch;\n  color: #d4d4d4;\n  font-weight: 350;\n  line-height: 1.7rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactUsBanner": `banner_contactUsBanner__DkY-7`,
	"contactUsTitle": `banner_contactUsTitle__qiLXS`,
	"contactUsCopy": `banner_contactUsCopy__3Ka7B`,
	"heroSvg": `banner_heroSvg__KFEcr`,
	"description": `banner_description__9DSVH`
};
export default ___CSS_LOADER_EXPORT___;
