// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactDetails_root__zKbnT {
  max-width: 57.5rem;
  margin: 0 auto;
}

.contactDetails_contactDetailsList__9IXtg {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
}

.contactDetails_contactDetail__tk8Sv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.625em;
}

.contactDetails_label__JeyMZ {
  font-family: var(--font-nunito-sans);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  color: rgba(255, 255, 255, 1);

  margin-top: 1em;
}

.contactDetails_value__ZrtjD {
  font-family: var(--font-nunito-sans);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: rgba(212, 212, 212, 1);
}

.contactDetails_contactDetail__tk8Sv:nth-child(1),
.contactDetails_contactDetail__tk8Sv:nth-child(2) {
  border-right: 1px solid rgba(249, 207, 49, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/ContactUs/ContactDetails/contactDetails.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,qCAAqC;EACrC,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,6BAA6B;;EAE7B,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;;EAEE,+CAA+C;AACjD","sourcesContent":[".root {\n  max-width: 57.5rem;\n  margin: 0 auto;\n}\n\n.contactDetailsList {\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-template-rows: 1fr;\n}\n\n.contactDetail {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  padding: 0.625em;\n}\n\n.label {\n  font-family: var(--font-nunito-sans);\n  font-size: 1.25rem;\n  font-weight: 700;\n  line-height: 1.5;\n  color: rgba(255, 255, 255, 1);\n\n  margin-top: 1em;\n}\n\n.value {\n  font-family: var(--font-nunito-sans);\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.6;\n  color: rgba(212, 212, 212, 1);\n}\n\n.contactDetail:nth-child(1),\n.contactDetail:nth-child(2) {\n  border-right: 1px solid rgba(249, 207, 49, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `contactDetails_root__zKbnT`,
	"contactDetailsList": `contactDetails_contactDetailsList__9IXtg`,
	"contactDetail": `contactDetails_contactDetail__tk8Sv`,
	"label": `contactDetails_label__JeyMZ`,
	"value": `contactDetails_value__ZrtjD`
};
export default ___CSS_LOADER_EXPORT___;
