/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import styles from './PurposePolicy.module.css'

const PurposePolicy = () => {
    return (
        <div className={styles.sectionWrapper}>
            <div className={`${styles.playerContainer} container`}>
                <div className={styles.playerCopy}>
                    <p>
                        Your privacy is important to us. We are committed to protecting your personal information in compliance with the Data Privacy Act of 2012
                        (Republic Act 10173) and adhering to international best practices.
                    </p>
                    <h1 className={styles.playerTitle}>PURPOSE OF THIS POLICY</h1>
                    <p>
                        This policy informs you about how we manage your personal information. We outline the collection, use, sharing, storage, deletion, and
                        protection of your information, and your rights to control your personal data.
                    </p>
                    <h1 className={styles.playerTitle}>WHAT is ScratchMania?</h1>
                    <p>
                        ScratchMania8 is a digital scratch card game offering a diverse selection of cards and prizes.
                    </p>
                    <h1 className={styles.playerTitle}>WHY DO WE NEED YOUR PERSONAL INFORMATION?</h1>
                    <ol className={styles.orderedList}>
                        <li><strong>Identity Confirmation (KYC Information):</strong> For legal compliance and transaction monitoring, we collect KYC Information to prevent money laundering.</li>
                        <li><strong>Personalized Gaming Experience:</strong> We analyze your information to tailor your gaming experience.</li>
                        <li><strong>Product and Market Research:</strong> To innovate and cater to your entertainment needs.</li>
                        <li><strong>Risk Management and Fraud Prevention:</strong> To protect our services and your account, we analyze transaction histories and other pertinent information.</li>
                        <li><strong>Enhanced Customer Service:</strong> Our Customer Experience Team collects information to better assist you.</li>
                        <li><strong>App Performance Improvement:</strong> Analyzing app usage patterns helps us enhance service quality.</li>
                        <li><strong>Communication:</strong> We use your information for updates, alerts, and other relevant communications.</li>
                        <li><strong>Targeted Advertising:</strong> Your information is used for personalized advertising.</li>
                    </ol>
                    <h1 className={styles.playerTitle}>TYPES OF INFORMATION WE COLLECT AND RETENTION</h1>
                    <ol className={styles.orderedList}>
                        <li><strong>Identification Information:</strong> Includes personal details, collected as per Bangko Sentral ng Pilipinas (BSP) regulations, retained for 5 years post the last transaction or account closure.</li>
                        <li><strong>Financial Information:</strong> Collected for transactions, retained for 5 years post the last transaction or account closure.</li>
                        <li><strong>Third-Party Information:</strong> Varies in retention based on the purpose.</li>
                        <li><strong>Device and App Use Information:</strong> Retained for 90 days in active records, up to 3 years in backup records.</li>
                        <li><strong>Geolocation Information:</strong> Retained up to 10 years for ongoing investigations or as per court order.</li>
                        <li><strong>Contact Information:</strong> Retained for 5 years post the last transaction or account closure.</li>
                        <li><strong>Online or Network Activity Information:</strong> Retained as per device and app use information.</li>
                        <li><strong>Social Web Information:</strong> Retained for 5 years post the last transaction or account closure.</li>
                        <li><strong>Advertising Unique Identifiers:</strong> Retained as long as the ScratchMania8 App is installed.</li>
                    </ol>
                    <h1 className={styles.playerTitle}>DATA COLLECTION METHODS</h1>
                    <ol className={styles.orderedList}>
                        <li><strong>Direct Interactions:</strong> When you create an account, use our services, or contact us.</li>
                        <li><strong>Via Our App and Website:</strong> When using our services online.</li>
                        <li><strong>Through Various Channels:</strong> Including online forms and app pages.</li>
                        <li><strong>From Our Social Media Platforms:</strong> When you interact with us on social media.</li>
                        <li><strong>By Granting Permissions:</strong> On your mobile devices for service improvement.</li>
                    </ol>
                    <h1 className={styles.playerTitle}>WHO WE SHARE YOUR INFORMATION WITH</h1>
                    <ol className={styles.orderedList}>
                        <li><strong>Financial Institutions and Agencies:</strong> For identity verification and fraud prevention.</li>
                        <li><strong>Service Providers:</strong> To assist in delivering seamless services.</li>
                        <li><strong>Fraud and Crime Prevention Partners:</strong> To protect against fraud.</li>
                        <li><strong>Law Enforcement and Regulatory Bodies:</strong> As required by law.</li>
                    </ol>
                    <h1 className={styles.playerTitle}>CHANGES TO YOUR PERSONAL INFORMATION</h1>
                    <p>
                        Notify us of any changes to ensure accuracy and security.
                    </p>
                    <h1 className={styles.playerTitle}>DATA SECURITY MEASURES</h1>
                    <ol className={styles.orderedList}>
                        <li><strong>Encryption:</strong> Your personal data is encrypted during storage and transmission.</li>
                        <li><strong>Multi-factor Authentication (MFA):</strong>  Implemented for enhanced account security.</li>
                        <li><strong>Regular Security Audits:</strong> To ensure the integrity of our information systems.</li>
                    </ol>
                    <h1 className={styles.playerTitle}>YOUR DATA PRIVACY RIGHTS</h1>
                    <ol className={styles.orderedList}>
                        <li><strong>Access, Object, and Rectification:</strong> You can access, object to processing, and rectify your data.</li>
                        <li><strong>Erasure and Blocking:</strong> Request deletion or blocking of your data.</li>
                        <li><strong>Damages:</strong> Claim compensation for privacy rights violations.</li>
                        <li><strong>File a Complaint:</strong> With the National Privacy Commission for misuse of your data.</li>
                        <li><strong>Data Portability: </strong> Transfer your data securely to another entity.</li>
                    </ol>
                    <h1 className={styles.playerTitle}>EXERCISING YOUR DATA PRIVACY RIGHTS</h1>
                    <p>
                        Reach out to our dedicated player support team via the Help Center feature within the app for any requests or concerns regarding your data.
                    </p>
                    <h1 className={styles.playerTitle}>POLICY UPDATES</h1>
                    <p>
                        This policy is regularly updated. We encourage you to review it periodically for the latest information on our privacy practices.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PurposePolicy
