// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newsLetter_newsLetter__\\+5Wev {
  max-width: 27.5rem;
}

.newsLetter_header__thqKl {
  font-size: 2.25rem;
  line-height: 2.25rem;

  margin: 0.5rem 0;
}

.newsLetter_description__j\\+oQN {
  max-width: 43ch;
  font-size: 1.125rem;
  font-family: var(--font-nunito-sans);
  font-weight: 250;
  line-height: 1.534rem;
}

input.newsLetter_emailInput__LjnxG {
  border-radius: 3px;
  background-color: white;
  padding: 1rem;

  color: #0c0324;
  font-family: var(--font-montserrat);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  text-align: left;
  border: none;
  border-color: none;
}

input.newsLetter_emailInput__LjnxG::placeholder {
  color: #0c0324;
}

input[type='text'].newsLetter_emailInput__LjnxG:focus-visible {
  border-color: unset;
  border: none;
}

.newsLetter_subscribeBtn__Fk7j\\+ {
  background-color: rgb(245, 41, 161);
  width: 10.3125rem;

  padding: 16px 32px;
  line-height: 1.625rem;

  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
}

.newsLetter_form__UsI-C {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FooterSection/NewsLetter/newsLetter.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;;EAEpB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,oCAAoC;EACpC,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;;EAEb,cAAc;EACd,mCAAmC;EACnC,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mCAAmC;EACnC,iBAAiB;;EAEjB,kBAAkB;EAClB,qBAAqB;;EAErB,0BAA0B;;EAE1B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX","sourcesContent":[".newsLetter {\n  max-width: 27.5rem;\n}\n\n.header {\n  font-size: 2.25rem;\n  line-height: 2.25rem;\n\n  margin: 0.5rem 0;\n}\n\n.description {\n  max-width: 43ch;\n  font-size: 1.125rem;\n  font-family: var(--font-nunito-sans);\n  font-weight: 250;\n  line-height: 1.534rem;\n}\n\ninput.emailInput {\n  border-radius: 3px;\n  background-color: white;\n  padding: 1rem;\n\n  color: #0c0324;\n  font-family: var(--font-montserrat);\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.625rem;\n  text-align: left;\n  border: none;\n  border-color: none;\n}\n\ninput.emailInput::placeholder {\n  color: #0c0324;\n}\n\ninput[type='text'].emailInput:focus-visible {\n  border-color: unset;\n  border: none;\n}\n\n.subscribeBtn {\n  background-color: rgb(245, 41, 161);\n  width: 10.3125rem;\n\n  padding: 16px 32px;\n  line-height: 1.625rem;\n\n  text-transform: capitalize;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.form {\n  margin-top: 2rem;\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newsLetter": `newsLetter_newsLetter__+5Wev`,
	"header": `newsLetter_header__thqKl`,
	"description": `newsLetter_description__j+oQN`,
	"emailInput": `newsLetter_emailInput__LjnxG`,
	"subscribeBtn": `newsLetter_subscribeBtn__Fk7j+`,
	"form": `newsLetter_form__UsI-C`
};
export default ___CSS_LOADER_EXPORT___;
