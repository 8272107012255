import React, { useEffect } from 'react';
import styles from './signUp.module.css';
import authStyle from '../auth.module.css';
import globalStyle from '../../../../global.module.css';
import { AuthType } from '../Auth';
import classNames from 'classnames';
import ArrowRight from '../../../Icons/ArrowRight/ArrowRight';

type SignUpProps = {
  isActive: boolean;
  handleChangeActiveAuth: (authType: AuthType) => void;
};

const SignUp = ({ isActive, handleChangeActiveAuth }: SignUpProps) => {
  return (
    <>
      <img className={authStyle.logo} src="./logo.png" alt="" />

      <div className={styles.authHeader}>
        <div className={`${authStyle.authCta}`}>
          <button
            onClick={() => handleChangeActiveAuth(AuthType.Login)}
            className={classNames({
              [authStyle.activeButton]: !isActive
            })}>
            Login
          </button>
          <button
            onClick={() => handleChangeActiveAuth(AuthType.Register)}
            className={classNames({
              [authStyle.activeButton]: isActive
            })}>
            Register
          </button>
        </div>

        <h3 className={authStyle.header}>Create Your Account</h3>
        <p className={authStyle.description}>
          Join the ScratchMania community & start playing for your chance to win
          big! Just fill in your details below to get started.
        </p>
      </div>

      <form className={authStyle.form} action="">
        <input
          type="email"
          placeholder="Email Address"
          className={authStyle.inputField}
        />
        <input
          type="text"
          placeholder="Mobile Number"
          className={authStyle.inputField}
        />
        <input
          type="text"
          placeholder="First Name"
          className={authStyle.inputField}
        />
        <input
          type="text"
          placeholder="Last Name"
          className={authStyle.inputField}
        />
        <input
          type="passwords"
          placeholder="Create Password"
          className={authStyle.inputField}
        />
        <button
          type="submit"
          className={classNames(
            authStyle.formSubmitBtn,
            globalStyle.btnIconReveal
          )}>
          <span>Create Account</span>
          <ArrowRight className={globalStyle.icon} />
        </button>
      </form>
    </>
  );
};

export default SignUp;
