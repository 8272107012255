import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import router from './router/router';

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error("Root element '#root' not found in the DOM");
}
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const handlePerformanceEntry = (entry: any) => {};

reportWebVitals(handlePerformanceEntry);
