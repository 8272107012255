// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactUs_root__7NPkR {
  max-width: 70.4375rem;
  margin: 0 auto;
}

.contactUs_contentWrapper__5maRC {
  width: 100%;
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ContactUs/contactUs.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":[".root {\n  max-width: 70.4375rem;\n  margin: 0 auto;\n}\n\n.contentWrapper {\n  width: 100%;\n  margin-top: 7.5rem;\n  margin-bottom: 7.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `contactUs_root__7NPkR`,
	"contentWrapper": `contactUs_contentWrapper__5maRC`
};
export default ___CSS_LOADER_EXPORT___;
