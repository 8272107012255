import axios from './axiosConfig';
import { LOGIN } from './endpoints';

export const login = async (username: string, password: string) => {
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  //   todo: fix this static client-id
  params.append('client_id', '9c2cbfbe-72fa-47db-a370-858112971234');

  const response = await axios.post(LOGIN, params);
  return response.data;
};
