import React from 'react';

const GalaSearch = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7" clipPath="url(#clip0_391_954)">
          <path
            d="M15.6498 13.6154L22.041 20.0067"
            stroke="#F9CF31"
            strokeWidth="2"
          />
          <path
            d="M13.5687 15.6965L19.96 22.0878"
            stroke="#F9CF31"
            strokeWidth="2"
          />
          <path
            d="M19.9599 22.0878C20.2359 22.3638 20.6102 22.5188 21.0005 22.5188C21.3907 22.5188 21.765 22.3638 22.041 22.0878"
            stroke="#F9CF31"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.0407 20.0067C22.3166 20.2827 22.4717 20.657 22.4717 21.0473C22.4717 21.4376 22.3166 21.8119 22.0407 22.0878"
            stroke="#F9CF31"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.43845 8.10786C1.43845 8.97749 1.60974 9.83859 1.94253 10.642C2.27532 11.4455 2.7631 12.1755 3.37802 12.7904C3.99293 13.4053 4.72295 13.8931 5.52637 14.2259C6.3298 14.5587 7.19091 14.7299 8.06053 14.7299C8.93016 14.7299 9.79127 14.5587 10.5947 14.2259C11.3981 13.8931 12.1281 13.4053 12.7431 12.7904C13.358 12.1755 13.8458 11.4455 14.1785 10.642C14.5113 9.83859 14.6826 8.97749 14.6826 8.10786C14.6826 6.35158 13.9849 4.66722 12.7431 3.42534C11.5012 2.18346 9.81682 1.48578 8.06053 1.48578C7.19091 1.48578 6.3298 1.65706 5.52637 1.98985C4.72295 2.32265 3.99293 2.81042 3.37802 3.42534C2.7631 4.04026 2.27532 4.77027 1.94253 5.5737C1.60974 6.37713 1.43845 7.23824 1.43845 8.10786Z"
            stroke="#F9CF31"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.6494 13.6154L13.5683 15.6965"
            stroke="#F9CF31"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6084 14.656L12.7428 12.7904"
            stroke="#F9CF31"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.4258 15.3918L15.3447 17.4729"
            stroke="#F9CF31"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.38161 8.10785C4.38161 9.08356 4.76921 10.0193 5.45915 10.7092C6.14908 11.3992 7.08483 11.7868 8.06055 11.7868"
            stroke="#F9CF31"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_391_954">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="matrix(-1 0 0 1 24 0)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default GalaSearch;
