import React from 'react';
import classNames from 'classnames';
import globalStyle from '../../../global.module.css';
import style from './topCard.module.css';
import ArrowRight from '../../Icons/ArrowRight/ArrowRight';

type TopCardProps = {
  name: string;
  rating: number;
  count: number;
  thumbnail: string;
};

const TopCard = ({ name, rating, count, thumbnail }: TopCardProps) => {
  return (
    <div className={style.topCard}>
      <div>
        <img src={thumbnail} alt="" />
      </div>
      <div className={style.content}>
        <p className={style.name}>{name}</p>
        <img src="./star.png" alt="" />
        <p className={style.count}>Current # count</p>

        <button
          className={classNames(style.playNowBtn, globalStyle.btnIconReveal)}>
          <span>play now</span>
          <ArrowRight className={globalStyle.icon} />
        </button>
      </div>
    </div>
  );
};

export default TopCard;
