import React from 'react';
import styles from './FAQSection.module.css';

import Accordion from './Accordion/Accordion';
import accordionData from './FAQSectionAccordionData.json';

const FAQsection = () => {
  return (
    <div className={`${styles.faqContainer} container`}>
      <div className={styles.faqSection}>
        <div className={styles.faqHeader}>
          <p className={'sectionTitle'}>FAQ</p>
          <h2 className={styles.faqTitle}>Quick Answers</h2>
          <p className={styles.faqDescription}>
            Got questions about ScratchMania? We’ve got answers! Check out our
            <span className={styles.faqLink}> FAQ </span>
            to find quick and helpful information on how to get started, play,
            and win.
          </p>
        </div>
        <div className={styles.accordionWrapper}>
          <Accordion items={accordionData} />
        </div>
      </div>
    </div>
  );
};

export default FAQsection;
