import React, { useState } from 'react';
import styles from './footerAbout.module.css';

import Logo from '../../Logo/Logo';
import FaceBook from '../../Icons/FaceBook/FaceBook';
import Twitter from '../../Icons/Twitter/Twitter';
import Instagram from '../../Icons/Instagram/Instagram';
import TikTok from '../../Icons/TikTok/TikTok';
import SocialMediaIcon from './SocialMediaIcon';
import { IconState } from '../../../types/iconState';

const socialMediaIcons = [
  { name: 'Facebook', component: FaceBook },
  { name: 'Twitter', component: Twitter },
  { name: 'Instagram', component: Instagram },
  { name: 'TikTok', component: TikTok }
];

const FooterAbout = () => {
  const [state, setState] = useState(IconState.Inactive);

  return (
    <div className={styles.footerAbout}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <p className={styles.description}>
        Experience the thrill of winning with ScratchMania, the ultimate
        destination for online scratch card games. Play, win, and enjoy the
        excitement.
      </p>

      <ul className={styles.socialMediaIcons}>
        {socialMediaIcons.map((method) => (
          <li key={method.name}>
            <SocialMediaIcon IconComponent={method.component} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterAbout;
