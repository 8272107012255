import React, { Dispatch, ElementType, SetStateAction, useState } from 'react';
import styles from './paymentMethods.module.css';
import GCash from '../../Icons/GCash/GCash';
import WeChatPay from '../../Icons/WeChatPay/WeChatPay';
import Maya from '../../Icons/Maya/Maya';
import Visa from '../../Icons/Visa/Visa';
import AliPay from '../../Icons/AliPay/AliPay';
import PayPal from '../../Icons/PayPal/PayPal';
import PaymentItems from './PaymentItem/PaymentItem';

const paymentMethods = [
  { name: 'GCash', component: GCash },
  { name: 'WeChatPay', component: WeChatPay },
  { name: 'Maya', component: Maya },
  { name: 'Visa', component: Visa },
  { name: 'AliPay', component: AliPay },
  { name: 'PayPal', component: PayPal }
];

const Payment = () => {
  return (
    <div className={styles.topUpThroughContent}>
      <ul>
        {paymentMethods.map((method) => (
          <PaymentItems key={method.name} IconComponent={method.component} />
        ))}
      </ul>
    </div>
  );
};

export default Payment;
