// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/violet.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.howToPlaySection_howToPlaySection__MZbyU {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100% 100%;
  background-position: center;
  /* display: flex; */
}

.howToPlaySection_howToPlayContainer__HwG22 {
  max-width: 69.4375rem;
  margin: 1.625rem auto;

  display: flex;
}
.howToPlaySection_howToPlayBackground__1jmPw {
  width: 60%;
  position: absolute;
  z-index: -1;
  left: 0%;
  right: 0%;
}

.howToPlaySection_howToPlayHeader__WaC6v {
  font-size: 4.6875rem;
  line-height: 4.6875rem;
}

.howToPlaySection_descriptionContainer__rCP8I {
  display: flex;
  align-items: end;
}

.howToPlaySection_description__Kfh8y {
  position: relative;
}

.howToPlaySection_howToPlayHeaderWrapper__ZUbtx {
  display: flex;
}

.howToPlaySection_howToPlayHeaderWrapper__ZUbtx div {
  flex: 0 1 auto;
  width: 50%;
}

.howToPlaySection_howToPlayHeaderWrapper__ZUbtx div:nth-child(2) p {
  opacity: 0.6;
}

.howToPlaySection_howToPlayContent__8VpAf {
  margin-top: 4.125rem;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.howToPlaySection_steps__pT9-l {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.howToPlaySection_steps__pT9-l li {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/HowToPlaySection/howToPlaySection.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,yDAAyC;EACzC,0BAA0B;EAC1B,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;;EAErB,aAAa;AACf;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,qCAAqC;EACrC,kCAAkC;AACpC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".howToPlaySection {\n  padding-top: 0.9375rem;\n  padding-bottom: 0.9375rem;\n  background-image: url(/public/violet.svg);\n  background-size: 100% 100%;\n  background-position: center;\n  /* display: flex; */\n}\n\n.howToPlayContainer {\n  max-width: 69.4375rem;\n  margin: 1.625rem auto;\n\n  display: flex;\n}\n.howToPlayBackground {\n  width: 60%;\n  position: absolute;\n  z-index: -1;\n  left: 0%;\n  right: 0%;\n}\n\n.howToPlayHeader {\n  font-size: 4.6875rem;\n  line-height: 4.6875rem;\n}\n\n.descriptionContainer {\n  display: flex;\n  align-items: end;\n}\n\n.description {\n  position: relative;\n}\n\n.howToPlayHeaderWrapper {\n  display: flex;\n}\n\n.howToPlayHeaderWrapper div {\n  flex: 0 1 auto;\n  width: 50%;\n}\n\n.howToPlayHeaderWrapper div:nth-child(2) p {\n  opacity: 0.6;\n}\n\n.howToPlayContent {\n  margin-top: 4.125rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: end;\n}\n\n.steps {\n  display: grid;\n  gap: 2rem;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(2, 1fr);\n}\n\n.steps li {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"howToPlaySection": `howToPlaySection_howToPlaySection__MZbyU`,
	"howToPlayContainer": `howToPlaySection_howToPlayContainer__HwG22`,
	"howToPlayBackground": `howToPlaySection_howToPlayBackground__1jmPw`,
	"howToPlayHeader": `howToPlaySection_howToPlayHeader__WaC6v`,
	"descriptionContainer": `howToPlaySection_descriptionContainer__rCP8I`,
	"description": `howToPlaySection_description__Kfh8y`,
	"howToPlayHeaderWrapper": `howToPlaySection_howToPlayHeaderWrapper__ZUbtx`,
	"howToPlayContent": `howToPlaySection_howToPlayContent__8VpAf`,
	"steps": `howToPlaySection_steps__pT9-l`
};
export default ___CSS_LOADER_EXPORT___;
