// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data_mt-1__HRzPv {
  margin-top: 0.5em;
}

.data_mb-1__FPaOE {
  margin-bottom: 0.5em;
}

.data_ml-1__WDHHF {
  margin-left: 0.5em;
}

.data_ml-2__jELuG {
  margin-left: 1em;
}

.data_mt-1__HRzPv {
  margin-top: 0.5em;
}

p.data_mtb-1__-Dxpr,
.data_mtb-1__-Dxpr {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

p.data_mtb-2__zbaOP,
.data_mtb-2__zbaOP {
  margin-top: 1em;
  margin-bottom: 1em;
}

/* list */
.data_list-disc__8vYpX {
  list-style: disc outside none;
  display: list-item;
  margin-left: 1em;
}

/* width */
.data_max-w-8__V7ZEH {
  width: 800px;
}

/* text indent */
.data_indent-4__DVTxN {
  text-indent: 1rem; /* 16px */
}
`, "",{"version":3,"sources":["webpack://./src/pages/HelpCenter/data/data.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;;EAEE,eAAe;EACf,kBAAkB;AACpB;;AAEA,SAAS;AACT;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA,UAAU;AACV;EACE,YAAY;AACd;;AAEA,gBAAgB;AAChB;EACE,iBAAiB,EAAE,SAAS;AAC9B","sourcesContent":[".mt-1 {\n  margin-top: 0.5em;\n}\n\n.mb-1 {\n  margin-bottom: 0.5em;\n}\n\n.ml-1 {\n  margin-left: 0.5em;\n}\n\n.ml-2 {\n  margin-left: 1em;\n}\n\n.mt-1 {\n  margin-top: 0.5em;\n}\n\np.mtb-1,\n.mtb-1 {\n  margin-top: 0.5em;\n  margin-bottom: 0.5em;\n}\n\np.mtb-2,\n.mtb-2 {\n  margin-top: 1em;\n  margin-bottom: 1em;\n}\n\n/* list */\n.list-disc {\n  list-style: disc outside none;\n  display: list-item;\n  margin-left: 1em;\n}\n\n/* width */\n.max-w-8 {\n  width: 800px;\n}\n\n/* text indent */\n.indent-4 {\n  text-indent: 1rem; /* 16px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mt-1": `data_mt-1__HRzPv`,
	"mb-1": `data_mb-1__FPaOE`,
	"ml-1": `data_ml-1__WDHHF`,
	"ml-2": `data_ml-2__jELuG`,
	"mtb-1": `data_mtb-1__-Dxpr`,
	"mtb-2": `data_mtb-2__zbaOP`,
	"list-disc": `data_list-disc__8vYpX`,
	"max-w-8": `data_max-w-8__V7ZEH`,
	"indent-4": `data_indent-4__DVTxN`
};
export default ___CSS_LOADER_EXPORT___;
