// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accountHeader_headerContainer__7bkPH {
  margin-top: 6.5rem;
  margin: 6.5rem auto 0 auto;
  background-color: rgba(201, 223, 219, 0.15);
  border-radius: 10px;
  width: 80%;
  max-width: 69.438rem;

  display: flex;
  gap: 4.5rem;

  padding: 1.938em;
}

.accountHeader_name__Fq6yu {
  font-size: 2.188rem;
  font-family: var(--font-rajdhani);
  font-weight: 700;
  color: var(--primary-orange-yellow);
}

.accountHeader_accountInfo__x74Ps {
  font-family: var(--font-nunito-sans);
  font-size: 0.875rem;
}

.accountHeader_contactInfo__zaRLR {
  margin: 2.875rem 0;
}

.accountHeader_contactInfo__zaRLR p,
.accountHeader_userBalance__eYx9f p {
  display: inline-block;
}

.accountHeader_value__aYw7U {
  font-size: 1.5rem;
  font-family: var(--font-nunito-sans);
}
`, "",{"version":3,"sources":["webpack://./src/pages/MyAccount/AccountHeader/accountHeader.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,2CAA2C;EAC3C,mBAAmB;EACnB,UAAU;EACV,oBAAoB;;EAEpB,aAAa;EACb,WAAW;;EAEX,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,iCAAiC;EACjC,gBAAgB;EAChB,mCAAmC;AACrC;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,oCAAoC;AACtC","sourcesContent":[".headerContainer {\n  margin-top: 6.5rem;\n  margin: 6.5rem auto 0 auto;\n  background-color: rgba(201, 223, 219, 0.15);\n  border-radius: 10px;\n  width: 80%;\n  max-width: 69.438rem;\n\n  display: flex;\n  gap: 4.5rem;\n\n  padding: 1.938em;\n}\n\n.name {\n  font-size: 2.188rem;\n  font-family: var(--font-rajdhani);\n  font-weight: 700;\n  color: var(--primary-orange-yellow);\n}\n\n.accountInfo {\n  font-family: var(--font-nunito-sans);\n  font-size: 0.875rem;\n}\n\n.contactInfo {\n  margin: 2.875rem 0;\n}\n\n.contactInfo p,\n.userBalance p {\n  display: inline-block;\n}\n\n.value {\n  font-size: 1.5rem;\n  font-family: var(--font-nunito-sans);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `accountHeader_headerContainer__7bkPH`,
	"name": `accountHeader_name__Fq6yu`,
	"accountInfo": `accountHeader_accountInfo__x74Ps`,
	"contactInfo": `accountHeader_contactInfo__zaRLR`,
	"userBalance": `accountHeader_userBalance__eYx9f`,
	"value": `accountHeader_value__aYw7U`
};
export default ___CSS_LOADER_EXPORT___;
