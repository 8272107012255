import classNames from 'classnames';
import styles from './pageHeader.module.css';
import React from 'react';

const PageHeader = () => {
  return (
    <div className={classNames([styles.helpCenter], 'container')}>
      <div className={styles.helpCenterBanner}>
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>Frequently Asked Questions</h1>
          <div className={styles.copy}>
            <p>Have questions?</p>
            <p>
              We’ve got answers. Explore our FAQs to find helpful information
              about using ScratchMania, from gameplay tips to account
              management.
            </p>
          </div>
        </div>
        <div>
          <img src="./help-center-banner.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
