// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gameCard_gameCard__S0M-k {
  width: 15.9375rem;
  padding: 0.5rem;
  gap: 0.5rem;
  border-radius: 0.5rem;

  background-color: rgba(255, 255, 255, 0.07);

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
}

.gameCard_gameCard__S0M-k:hover {
  background-color: rgba(94, 89, 109, 1);
}

.gameCard_thumbnailContainer__v1HVt img {
  width: 100%;
  border-radius: 0.5rem;
}

.gameCard_gameCardContent__6VuL\\+ {
  background-color: rgb(12, 3, 36, 0.5);
  height: 7.0625rem;
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem 1rem;
}

.gameCard_gameName__PWHx7 {
  font-family: var(--font-rajdhani);
  font-weight: 600;
  line-height: 1.435625rem;
}

.gameCard_gameType__Sf947 {
  font-family: var(--font-nunito-sans);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.19375rem;
  opacity: 0.6;

  margin: 0.25rem 0;
}

.gameCard_cost__OHVMn {
  font-family: var(--font-nunito-sans);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.705rem;
  color: var(--primary-orange-yellow);
}

.gameCard_coin__KMwWC {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.115625rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/GameCard/gameCard.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,qBAAqB;;EAErB,2CAA2C;;EAE3C,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,qCAAqC;EACrC,iBAAiB;EACjB,qBAAqB;;EAErB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;;EAEnB,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;;EAEZ,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB,mCAAmC;AACrC;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".gameCard {\n  width: 15.9375rem;\n  padding: 0.5rem;\n  gap: 0.5rem;\n  border-radius: 0.5rem;\n\n  background-color: rgba(255, 255, 255, 0.07);\n\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n  cursor: pointer;\n}\n\n.gameCard:hover {\n  background-color: rgba(94, 89, 109, 1);\n}\n\n.thumbnailContainer img {\n  width: 100%;\n  border-radius: 0.5rem;\n}\n\n.gameCardContent {\n  background-color: rgb(12, 3, 36, 0.5);\n  height: 7.0625rem;\n  border-radius: 0.5rem;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  padding: 1rem 1rem;\n}\n\n.gameName {\n  font-family: var(--font-rajdhani);\n  font-weight: 600;\n  line-height: 1.435625rem;\n}\n\n.gameType {\n  font-family: var(--font-nunito-sans);\n  font-size: 0.875rem;\n  font-weight: 400;\n  line-height: 1.19375rem;\n  opacity: 0.6;\n\n  margin: 0.25rem 0;\n}\n\n.cost {\n  font-family: var(--font-nunito-sans);\n  font-size: 1.25rem;\n  font-weight: 700;\n  line-height: 1.705rem;\n  color: var(--primary-orange-yellow);\n}\n\n.coin {\n  margin-left: 0.5rem;\n  width: 1.5rem;\n  height: 1.115625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gameCard": `gameCard_gameCard__S0M-k`,
	"thumbnailContainer": `gameCard_thumbnailContainer__v1HVt`,
	"gameCardContent": `gameCard_gameCardContent__6VuL+`,
	"gameName": `gameCard_gameName__PWHx7`,
	"gameType": `gameCard_gameType__Sf947`,
	"cost": `gameCard_cost__OHVMn`,
	"coin": `gameCard_coin__KMwWC`
};
export default ___CSS_LOADER_EXPORT___;
