// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentMethods_topUpThrough__Pi\\+8f h2 {
  text-align: center;
  font-size: 1.5rem;

  padding: 1.625rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.paymentMethods_topUpThroughContent__HF-Qi ul {
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin: 2rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/TopupSection/PaymentMethods/paymentMethods.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;;EAEjB,mBAAmB;EACnB,iDAAiD;AACnD;;AAEA;EACE,aAAa;EACb,8BAA8B;;EAE9B,WAAW;EACX,cAAc;AAChB","sourcesContent":[".topUpThrough h2 {\n  text-align: center;\n  font-size: 1.5rem;\n\n  padding: 1.625rem 0;\n  border-bottom: 1px solid rgba(255, 255, 255, 0.2);\n}\n\n.topUpThroughContent ul {\n  display: flex;\n  justify-content: space-between;\n\n  width: 100%;\n  margin: 2rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topUpThrough": `paymentMethods_topUpThrough__Pi+8f`,
	"topUpThroughContent": `paymentMethods_topUpThroughContent__HF-Qi`
};
export default ___CSS_LOADER_EXPORT___;
