import React from 'react';
import styles from './heroSection.module.css';
import globalStyle from '../../global.module.css';
import classNames from 'classnames';

import ArrowRight from '../Icons/ArrowRight/ArrowRight';
import useRequireLogin from '../../hooks/useRequiredLogin';
import routes from '../../router/routes.json';

const HeroSection = () => {
  const useRequiredLogin = useRequireLogin();

  return (
    <div className={`${styles.heroSectionContainer} container`}>
      <div className={styles.heroBackground}>
        <img src="./background-hero.png" alt="" />
      </div>
      <div className={styles.header}>
        <div>
          <h1 className={styles.headerTitle}>
            Unleash the Fun with{' '}
            <span className={styles.textScratch}>Scratch</span>
            <span className={styles.textMania}>Mania!</span>
          </h1>
          <p className={styles.headerCopy}>
            Dive into ScratchMania, the ultimate online destination for
            thrilling scratch card games. Purchase virtual cards, match numbers,
            and win cash instantly. Enjoy the excitement of traditional scratch
            cards on your smartphone, anytime, anywhere.
          </p>
          <button
            className={classNames(styles.playNowBtn, globalStyle.btnIconReveal)}
            onClick={() => {
              useRequiredLogin(routes.cards.url);
            }}>
            <span>play now</span>
            <ArrowRight className={globalStyle.icon} />
          </button>
          <div className={styles.statsWrapper}>
            <div className={styles.stats}>
              <div className={styles.statsHeader}>20+</div>
              <p className={styles.statsSubHeader}>Scratch Cards</p>
            </div>
            <div className={styles.stats}>
              <div className={styles.statsHeader}>1M++</div>
              <p className={styles.statsSubHeader}>In Winnings</p>
            </div>
          </div>
        </div>
        <div className={styles.headerImageContainer}>
          <img src="./header-image.png" alt="Scratch Mania Banner" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
