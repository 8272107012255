import React, { useCallback } from 'react';
import style from './guestNavButtons.module.css';
import { useModal, ModalType } from '../../../context/ModalContextProvider';
import { AuthType } from '../../Modal/Auth/Auth';

import HoverRevealButton from '../../Buttons/HoverRevealButton/HoverRevealButton';
import hoverBtnStyle from '../../Buttons/HoverRevealButton/hoverRevealButton.module.css';
import Coin from '../../../assets/icons/Coin';

const navButton = () => {
  const { openModal } = useModal();

  const onClick = useCallback((authType: AuthType) => {
    openModal(ModalType.AUTH, { authType: authType });
  }, []);

  return (
    <div className={style.navButtonWrapper}>
      <HoverRevealButton onClick={() => onClick(AuthType.Register)}>
        <span
          className={`${hoverBtnStyle.defaultView} ${hoverBtnStyle.primaryBgColor}`}>
          register
        </span>
        <div
          className={`${hoverBtnStyle.hoverView} ${hoverBtnStyle.secondaryBgColor}`}>
          <Coin width={25} height={25} />
        </div>
      </HoverRevealButton>

      <HoverRevealButton onClick={() => onClick(AuthType.Login)}>
        <span
          className={`${hoverBtnStyle.defaultView} ${hoverBtnStyle.secondaryBgColor}`}>
          login
        </span>
        <div
          className={`${hoverBtnStyle.hoverView} ${hoverBtnStyle.primaryBgColor}`}>
          <Coin width={25} height={25} />
        </div>
      </HoverRevealButton>
    </div>
  );
};

export default navButton;
