// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FaqCard_card__TnOL6 {
  width: 21.813rem;
  min-height: 16rem;

  background-color: rgba(201, 223, 219, 0.15);
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;

  padding: 2.5em;
  cursor: pointer;
}

.FaqCard_card__TnOL6:hover,
.FaqCard_card__TnOL6.FaqCard_isActive__WAHTz {
  background: rgba(249, 207, 49, 1);
  color: rgba(0, 0, 0, 1);
}

.FaqCard_card__TnOL6:hover .FaqCard_cardContent__Y02s-,
.FaqCard_card__TnOL6.FaqCard_isActive__WAHTz .FaqCard_cardContent__Y02s- {
  color: rgba(0, 0, 0, 1);
}

.FaqCard_cardTitle__VtPyM {
  font-size: 1.563rem;
  font-weight: 700;
  font-family: var(--font-rajdhani);
  margin: 0.5em 0;
}

.FaqCard_cardContent__Y02s- {
  font-family: var(--font-nunito-sans);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4em;
  text-align: center;
  color: rgba(212, 212, 212, 1);
}
`, "",{"version":3,"sources":["webpack://./src/components/FaqCardGrid/FaqCard/FaqCard.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;;EAEjB,2CAA2C;EAC3C,mBAAmB;;EAEnB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,sBAAsB;;EAEtB,cAAc;EACd,eAAe;AACjB;;AAEA;;EAEE,iCAAiC;EACjC,uBAAuB;AACzB;;AAEA;;EAEE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iCAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,6BAA6B;AAC/B","sourcesContent":[".card {\n  width: 21.813rem;\n  min-height: 16rem;\n\n  background-color: rgba(201, 223, 219, 0.15);\n  border-radius: 15px;\n\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  flex-direction: column;\n\n  padding: 2.5em;\n  cursor: pointer;\n}\n\n.card:hover,\n.card.isActive {\n  background: rgba(249, 207, 49, 1);\n  color: rgba(0, 0, 0, 1);\n}\n\n.card:hover .cardContent,\n.card.isActive .cardContent {\n  color: rgba(0, 0, 0, 1);\n}\n\n.cardTitle {\n  font-size: 1.563rem;\n  font-weight: 700;\n  font-family: var(--font-rajdhani);\n  margin: 0.5em 0;\n}\n\n.cardContent {\n  font-family: var(--font-nunito-sans);\n  font-size: 0.875rem;\n  font-weight: 400;\n  line-height: 1.4em;\n  text-align: center;\n  color: rgba(212, 212, 212, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `FaqCard_card__TnOL6`,
	"isActive": `FaqCard_isActive__WAHTz`,
	"cardContent": `FaqCard_cardContent__Y02s-`,
	"cardTitle": `FaqCard_cardTitle__VtPyM`
};
export default ___CSS_LOADER_EXPORT___;
