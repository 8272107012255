import React from 'react';
import classNames from 'classnames';
import styles from './cards.module.css';

import LoggedInHeroBanner from '../../components/LoggedInHeroBanner/LoggedInHeroBanner';
import BrowseByCategory from '../../components/BrowseByCategory/BrowseByCategory';
import TopCards from '../../components/TopCards/TopCards';
import NewCards from '../../components/NewCards/NewCards';
import Cards from '../../components/Cards/Cards';
import { useCategory } from '../../context/CategoryContextProvider';

const CardsContent = () => {
  const { isCategorySelected } = useCategory();

  return (
    <div>
      <LoggedInHeroBanner />
      <BrowseByCategory />

      <div
        className={classNames({
          [styles.hidden]: isCategorySelected
        })}>
        <TopCards />
        <NewCards />
      </div>

      <Cards />
    </div>
  );
};

export default CardsContent;
