// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion_accordion__ZuhwA {
  width: auto;
}

.accordion_accordionItem__7Q6lS {
  margin-bottom: 2rem;
  border-radius: 5px;
  overflow: hidden;
}

.accordion_accordionTitle__oFVCK {
  font-size: 1.5rem;
  font-family: var(--font-rajdhani);
  font-weight: 700;
  cursor: pointer;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
}

.accordion_accordionTitle__oFVCK::first-letter {
  text-transform: uppercase;
}

.accordion_accordionContent__vO69X p {
  margin-bottom: 1.5rem;
  line-height: 1.7rem;
}

.accordion_accordionContent__vO69X {
  margin-top: 1rem;
  font-family: var(--font-nunito-sans);
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
  max-height: 0;
  overflow: hidden;
  transition:
    max-height 1s ease,
    padding 1s ease;

  width: 100%;
  scrollbar-width: 3px;
  scrollbar-track-color: transparent;
}

.accordion_accordionContent__vO69X::-webkit-scrollbar {
  width: 3px;
}

.accordion_accordionContent__vO69X::-webkit-scrollbar-track {
  display: none;
}

.accordion_accordionContent__vO69X::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #8e8e8e;
}

.accordion_accordionContentActive__BMLBV {
  max-height: 700px;
}

.accordion_arrowActive__LdsgB {
  transform: rotate(180deg);
  transition: transform 1s ease;
}

.accordion_arrowInactive__Mjuqb {
  transition: transform 1s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/FAQSection/Accordion/accordion.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,iBAAiB;;EAEjB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,eAAe;EACf,+BAA+B;EAC/B,aAAa;EACb,gBAAgB;EAChB;;mBAEiB;;EAEjB,WAAW;EACX,oBAAoB;EACpB,kCAAkC;AACpC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".accordion {\n  width: auto;\n}\n\n.accordionItem {\n  margin-bottom: 2rem;\n  border-radius: 5px;\n  overflow: hidden;\n}\n\n.accordionTitle {\n  font-size: 1.5rem;\n  font-family: var(--font-rajdhani);\n  font-weight: 700;\n  cursor: pointer;\n  font-weight: bold;\n\n  display: flex;\n  justify-content: space-between;\n}\n\n.accordionTitle::first-letter {\n  text-transform: uppercase;\n}\n\n.accordionContent p {\n  margin-bottom: 1.5rem;\n  line-height: 1.7rem;\n}\n\n.accordionContent {\n  margin-top: 1rem;\n  font-family: var(--font-nunito-sans);\n  font-size: 1rem;\n  color: rgba(255, 255, 255, 0.6);\n  max-height: 0;\n  overflow: hidden;\n  transition:\n    max-height 1s ease,\n    padding 1s ease;\n\n  width: 100%;\n  scrollbar-width: 3px;\n  scrollbar-track-color: transparent;\n}\n\n.accordionContent::-webkit-scrollbar {\n  width: 3px;\n}\n\n.accordionContent::-webkit-scrollbar-track {\n  display: none;\n}\n\n.accordionContent::-webkit-scrollbar-thumb {\n  border-radius: 8px;\n  background-color: #8e8e8e;\n}\n\n.accordionContentActive {\n  max-height: 700px;\n}\n\n.arrowActive {\n  transform: rotate(180deg);\n  transition: transform 1s ease;\n}\n\n.arrowInactive {\n  transition: transform 1s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": `accordion_accordion__ZuhwA`,
	"accordionItem": `accordion_accordionItem__7Q6lS`,
	"accordionTitle": `accordion_accordionTitle__oFVCK`,
	"accordionContent": `accordion_accordionContent__vO69X`,
	"accordionContentActive": `accordion_accordionContentActive__BMLBV`,
	"arrowActive": `accordion_arrowActive__LdsgB`,
	"arrowInactive": `accordion_arrowInactive__Mjuqb`
};
export default ___CSS_LOADER_EXPORT___;
