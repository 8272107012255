import React, { useEffect, useState } from 'react';
import styles from './auth.module.css';
import ArrowRight from '../../Icons/ArrowRight/ArrowRight';
import globalStyle from '../../../global.module.css';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes.json';
import { useModal } from '../../../context/ModalContextProvider';

import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';

export enum AuthType {
  Login = 'login',
  Register = 'register'
}

const Auth = () => {
  const { closeModal, authType } = useModal();
  const [activeAuth, setActiveAuth] = useState<AuthType | null>(authType);

  const handleChangeActiveAuth = (authType: AuthType) => {
    setActiveAuth(authType);
  };

  if (activeAuth === null) return;

  return (
    <div
      className={`${styles.container} ${activeAuth === AuthType.Register ? styles.active : ''}`}>
      <div className={`${styles.formContainer} ${styles.signIn}`}>
        <SignIn
          isActive={activeAuth === AuthType.Login}
          handleChangeActiveAuth={handleChangeActiveAuth}
        />
      </div>
      <div className={`${styles.formContainer} ${styles.signUp}`}>
        <SignUp
          isActive={activeAuth === AuthType.Register}
          handleChangeActiveAuth={handleChangeActiveAuth}
        />
      </div>
      <div className={`${styles.imagePanel}`}>
        <div className={`${styles.imageContainer}`}>
          <div className={`${styles.imageWrapper} ${styles.transformLeft}`}>
            <img
              className={styles.authModaImage}
              src="register-modal-image.png"
            />
          </div>
          <div className={`${styles.imageWrapper} ${styles.transformRight}`}>
            <img className={styles.authModaImage} src="login-modal-image.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
