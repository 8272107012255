import { motion } from 'framer-motion';
import style from './modal.module.css';
import React from 'react';

const Overlay = () => {
  return (
    <motion.div
      initial={{
        opacity: 0
      }}
      animate={{
        opacity: 1,
        backdropFilter: 'blur(10px)'
      }}
      exit={{
        opacity: 0,
        backdropFilter: 'blur(0px)'
      }}
      className={style.overlay}></motion.div>
  );
};

export default Overlay;
