import React, { ElementType, useState } from 'react';
import { IconState } from '../../../types/iconState';

type SocialMediaIconProps = {
  IconComponent: ElementType<{ state: IconState }>;
};

const SocialMediaIcon = ({ IconComponent }: SocialMediaIconProps) => {
  const [state, setState] = useState(IconState.Inactive);

  return (
    <div
      onClick={() => setState(IconState.Active)}
      onMouseEnter={() => setState(IconState.Hover)}
      onMouseLeave={() => setState(IconState.Inactive)}>
      {<IconComponent state={state} />}
    </div>
  );
};

export default SocialMediaIcon;
