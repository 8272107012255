import React, { useState } from 'react';
import styles from './accordion.module.css';

import ArrowDown from '../../Icons/ArrowDown/ArrowDown';

interface AccordionItem {
  title: string;
  content: string;
}

interface AccordionProps {
  items: AccordionItem[];
}

const Accordion = ({ items }: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.accordion}>
      {items.map((item, index) => (
        <div key={index} className={styles.accordionItem}>
          <div
            className={styles.accordionTitle}
            onClick={() => handleToggle(index)}>
            {item.title}
            <div
              className={
                activeIndex === index
                  ? styles.arrowActive
                  : styles.arrowInactive
              }>
              <ArrowDown />
            </div>
          </div>
          <div
            className={`${styles.accordionContent} ${
              activeIndex === index ? styles.accordionContentActive : ''
            }`}>
            <div className={styles.accordionContentInner}>{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
