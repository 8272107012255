import React from 'react';
import globalStyle from './../../../global.module.css';
import classNames from 'classnames';

import TopUpSVG from './TopUpSVG';
import styles from './gameStory.module.css';
import ArrowRight from '../../Icons/ArrowRight/ArrowRight';
import useRequireLogin from '../../../hooks/useRequiredLogin';
import routes from '../../../router/routes.json';

const GameStory = () => {
  const useRequiredLogin = useRequireLogin();
  return (
    <div className={styles.gameStoryContainer}>
      <div>
        <TopUpSVG />
      </div>
      <div className={styles.gameStoryCopy}>
        <p className="sectionTitle">story</p>
        <h3>ScratchMania</h3>
        <p>
          We are transforming online gaming with thrilling scratch card games.
          Our mission is to bring the excitement of traditional scratch cards to
          your smartphone, offering instant fun and cash prizes.
        </p>
        <p>
          We prioritize a secure and fair gaming experience, ensuring every
          player enjoys the thrill responsibly. Whether you’re a casual gamer or
          a dedicated player, ScratchMania has something exciting for you
        </p>
        <p>Join us and discover the joy of winning with just a scratch! </p>

        <button
          className={classNames(styles.playNowBtn, globalStyle.btnIconReveal)}
          onClick={() => useRequiredLogin(routes.cards.url)}>
          <span>play now</span>
          <ArrowRight className={globalStyle.icon} />
        </button>
      </div>
    </div>
  );
};

export default GameStory;
