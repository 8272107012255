import React from 'react';
import { IconState } from '../../../types/iconState';

type WeChatPayProps = {
  state?: IconState;
};

const WeChatPay = ({ state = IconState.Inactive }: WeChatPayProps) => {
  const fillColor = state === IconState.Inactive ? '#544E65' : '';

  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M1.20001 0.5H24.2V23.5H1.20001V0.5Z" />
        <path
          d="M10.1972 15.1144L10.2042 15.1109L10.211 15.1071L10.291 15.0631L10.291 15.0632L10.2995 15.0583L21.4185 8.65566C21.9381 9.63788 22.2075 10.736 22.199 11.8522H22.199V11.856C22.199 16.0987 18.0279 19.686 12.689 19.686L12.6881 19.686C11.6327 19.6879 10.5826 19.5429 9.56559 19.2564C9.42336 19.2129 9.27742 19.193 9.13401 19.193L9.13135 19.193C8.8528 19.1945 8.58267 19.2725 8.34742 19.4153L6.49463 20.4904L6.88123 19.029C6.94853 18.802 6.94486 18.5596 6.87056 18.3346C6.7951 18.106 6.6505 17.9065 6.45675 17.7636L6.44796 17.7571L6.4389 17.751C5.46779 17.0984 4.66702 16.2228 4.10352 15.1974C3.54105 14.174 3.2313 13.0309 3.20002 11.8635C3.20421 7.585 7.36885 4.00201 12.704 4.00201V4.00211L12.7141 4.00191C14.1775 3.97231 15.6303 4.2571 16.9742 4.83704C18.1045 5.3248 19.1357 6.01178 20.0194 6.86336L10.6331 11.0675C10.5871 11.0853 10.5381 11.0943 10.4887 11.094L10.488 11.094C10.3933 11.0936 10.3008 11.0658 10.2217 11.0139L8.05618 9.49701C7.91492 9.39011 7.74225 9.33276 7.56497 9.33396C7.45826 9.33281 7.35231 9.35231 7.25296 9.39144C7.15011 9.43195 7.05643 9.49266 6.97744 9.57C6.89846 9.64733 6.83578 9.73971 6.79311 9.84169C6.75577 9.93093 6.73441 10.0259 6.72991 10.1223C6.71983 10.2092 6.7215 10.2971 6.73492 10.3837L6.74544 10.4515L6.77386 10.514L8.5915 14.5112L8.59186 14.512L8.6345 14.6062L8.64282 14.6246L8.65259 14.6422C8.79858 14.9059 9.04127 15.1025 9.32947 15.1906C9.61766 15.2787 9.92881 15.2514 10.1972 15.1144Z"
          fill={`${state === IconState.Active || state === IconState.Hover ? '#29AF3E' : fillColor}`}
        />
      </svg>
    </>
  );
};

export default WeChatPay;
